import { Component, OnInit, Injector } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppComponentBase } from "@shared/app-component-base";

@Component({
  selector: "app-intro",
  templateUrl: "./intro.page.html",
  //styleUrls: ['./intro.page.scss'],
})
export class IntroPage extends AppComponentBase implements OnInit {
  constructor(injector: Injector, private activatedRoute: ActivatedRoute) {
    super(injector);
  }

  ngOnInit() {}

  gotohome() {
    /*this.appAuthService.isLoggedIn().then((res) => {
      this.isLoggedIn = res;
      if (!this.isLoggedIn) {
        this.notify.warn(this.l("Please login and try again!"));
        this.router.navigate([""]).then((e) => {
          this.router.navigate(["/account/login"]);
        });
        return;
      }
      this.router.navigate(["/home"], { replaceUrl: true });
    });*/

    /*this.getUserInfo().then((res) => {
      if(!res) {this.router.navigate(["/account/login"], { replaceUrl: true });}
      this.router.navigate(["/home"], { replaceUrl: true });
    })*/
    this.router.navigate(["/home"], { replaceUrl: true });
  }
}
