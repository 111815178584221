import { Component, OnInit, Injector } from "@angular/core";
import { AppComponentBase } from "@app/shared/app-component-base";

@Component({
  selector: "app-aboutus",
  templateUrl: "./aboutus.component.html",
})
export class AboutUsComponent extends AppComponentBase implements OnInit {
  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {}
}
