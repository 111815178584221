import {
  Component,
  Input,
  forwardRef,
  AfterViewInit,
  //   trigger,
  //   state,
  //   animate,
  //   transition,
  //   style,
  HostListener,
  OnChanges,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnDestroy,
} from "@angular/core";
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  FormGroup,
  FormBuilder,
  Validators,
} from "@angular/forms";
import {
  base64ToFile,
  Dimensions,
  ImageCroppedEvent,
  ImageTransform,
} from "ngx-image-cropper";
import { Subscription } from "rxjs";

export interface ImageCropperFormValues {
  ImageName: string;
}

@Component({
  selector: "app-imagecropper",
  templateUrl: "./imagecropper.component.html",
  styleUrls: ["./imagecropper.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageCropperComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ImageCropperComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageCropperComponent
  implements ControlValueAccessor, AfterViewInit, OnChanges, OnDestroy
{
  public formIC: FormGroup;
  public subscriptions: Subscription[] = [];

  public imageChangedEvent: any = "";
  public croppedImage2: any = "";
  public canvasRotation = 0;
  public rotation = 0;
  public scale = 1;
  public showCropper = false;
  public containWithinAspectRatio = false;
  public transform: ImageTransform = {};
  @Output() imageSelected: EventEmitter<any> = new EventEmitter<any>();
  @Input() showPreview: boolean = true;
  @Input() maintainAspectRatio: boolean = true;
  @Input() aspectRatio: number = 4 / 3;
  //@Input() resizeToWidth: number = 256;
  //@Input() cropperMinWidth: number = 128;
  @Input() autoOpenFile: boolean = false;

  @ViewChild("fileInput") fileInput: ElementRef;
  @Input() disabled: boolean = false;

  constructor(private formBuilder: FormBuilder) {}

  ngOnDestroy(): void {
    //throw new Error("Method not implemented.");
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error("Method not implemented.");
  }

  ngOnInit(): void {
    this.formIC = this.formBuilder.group({
      ImageName: ["", Validators.required],
      rotation: [""],
    });

    this.subscriptions.push(
      this.formIC.valueChanges.subscribe((value) => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }

  ngOnChanges() {}

  //Lifecycle hook. angular.io for more info
  ngAfterViewInit() {
    if (this.autoOpenFile) {
      let inputElement: HTMLElement = this.fileInput
        .nativeElement as HTMLElement;
      inputElement.click();
    }
  }

  get value(): ImageCropperFormValues {
    return this.formIC.value;
  }

  set value(value: ImageCropperFormValues) {
    this.formIC.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value == "" || value === null || value === undefined) {
      this.formIC.reset();
      this.imageSelected.emit(null);
    } else {
      this.value = value;
      this.imageSelected.emit(this.croppedImage2);
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate(_: FormControl) {
    return this.formIC.valid ? null : { ImageName: { valid: false } };
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage2 = event.base64;
    this.imageSelected.emit(this.croppedImage2);
  }

  imageLoaded() {
    this.showCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions) {}

  loadImageFailed() {}

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
    this.updateValue();
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV,
    };
    this.updateValue();
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.formIC.get("rotation").setValue(0);
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      //rotate: this.rotation,
      rotate: this.formIC.get("rotation").value,
    };
  }

  private updateValue() {
    this.imageSelected.emit(this.croppedImage2);
  }
}
