/*import { IsTenantAvailableOutputState } from './service-proxies/service-proxies';


export class AppTenantAvailabilityState {
    static Available: number = IsTenantAvailableOutputState._1;
    static InActive: number = IsTenantAvailableOutputState._2;
    static NotFound: number = IsTenantAvailableOutputState._3;
}*/

export class AppEnums {
    static readonly nametitles = {
        Mr: 'Mr.',
        Mrs: 'Mrs.',
        Miss: 'Miss'
    };

    static readonly genders = {
        Male: 'Male',
        Female: 'Female',
        Unisex: 'Unisex'
    };

    static readonly accounttypes = {
        Saving: 'Saving',
        Current: 'Current'
    };

    static readonly offertypes = [
        {"id": "FlatPerc", "name": "Flat Percentage", "vfields": "offer_value"},
        {"id": "FlatAmt", "name": "Flat Amount", "vfields": "offer_value"},
        {"id": "Upto", "name": "Upto", "vfields": "offer_value"},
        {"id": "BXGXF", "name": "Buy X Get X free (BXGXF)", "vfields": "offer_value,offer_value2"},
    ];

    static readonly paymentmodes = [
        {"id": "Cash Deposit", "name": "Cash Deposit", "vfields": ""},
        {"id": "Cheque", "name": "Cheque", "vfields": ""},
        {"id": "NEFT/IMPS", "name": "NEFT/IMPS", "vfields": ""},
        {"id": "UPI", "name": "UPI", "vfields": ""},
    ];

    static readonly epocketrequeststatus = [
        {"id": "all", "name": "All", "vfields": ""},
        {"id": "Accepted", "name": "Accepted", "vfields": ""},
        {"id": "Pending", "name": "Pending", "vfields": ""},
        {"id": "Rejected", "name": "Rejected", "vfields": ""},
    ];

    static readonly epockettranstypes = [
        {"id": "all", "name": "All", "vfields": ""},
        {"id": "Deposite", "name": "Deposite", "vfields": ""},
        {"id": "Withdrawal", "name": "Withdrawal", "vfields": ""},
    ];

    static readonly ordertypes = [
        {"id": "Purchase", "name": "Capital Contribution", "vfields": ""},
        {"id": "Repurchase", "name": "Running Capital", "vfields": ""},
    ];

    static readonly orderplacedin = [
        {"id": "1", "name": "This Month", "vfields": ""},
        {"id": "2", "name": "Last Month", "vfields": ""},
        {"id": "6", "name": "6 Month", "vfields": ""},
        {"id": "12", "name": "1 Year", "vfields": ""},
        {"id": "24", "name": "2 Year", "vfields": ""},
    ];

    static readonly relations = [
        'Aunti', 'Brother', 'Cousin', 'Daughter', 'Father', 'Husband', 'Mother', 'Sister', 'Son', 'Uncle', 'Wife', 'Owner', '--Other--'
    ]
}