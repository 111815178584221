import {
  Component,
  OnInit,
  Injector,
  OnDestroy,
  AfterContentInit,
  HostListener,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { finalize } from "rxjs/operators";
import { NgxPubSubService } from "@pscoped/ngx-pub-sub";
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
  FormArray,
  ValidationErrors,
} from "@angular/forms";

import { AppComponentBase } from "@app/shared/app-component-base";
import {
  AuthServiceProxy,
  EpocketServiceProxy,
  OffersServiceProxy,
  UtilsServiceProxy,
} from "@app/shared/service-proxies/service-proxies";
import { environment } from "@environments/environment";
import { ActivationStart } from "@angular/router";
import * as moment from "moment";
import { UserModel } from "@shared/auth/app-auth.service";
import { Subscription } from "rxjs/internal/Subscription";

@Component({
  selector: "app-addrequest",
  templateUrl: "./addrequest.component.html",
  styleUrls: ["./addrequest.component.scss"],
})
export class AddRequestComponent
  extends AppComponentBase
  implements OnInit, OnDestroy {
  public myOffers: any[] = [];
  showResult: boolean = false;
  saving: boolean = false;
  frmFG: FormGroup;
  public userModel: UserModel;
  public me: any;
  @ViewChild("fileInput") fileInput;
  submitted = false;

  validation_messages = {
    currentBalance: [
      { type: "required", message: "Current Balance is required." },
    ],
    amount: [{ type: "required", message: "Amount is required." }],
    paymentMode: [{ type: "required", message: "Payment Mode is required." }],
    transactionRefNo: [
      { type: "required", message: "Transaction Reference No. is required." },
    ],
    transactionDate: [
      { type: "required", message: "Transaction Date is required." },
    ],
    companyAccNo: [
      { type: "required", message: "Company Account No. is required." },
    ],
    transactionDetails: [
      { type: "required", message: "Transaction Details is required." },
    ],
    ImageName: [{ type: "required", message: "Transaction Slip is required." }],
  };

  constructor(
    injector: Injector,
    private fb: FormBuilder,
    public pubsubSvc: NgxPubSubService,
    private spEpocket: EpocketServiceProxy,
    private authSP: AuthServiceProxy
  ) {
    super(injector);
  }

  ngOnInit(): void {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("pg-epocket");

    this.frmFG = this.fb.group({
      currentBalance: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      amount: new FormControl("", Validators.compose([Validators.required])),
      paymentMode: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
    });

    this.appAuthService.isLoggedIn().then((res) => {
      this.isLoggedIn = res;
      if (!this.isLoggedIn) {
        this.notify.warn(this.l("Please login and try again!"));
        this.router.navigate([""]).then((e) => {
          this.router.navigate(["/account/login"]);
        });
        return;
      } else {
        this.spinner.show();
        this.appAuthService.getUserModel().then(
          (res) => {
            this.userModel = res;

            this.authSP
              .getMe({
                LoginID: this.userModel.strUserID,
                PanelType: this.userModel.UserType,
              })
              .pipe(
                finalize(() => {
                  this.spinner.hide();
                })
              )
              .subscribe(
                (result: any) => {
                  if (!result.issuccess) {
                    this.notify.warn(result.message);
                  } else if (result.statuscode == 200) {
                    this.me = result.result[0];
                    this.frmFG.controls["currentBalance"].setValue(
                      this.me.Balance
                    );
                  }
                  //this.showResult = true;
                  this.spinner.hide();
                },
                (err) => {
                  this.me = {};
                  this.showResult = false;
                  this.spinner.hide();
                }
              );
          },
          (err) => {}
        );
      }
    });
  }

  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("pg-home");
  }

  doRefresh(event) {}

  // convenience getter for easy access to form fields
  get f() {
    return this.frmFG.controls;
  }

  chngPaymentMode(event) {}

  async frmSubmit(form: any, isValid: boolean) {
    if(this.frmFG.controls["amount"].value == "") {
      this.frmFG.get("amount").setErrors({ required: true });
      this.frmFG.get("amount").markAsDirty();
      return;
    }
    if (this.frmFG.controls["paymentMode"].value == "offline") {
      this.router.navigate([""]).then((e) => {
        this.router.navigate([
          "/epocket/addrequestoffline",
          { amount: this.frmFG.controls["amount"].value },
        ]);
      });
    } else if (this.frmFG.controls["paymentMode"].value == "online") {
      this.router.navigate([""]).then((e) => {
        this.router.navigate(["/epocket/addrequestonline"]);
      });
    } else {
      this.frmFG.get("paymentMode").setErrors({ required: true });
      this.frmFG.get("paymentMode").markAsDirty();
      return;
    }
  }

  frmReset() {
    this.submitted = false;
    this.frmFG.reset();
  }
}
