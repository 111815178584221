import { Component, Injector, OnInit, AfterViewInit } from "@angular/core";

import { Platform, AlertController } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { Location } from "@angular/common";
import { AppComponentBase } from "@shared/app-component-base";
import { combineLatest, Subscription } from "rxjs";
import { NgxPubSubService } from "@pscoped/ngx-pub-sub";
import { setTheme } from "ngx-bootstrap/utils";

import { UserModel } from "@shared/auth/app-auth.service";
import {
  animate,
  query,
  style,
  transition,
  trigger,
} from "@angular/animations";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
  /*animations: [
    trigger('myAnimation', [
      transition('* => *', [
        query(
          ':enter',
          [style({ opacity: 0 })],
          { optional: true }
        ),
        query(
          ':leave',
           [style({ opacity: 1 }), animate('0.3s', style({ opacity: 0 }))],
          { optional: true }
        ),
        query(
          ':enter',
          [style({ opacity: 0 }), animate('0.3s', style({ opacity: 1 }))],
          { optional: true }
        )
      ])
    ])] // register the animations*/
})
export class AppComponent extends AppComponentBase implements OnInit {
  public userModel: UserModel;
  public labels = ["Family", "Friends", "Notes", "Work", "Travel", "Reminders"];

  constructor(
    injector: Injector,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public pubsubSvc: NgxPubSubService,
    private _location: Location,
    public alertController: AlertController
  ) {
    super(injector);
    this.initializeApp();
  }

  initializeApp() {
    //console.log('initializeApp')
    this.splashScreen.show();
    this.platform.ready().then(() => {
      this.statusBar.styleBlackTranslucent();
      this.splashScreen.hide();
    });
    setTheme("bs4");

    this.platform.backButton.subscribeWithPriority(10, (processNextHandler) => {
      //console.log("Back press handler!");
      if (this._location.isCurrentPathEqualTo("/home")) {
        // Show Exit Alert!
        //console.log("Show Exit Alert!");
        this.showExitConfirm();
        processNextHandler();
      } else {
        // Navigate to back page
        //console.log("Navigate to back page");
        this._location.back();
      }
    });

    this.platform.backButton.subscribeWithPriority(5, () => {
      //console.log("Handler called to force close!");
      this.alertController
        .getTop()
        .then((r) => {
          if (r) {
            navigator["app"].exitApp();
          }
        })
        .catch((e) => {
          //console.log(e);
        });
    });
  }

  showExitConfirm() {
    this.alertController
      .create({
        header: "App termination",
        message: "Do you want to close the app?",
        backdropDismiss: false,
        buttons: [
          {
            text: "Stay",
            role: "cancel",
            handler: () => {
              //console.log("Application exit prevented!");
            },
          },
          {
            text: "Exit",
            handler: () => {
              navigator["app"].exitApp();
            },
          },
        ],
      })
      .then((alert) => {
        alert.present();
      });
  }

  ngOnInit() {
    //console.log("AppComponent init");
  }
}
