import {
  OnInit,
  Injector,
  OnDestroy,
  Component,
  ElementRef,
} from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { NotifyService } from "@app/shared/helpers/notify.service";
import { LocalizationService } from "./localization/localization.service";
import { AppEnums } from "@app/shared/AppEnums";
import { AppAuthService, UserModel } from "./auth/app-auth.service";
import { AppConsts } from "@app/shared/AppConsts";
import { Observable, Subscription } from "rxjs";
import { filter, finalize } from "rxjs/operators";
import { environment } from "@environments/environment";
import { UtilsServiceProxy } from "./service-proxies/service-proxies";
import { NgxPubSubService } from "@pscoped/ngx-pub-sub";

@Component({
  template: "",
})
export abstract class AppComponentBase implements OnInit, OnDestroy {
  protected spinner: NgxSpinnerService;
  protected router: Router;
  protected route: ActivatedRoute;
  protected notify: NotifyService;
  elementRef: ElementRef;
  public genums: any;
  public gconsts: any;
  public isLoggedIn: boolean = false;
  public locOrigin: string = "";
  public mdlOpenLogin: boolean = false;
  protected appAuthService: AppAuthService;
  public loggedInUserId: number;
  public userModel: UserModel;
  public clientDetails: any = {};
  protected utilsService: UtilsServiceProxy;
  public pubsubSvc: NgxPubSubService;
  public subLogin: Subscription;
  public apptitle: string;
  public apptagline: string;

  constructor(injector: Injector) {
    this.spinner = injector.get(NgxSpinnerService);
    this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
    this.notify = injector.get(NotifyService);
    this.elementRef = injector.get(ElementRef);
    this.appAuthService = injector.get(AppAuthService);
    this.utilsService = injector.get(UtilsServiceProxy);
    this.pubsubSvc = injector.get(NgxPubSubService);

    this.genums = AppEnums;
    this.gconsts = AppConsts;
    this.locOrigin = window.location.origin;
    this.apptitle = environment.APP_TITLE;
    this.apptagline = environment.APP_TAGLINE;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  getUserInfo = (): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      this.appAuthService.isLoggedIn().then((res) => {
        this.isLoggedIn = res;

        if (!this.isLoggedIn) {
        } else {
          this.appAuthService.getUserModel().then(
            (res) => {
              this.userModel = res;
              this.loggedInUserId = this.userModel.UserID;
              this.getClientDetails().then((res) => {});
              return resolve(true);
            },
            (err) => {
              return resolve(false);
            }
          );
        }
      });
    });
  };
  
  getClientDetails = (): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      this.appAuthService.getClientDetails().then((res) => {
        this.clientDetails = res;
        return resolve(res);
      });
    });
  };

  l(key: string, ...args: any[]): string {
    let localizedText = key; // this.localization.localize(key, this.localizationSourceName);

    if (!localizedText) {
      localizedText = key;
    }

    if (!args || !args.length) {
      return localizedText;
    }

    args.unshift(localizedText);
    //return abp.utils.formatString.apply(this, args);
    return localizedText;
  }

  logout() {
    this.appAuthService.logout();
    this.router.navigate(["/account/login"]);
  }

  getURL(url) {
    return (
      environment.UPLOADS_URL +
      (url != "" ? url.replace("../../").replace("~/", "") : "") +
      "?t=" +
      Math.random()
    );
  }
}
