import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { CommonModule } from "@angular/common";

import { RightSideBarComponent } from "./right-sidebar.component";

@NgModule({
  imports: [CommonModule],
  declarations: [RightSideBarComponent],
  providers: [],
  exports: [RightSideBarComponent],
  //schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class RightSideBarModule {}
