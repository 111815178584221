import { Component, OnInit, Injector } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { AppComponentBase } from "@app/shared/app-component-base";
import { NgxPubSubService } from "@pscoped/ngx-pub-sub";
import { AppAuthService } from "@app/shared/auth/app-auth.service";

@Component({
  selector: "app-offer-layout",
  templateUrl: "./offer-layout.component.html",
  styleUrls: ["./offer-layout.component.scss"],
})
export class OfferLayoutComponent extends AppComponentBase implements OnInit {
  constructor(
    injector: Injector,
    private title: Title,
    public translate: TranslateService,
    public appAuthService: AppAuthService,
    public pubsubSvc: NgxPubSubService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.title.setTitle(environment.APP_TITLE);
    const html = document.getElementsByTagName("html")[0];
    html.classList.remove("ap");
    html.classList.remove("up");
    html.classList.add("up");

    /*window.onscroll = function () {
      var element = document.getElementsByTagName("body")[0];
      if (
        document.body.scrollTop > 400 ||
        document.documentElement.scrollTop > 200
      ) {
        $(".navbar").addClass("fixed-top");
        element.classList.add("header-small");
        $("body").addClass("body-top-padding");
      } else {
        $(".navbar").removeClass("fixed-top");
        element.classList.remove("header-small");
        $("body").removeClass("body-top-padding");
      }
    };*/
  }

  onResize(event) {}

  logout() {
    this.appAuthService.logout();
    this.router.navigate(["/account/login", { replaceUrl: true }]);
  }
}
