import {
  Component,
  Injector,
  ViewEncapsulation,
  Input,
  OnInit,
} from "@angular/core";
import { environment } from "@environments/environment";
import { NgxPubSubService } from "@pscoped/ngx-pub-sub";
import { AppComponentBase } from "@shared/app-component-base";
import { AuthServiceProxy } from "@shared/service-proxies/service-proxies";
import { StateChange } from "ng-lazyload-image";
import { Subscription } from "rxjs";
import { finalize } from "rxjs/operators";

@Component({
  templateUrl: "./sidebar.component.html",
  selector: "sidebar-nav",
  styleUrls: ["sidebar.component.scss"],
  //encapsulation: ViewEncapsulation.None
})
export class SideBarComponent extends AppComponentBase implements OnInit {
  @Input() title: string;
  public subscLogout: Subscription;
  public subOpenLogin: Subscription;
  public subLogin: Subscription;
  public subEPBalChanged: Subscription;
  public appPages: any[] = [];
  mnuEpShow: boolean = false;
  public selectedIndex = 0;
  public defaultImgProfile: string = "assets/img/default-avatar.png";
  profileImg: string = "assets/img/default-avatar.png";
  appMenuSwipeGesture: boolean = false;
  me: any = {};
  EPBalance: number = 0;

  constructor(
    injector: Injector,
    public pubsubSvc: NgxPubSubService,
    private authSP: AuthServiceProxy
  ) {
    super(injector);
    this.subLogin = this.pubsubSvc.subscribe("user:login", (data) => {
      //if (this.mdlOpenLogin) {
      //  return;
      //}
      if (data != null) {
        this.checkLogin();
        //this.router.navigate(["/home"], { replaceUrl: true});
        //this.router.navigateByUrl('/home?t=' + Math.random(), { replaceUrl: true, skipLocationChange: false });
      }
    });
  }

  ngOnInit(): void {
    //this.selectedThemeCssClass = this.setting.get('App.UiTheme');
    //$('body').addClass('theme-' + this.selectedThemeCssClass);
    this.checkLogin();

    this.subscLogout = this.pubsubSvc.subscribe("user:logout", (data) => {
      if (!data) {
        return;
      }
      this.isLoggedIn = false;
      this.spinner.hide();
      this.logout();
      //this.router.navigate(["/home"], { skipLocationChange: true });
      //window.location.reload();
      if (data.url && data.url.indexOf("auth/refresh") === -1) {
        window.location.reload();
      }
    });

    this.subOpenLogin = this.pubsubSvc.subscribe("user:openlogin", (data) => {
      //if (this.mdlOpenLogin) {
      //  return;
      //}
      if (data != null) {
        this.router.navigate([""]).then((e) => {
          this.router.navigate(["/account/login"]);
        });
      }
    });

    // this.subEPBalChanged = this.pubsubSvc.subscribe("epocket:epbalchanged", (data) => {
    //   console.log('epocket:epbalchanged', data)
    //   //if (this.mdlOpenLogin) {
    //   //  return;
    //   //}
    //   if (data != null) {
    //     if(data.Balance != null) {
    //       this.appAuthService.setEPBalance(data.Balance).then((res) => {
    //         this.EPBalance = res;
    //       });
    //     } else {
    //       this.getMe();
    //     }
    //   }
    // });
  }

  onIonDidOpen() {
    this.appMenuSwipeGesture = false;
    /*this.appAuthService.getEPBalance().then((res) => {
      this.EPBalance = res;
    });*/
    this.getMe();
  }

  checkLogin() {
    this.appAuthService.isLoggedIn().then((res) => {
      this.isLoggedIn = res;

      this.appPages = [
        {
          title: "Home",
          url: "/home",
          icon: "home",
          show: this.isLoggedIn ? true : false,
        },
        {
          title: this.l("Sign In"),
          url: "/account/login",
          icon: "log-in",
          show: !this.isLoggedIn ? true : false,
        },
        {
          title: this.l("Sign Up"),
          url: "/account/register",
          icon: "paper-plane",
          show: !this.isLoggedIn ? true : false,
        },
        {
          title: this.l("Profile"),
          url: "/account/profile",
          icon: "person",
          show: this.isLoggedIn ? true : false,
        },
        {
          title: this.l("KYC"),
          url: "/kyc",
          icon: "finger-print",
          show: this.isLoggedIn ? true : false,
        },
        {
          title: this.l("Packages"),
          url: "/offers",
          icon: "bag-handle",
          show: this.isLoggedIn ? true : false,
        },
        {
          title: this.l("ePocket"),
          url: "/epocket",
          icon: "wallet",
          show: this.isLoggedIn ? true : false,
        },
        {
          title: this.l("Orders"),
          url: "/orders",
          icon: "layers",
          show: this.isLoggedIn ? true : false,
        },
        {
          title: this.l("My References"),
          url: "/orders/myreferences",
          icon: "people",
          show: this.isLoggedIn ? true : false,
        },
        {
          title: this.l("My Team Orders"),
          url: "/orders/myteamorders",
          icon: "layers",
          show: this.isLoggedIn ? true : false,
        },
        {
          title: this.l("Payment History"),
          url: "/orders/paymenthistory",
          icon: "card",
          show: this.isLoggedIn ? true : false,
        },
        {
          title: "About Us",
          url: "/aboutus",
          icon: "information-circle",
          show: true,
        },
        // {
        //   title: this.l("Sign Out"),
        //   url: "/account/login",
        //   icon: "log-out",
        //   show: (this.isLoggedIn ? true : false),
        //   //action: this.logout()
        // },
      ];

      this.appAuthService.getUserModel().then(
        (res) => {
          if(res == null) {return}
          this.userModel = res;
          this.getMe();
        },
        (err) => {}
      );
    });
  }

  myCallbackFunction(event: StateChange) {
  }

  ngAfterViewInit() {
    console.log('ngAfterViewInit')
    this.checkLogin();
  }

  logout(): void {
    this.appAuthService.logout().then(
      (res) => {
        this.isLoggedIn = false;
        //window.location.reload();
        this.router.navigate(["/account/login"], { replaceUrl: true });
        //this.router.navigate([""]).then((e) => {
        //  this.router.navigate(["/account/login"]);
        //});
      },
      (err) => {}
    );
  }

  gotoprofile() {
    this.router.navigate([""]).then((e) => {
      this.router.navigate(["/account/profile"]);
    });
  }

  mnuEp() {
    this.mnuEpShow = this.mnuEpShow ? false : true;
  }

  goto(url) {
    this.router.navigate([""]).then((e) => {
      this.router.navigate([url], { replaceUrl: true });
    });
  }

  imgError(event) {
    //event.srcElement.setAttribute('src', this.defaultImgProfile)
  }

  getMe() {
    this.authSP
      .getMe({
        LoginID: this.userModel.strUserID,
        PanelType: this.userModel.UserType,
      })
      .pipe(
        finalize(() => {
          //this.spinner.hide();
          //this.loadingDeskB = false;
        })
      )
      .subscribe(
        (result: any) => {
          this.spinner.hide();
          if (!result.issuccess) {
            this.notify.warn(result.message);
          } else if (result.statuscode == 200) {
            this.me = result.result[0];
            this.EPBalance = parseInt(this.me.Balance);
            this.appAuthService.setEPBalance(this.EPBalance).then((res) => {});
            if (
              result.result[0].ProfileImageURL != "" &&
              result.result[0].ProfileImageURL.length > 5
            ) {
              this.profileImg = this.getURL(result.result[0].ProfileImageURL);
            }
          }
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
        }
      );
  }
}
