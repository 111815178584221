import {
  Component,
  Injector,
  OnInit,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import {
  finalize,
  map,
  mergeMap as _observableMergeMap,
  catchError as _observableCatch,
} from "rxjs/operators";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  ValidationErrors,
} from "@angular/forms";
import { NgxPubSubService } from "@pscoped/ngx-pub-sub";
//import hmacSHA256 from 'crypto-js/hmac-sha512';
import CryptoJS from "crypto-js";
import Base64 from "crypto-js/enc-base64";
//import { BsModalService, BsModalRef, ModalDirective } from "ngx-bootstrap/modal";
import { AppComponentBase } from "@app/shared/app-component-base";
//import { MustMatch } from "@app/shared/validators/must-match.validator";
//import { PasswordValidator } from "@app/shared/validators/password.validator";
import { AuthServiceProxy } from "@app/shared/service-proxies/service-proxies";
import { AppAuthService } from "@app/shared/auth/app-auth.service";
//import { TermsPage } from "./terms.page";
//import { LoginService } from "@app/shared/auth/login.service";
import { environment } from "@environments/environment";
import { UtilsHelper } from "@shared/helpers/utils.helper";

//import { ZipcodeInputComponent } from "@app/shared/components/zipcode/zipcode.component";
/*import { SocialAuthService, SocialUser } from "angularx-social-login";
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
} from "angularx-social-login";*/

@Component({
  templateUrl: "./register.component.html",
  selector: "app-register",
  //animations: [accountModuleAnimation()],
})
export class RegisterComponent extends AppComponentBase implements OnInit {
  saving = false;
  public frmFG: FormGroup;
  submitted = false;
  passwordType: string = "password";
  passwordIcon: string = "eye-off";
  public event: EventEmitter<any> = new EventEmitter();
  socialProvider: string = null;
  //user: SocialUser;
  loggedIn: boolean;
  //@ViewChild('mdlTerms', { static: false }) mdlTerms: ModalDirective;
  //modalRef: BsModalRef;
  areas: any[] = [];
  reference: any = {};
  refvalidated: boolean = false;
  termsURL: string = "/account/terms";

  matching_passwords_group: FormGroup;

  validation_messages = {
    title: [{ type: "required", message: "Title is required." }],
    name: [
      { type: "required", message: "Name is required." },
      {
        type: "maxlength",
        message: "Name cannot be more than 25 characters long.",
      },
      {
        type: "pattern",
        message: "Your Name must contain only numbers and letters.",
      },
    ],
    countryCode: [
      { type: "required", message: "Country Code is required." },
      //{ type: 'validCountryPhone', message: 'The phone is incorrect for the selected country.' }
    ],
    country: [{ type: "required", message: "Country is required." }],
    mobileNo: [
      { type: "required", message: "Mobile Number is required." },
      //{ type: 'validCountryPhone', message: 'The phone is incorrect for the selected country.' }
    ],
    email: [
      { type: "required", message: "Email is required." },
      { type: "pattern", message: "Please enter a valid email." },
      { type: "email", message: "Please enter a valid email." },
    ],
    pincode: [{ type: "required", message: "State is required." }],
    address: [
      { type: "required", message: "Address is required." },
      {
        type: "maxlength",
        message: "Address cannot be more than 100 characters long.",
      },
      {
        type: "pattern",
        message: "Your Address must contain only numbers and letters.",
      },
    ],
    area: [
      { type: "required", message: "Area is required." },
      {
        type: "maxlength",
        message: "Area cannot be more than 25 characters long.",
      },
      {
        type: "pattern",
        message: "Your Area must contain only numbers and letters.",
      },
    ],
    state: [{ type: "required", message: "State is required." }],
    city: [{ type: "required", message: "City is required." }],
    referenceId: [
      { type: "required", message: "Reference Id is required." },
      {
        type: "minlength",
        message: "Reference Id must contain minimum 7 characters.",
      },
    ],
    password: [
      { type: "required", message: "Password is required." },
      {
        type: "minlength",
        message: "Password must be at least 5 characters long.",
      },
      {
        type: "pattern",
        message:
          "Your password must contain at least one uppercase, one lowercase, one number and at least one special character (#?!@$%^&*+-).",
      },
    ],
    confirm_password: [
      { type: "required", message: "Confirm password is required." },
    ],
    matching_passwords: [{ type: "areEqual", message: "Password mismatch." }],
    terms_and_cond: [
      { type: "pattern", message: "You must accept terms and conditions." },
    ],
  };

  constructor(
    injector: Injector,
    private fb: FormBuilder,
    private authSP: AuthServiceProxy,
    public appAuthService: AppAuthService,
    public pubsubSvc: NgxPubSubService,
    private utilsHelper: UtilsHelper
  ) {
    super(injector);
  }

  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("register-page");

    this.frmFG = this.fb.group(
      {
        email: new FormControl(
          "",
          Validators.compose([
            //Validators.maxLength(25),
            Validators.required,
            //Validators.email,
            Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
          ])
        ),
        title: new FormControl("", Validators.compose([Validators.required])),
        name: new FormControl(
          "",
          Validators.compose([
            Validators.required,
            Validators.pattern("^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$"),
            Validators.maxLength(100),
          ])
        ),
        countryCode: new FormControl(
          "",
          Validators.compose([Validators.required])
        ),
        mobileNo: new FormControl(
          "",
          Validators.compose([Validators.required, Validators.maxLength(12)])
        ),
        pincode: new FormControl(
          "",
          Validators.compose([Validators.required, Validators.maxLength(10)])
        ),
        address: new FormControl(
          "",
          Validators.compose([Validators.required, Validators.maxLength(100)])
        ),
        area: new FormControl(
          "",
          Validators.compose([Validators.required, Validators.maxLength(100)])
        ),
        city: new FormControl(
          "",
          Validators.compose([
            //Validators.required,
            Validators.maxLength(100),
          ])
        ),
        state: new FormControl(
          "",
          Validators.compose([
            //Validators.required,
            Validators.maxLength(100),
          ])
        ),
        country: new FormControl(
          "",
          Validators.compose([
            //Validators.required,
            Validators.maxLength(100),
          ])
        ),
        referenceId: new FormControl(
          "",
          Validators.compose([
            Validators.required, //Validators.minLength(7),
            Validators.maxLength(100),
          ])
        ),
        //matching_passwords: this.matching_passwords_group,
        meta: this.fb.group({
          //phone: new FormControl(null, Validators.required),
          terms_and_cond: new FormControl(true, Validators.pattern("true")),
        }),
      } /*,
      (formGroup: FormGroup) => {
        return PasswordValidator.areEqual(formGroup);
      }*/
    );

    this.frmFG.get("referenceId").valueChanges.subscribe((referenceId) => {
      //if (referenceId != this.reference.) {
      this.refvalidated = false;
      //}
    });

    this.frmFG.get("countryCode").setValue("+91");

    let formdata = {
      CompanyId: environment.CompanyID,
      AppVersionCode: environment.AppVersionCode
    };
    this.utilsService
      .getClientDetails(formdata)
      .pipe(finalize(() => {}))
      .subscribe(
        (result: any) => {
          if (!result.issuccess) {
            //this.notify.warn(result.message);
          } else if (result.statuscode == 200) {
            this.termsURL = result.result[0].TermsAndConditionsURL;
          }
        },
        (err) => {}
      );
  }

  getAreas(event) {
    let pincode = event.target.value;
    if (pincode == "" || pincode.length != 6) {
      this.areas = [];
      return;
    }

    const hashDigest = Base64.stringify(
      CryptoJS.HmacSHA256(
        pincode + "$$" + "" + environment.SECURE_KEY,
        environment.SECURE_KEY
      )
    );
    this.spinner.show();
    this.utilsService
      .getAreas(pincode, {
        PinCode: pincode,
        SecureKey: "" + environment.SECURE_KEY,
        Token: hashDigest,
      })
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe(
        (result: any) => {
          this.spinner.hide();
          this.areas = result.result;
        },
        (err) => {
          this.spinner.hide();
          this.areas = [];
        }
      );
  }

  chngArea(event) {
    if (this.areas[event.target.value]) {
      this.frmFG.get("city").setValue(this.areas[event.target.value].City);
      this.frmFG.get("state").setValue(this.areas[event.target.value].State);
      this.frmFG
        .get("country")
        .setValue(this.areas[event.target.value].Country);
    }
  }

  validatereferenceId(event, referenceId) {
    this.utilsHelper.getReferenceDetails(referenceId).then((res) => {
      if (res != null) {
        this.reference = res;
      } else {
        this.reference = {};
      }
    });
    /*this.spinner.show();
    this.utilsService
      .getReferenceInfo({
        StrReferenceID: referenceId,
        StrSponsorID: referenceId,
      })
      .pipe(
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe(
        (result: any) => {
          this.spinner.hide();
          if (!result.issuccess) {
            this.notify.warn("Invalid Reference Id");
            this.reference = {};
          } else {
            if (result.ReferenceID) {
              this.reference = result;
              //this.frmFG.get("referenceId").setValue(result.ReferenceID);
            }

            //console.log("this.reference", this.reference);
          }
        },
        (err) => {
          this.reference = {};
          this.spinner.hide();
          this.areas = [];
        }
      );*/
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.frmFG.controls;
  }

  ngOnDestroy() {
    //var body = document.getElementsByTagName("body")[0];
    //body.classList.remove("register-page");
  }

  back(): void {
    this.router.navigate(["/home"]);
  }

  tncChange(event) {
    //this.frmFG.get("meta.terms_and_cond").setValue(event.srcElement.checked);
  }

  async Passwordmismatch() {
    this.notify.warn("Please enter correct password");
  }

  async frmReset() {
    this.submitted = false;
    this.frmFG.reset();
    this.frmFG.get("countryCode").setValue("+91");
    this.reference = {};
  }

  async frmSubmit(form: any, isValid: boolean) {
    form.username = form.email;
    /*if (!this.checkNetwork) {
      this.notify.error("No internet connection!");
    }*/

    this.getFormValidationErrors();
    if (!isValid) {
      this.frmFG.markAsDirty();
      this.frmFG.markAllAsTouched();
      this.notify.error(
        "Invalid form inputs. Please enter proper details and try again."
      );
      return;
    }

    var pass = Math.floor(Math.random() * 1000000 + 1);
    let formdata = {
      Password: pass,
      Name: form.name,
      Pincode: form.pincode,
      Country: form.country,
      Area: form.area,
      City: form.city,
      state: form.state,
      Address: form.address,
      CountryCode: form.countryCode,
      MobileNo: form.mobileNo,
      EmailID: form.email,
      ReferenceId: this.reference.ReferenceID,
      strUserID: "",
      LoginID: "",
      PositionFlag: 15,
    };

    this.saving = true;
    this.spinner.show();

    this.utilsHelper
      .getReferenceDetails(this.frmFG.get("referenceId").value)
      .then((res) => {
        if (res != null) {
          this.reference = res;
          this.authSP
            .register(formdata)
            .pipe(
              finalize(() => {
                this.saving = false;
                this.spinner.hide();
              })
            )
            .subscribe(
              (result: any) => {
                this.spinner.hide();
                this.saving = false;
                if (!result.issuccess) {
                  this.notify.warn(result.message);
                } else if (result.statuscode == 200) {
                  this.notify.success("Registration successfull.");
                  this.router.navigate(["/account/login"]);
                }
              },
              (err) => {
                this.spinner.hide();
                this.notify.error(err.message);
                this.saving = false;
              }
            );
        } else {
          this.saving = false;
        }
      }, (err) => {

      });
  }

  openTNCPage() {
    /*const url = this.router.serializeUrl(
      this.router.createUrlTree(["/account/terms"])
    );*/
    let url = environment.TERMSNCONDSURL;
    window.open(url, "_blank");
  }

  /**
   * Marks all controls in a form group as touched
   * @param formGroup - The form group to touch
   */
  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  getFormValidationErrors() {
    Object.keys(this.frmFG.controls).forEach((key) => {
      const controlErrors: ValidationErrors = this.frmFG.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          console.log(
            "Key control: " + key + ", keyError: " + keyError + ", err value: ",
            controlErrors[keyError]
          );
        });
      }
    });
  }
}
