import {
  Component,
  Injector,
  OnInit,
  EventEmitter,
  ElementRef,
  Input,
  Output,
} from "@angular/core";
import {
  finalize,
  map,
  mergeMap as _observableMergeMap,
  catchError as _observableCatch,
} from "rxjs/operators";
//import { NgxPubSubService } from "@pscoped/ngx-pub-sub";
//import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { AppComponentBase } from "@app/shared/app-component-base";
//import { accountModuleAnimation } from "@app/shared/animations/routerTransition";
import { LoginService } from "@app/shared/auth/login.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  ValidationErrors,
  FormControl,
} from "@angular/forms";
import { PasswordValidator } from "@app/shared/validators/password.validator";
import {
  AuthServiceProxy,
  SocialServiceProxy,
} from "@app/shared/service-proxies/service-proxies";
import { AppAuthService } from "@app/shared/auth/app-auth.service";

@Component({
  templateUrl: "./forgotpass.component.html",
  styleUrls: ["./forgotpass.component.less"],
  //animations: [accountModuleAnimation()],
})
export class ForgotPassComponent extends AppComponentBase implements OnInit {
  //model: RegisterInput = new RegisterInput();
  //model: ShardConfig = new ShardConfig();
  saving = false;
  subscriptionid: number = null;
  frmFG: FormGroup;
  submitted = false;
  passwordType: string = "password";
  passwordIcon: string = "eye-off";
  public event: EventEmitter<any> = new EventEmitter();
  enableOTP: boolean = false;
  loggedIn: boolean;
  //@Input() data: any = null;
  data: any = null;

  validation_messages = {
    loginId: [
      { type: "required", message: "LoginID is required." },
    ],
    email: [
      { type: "required", message: "Email is required." },
      { type: "pattern", message: "Please wnter a valid email." },
    ],
  };

  constructor(
    injector: Injector,
    //private el: ElementRef,
    //private _loginService: LoginService,
    private fb: FormBuilder,
    private authSP: AuthServiceProxy,
    public appAuthService: AppAuthService
  ) //public pubsubSvc: NgxPubSubService
  {
    super(injector);
  }

  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("login-page");

    this.frmFG = this.fb.group(
      {
        loginId: [
          "",
          [
            Validators.required
          ],
        ],
        email: [
          "",
          [
            Validators.required,
            //Validators.email,
            //Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
          ],
        ],
      },
      {}
    );

    this.route.params.subscribe((params) => {
      let email = params["email"] ?? [];
      this.frmFG.get("email").setValue(email);
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.frmFG.controls;
  }

  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("login-page");
  }

  async frmSubmit(form: any, isValid: boolean) {
    /*if (!this.checkNetwork) {
      this.notify.error("No internet connection!");
    }*/
    this.getFormValidationErrors();
    if (!isValid) {
      this.frmFG.markAsDirty();
      this.frmFG.markAllAsTouched();
      this.notify.error(
        "Invalid form inputs. Please enter proper details and try again."
      );
      /*for (const key of Object.keys(this.frmFG.controls)) {
        if (this.frmFG.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          invalidControl.focus();
          break;
       }
      }*/
      return;
    }

    this.saving = true;
    this.spinner.show();
    this.authSP
      .forgotPass({LoginID: form.loginId, MobileNoOrEmailID: form.email})
      .pipe(
        finalize(() => {
          this.saving = false;
          this.spinner.hide();
        })
      )
      .subscribe(
        (result: any) => {
          
          if (!result.issuccess) {
            this.notify.warn(result.message);
          } else if (result.statuscode == 200) {
            this.notify.success(
              this.l("Forgot password done. Password is sent on your registered email address.")
            );
          }
        },
        (err) => {
          this.notify.error(err.message);
          this.saving = false;
        }
      );
  }

  onReset() {
    this.submitted = false;
    this.frmFG.reset();
  }

  getFormValidationErrors() {
    Object.keys(this.frmFG.controls).forEach((key) => {
      const controlErrors: ValidationErrors = this.frmFG.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          console.log(
            "Key control: " + key + ", keyError: " + keyError + ", err value: ",
            controlErrors[keyError]
          );
        });
      }
    });
  }

  register() {
    this.router.navigate(["/account/register"], { replaceUrl: true });
  }
}
