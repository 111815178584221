import { Component, OnInit, Injector, OnDestroy } from "@angular/core";
import { environment } from "@environments/environment";
import { AppComponentBase } from "@shared/app-component-base";
import { AppAuthService } from "@shared/auth/app-auth.service";
import { OffersServiceProxy } from "@shared/service-proxies/service-proxies";
import { finalize } from "rxjs/operators";

@Component({
  selector: "app-offer",
  templateUrl: "./offer.component.html",
  styleUrls: ["./offer.component.scss"],
})
export class OfferComponent
  extends AppComponentBase
  implements OnInit, OnDestroy {
  userModel: any;
  active: boolean = false;
  saving: boolean = false;
  loading: any;
  submitted: boolean = false;
  formData: FormData = new FormData();
  useroffer_slug: string;
  userOffer: any = {};

  constructor(
    private injector: Injector,
    public appAuthService: AppAuthService,
    private offersService: OffersServiceProxy
  ) {
    super(injector);
  }

  ionViewWillLeave() {}

  ngOnInit(): void {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("pg-offer");

    this.route.paramMap.subscribe((params) => {
      this.useroffer_slug = params.get("slug");
      this.getData();
    });
  }

  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("pg-offer");
  }

  getData() {
    this.offersService
      .get({ PackageCode: this.useroffer_slug })
      .pipe(
        finalize(() => {
          this.saving = false;
        })
      )
      .subscribe(
        (result: any) => {
          if (!result.issuccess) {
            this.notify.warn(result.message);
          } else if (result.statuscode == 200) {
            this.userOffer = result.result[0];
            this.userOffer.avatar =
              environment.UPLOADS_URL +
              (this.userOffer.ProductImage != ""
                ? this.userOffer.ProductImage.replace("../../", "")
                : "");
          }
        },
        (err) => {}
      );
  }
}
