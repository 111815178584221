import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { ProfileComponent } from "./profile/profile.component";
import { EditProfileComponent } from "./profile/edit-profile.component";
import { TermsPage } from "./register/terms.page";
import { ForgotPassComponent } from "./forgotpass/forgotpass.component";
import { ResetPassComponent } from "./resetpass/resetpass.component";
import { AccountComponent } from "./account.component";
import { AppRouteGuard } from "@shared/auth/auth-route-guard";

const routes: Routes = [
  {
    path: "",
    //redirectTo: "home",
    //pathMatch: "full",
    component: AccountComponent,
    children: [
      { path: "login", component: LoginComponent },
      { path: "register", component: RegisterComponent },
      {
        path: "profile",
        component: ProfileComponent,
        canActivate: [AppRouteGuard],
      },
      {
        path: "edit-profile",
        component: EditProfileComponent,
        canActivate: [AppRouteGuard],
      },
      { path: "terms", component: TermsPage },
      { path: "forgotpass", component: ForgotPassComponent },
      { path: "resetpass/:token", component: ResetPassComponent },
      //{ path: 'register/:id', component: RegisterComponent }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountRoutingModule {}
