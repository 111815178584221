import { HttpClient, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import {
  AppRouteGuard,
  AppRouteGuard as AuthGuard,
} from "@shared/auth/auth-route-guard";

import { AboutUsComponent } from "./aboutus/aboutus.component";
import { IntroPage } from "./intro/intro.page";

const routes: Routes = [
  //{ path: "", pathMatch: "full", component: IntroPage },
  {
    path: "",
    loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
    canActivate: [AppRouteGuard],
  },
  {
    path: "home",
    loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
    canActivate: [AppRouteGuard],
  },
  {
    path: "account",
    loadChildren: () =>
      import("./account/account.module").then((m) => m.AccountModule),
  },
  {
    path: "offers",
    //outlet: 'rooffers',
    loadChildren: () =>
      import("./offers/offers.module").then((m) => m.OffersModule),
    canActivate: [AppRouteGuard],
  },
  {
    path: "cart",
    loadChildren: () => import("./cart/cart.module").then((m) => m.CartModule),
    canActivate: [AppRouteGuard],
  },
  {
    path: "kyc",
    loadChildren: () => import("./kyc/kyc.module").then((m) => m.KycModule),
    canActivate: [AppRouteGuard],
  },
  {
    path: "epocket",
    loadChildren: () =>
      import("./epocket/epocket.module").then((m) => m.EpocketModule),
    canActivate: [AppRouteGuard],
  },
  {
    path: "orders",
    loadChildren: () =>
      import("./orders/orders.module").then((m) => m.OrdersModule),
    canActivate: [AppRouteGuard],
  },
  {
    path: "aboutus",
    component: AboutUsComponent,
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      //preloadingStrategy: QuicklinkStrategy,
      //enableTracing: true,
      onSameUrlNavigation: "reload",
      //useHash: true
    }),
    //RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' }),
    //RouterModule.forRoot(routes, { useHash: true, enableTracing: false })
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
