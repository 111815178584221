import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

declare var jQuery: any;
declare var aks: any;

@Injectable()
export class AppUserConfigurationService {

    constructor(private _http: HttpClient) {
        
    }

    initialize(): void {
        this._http.get('/AbpUserConfiguration/GetAll')
            .subscribe(result => {
                jQuery.extend(true, aks, JSON.parse(JSON.stringify(result)));
            });
    }

}