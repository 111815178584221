import { Injectable } from "@angular/core";
import { finalize, tap } from "rxjs/operators";
import { Observable, BehaviorSubject } from "rxjs";
import { of as observableOf } from "rxjs";
import { delay } from "rxjs/operators";
import { StorageMap } from "@ngx-pwa/local-storage";
import {
  AuthServiceProxy,
  AuthenticateResultModel,
  UtilsServiceProxy,
} from "../service-proxies/service-proxies";
import * as moment from "moment-timezone";
import { environment } from "@environments/environment";
import { NetworkInterface } from "@ionic-native/network-interface/ngx";
import { Platform } from "@ionic/angular";

@Injectable()
export class AppAuthService {
  currentToken: string;
  authSubject = new BehaviorSubject(false);
  ipAddress = "";

  constructor(
    private storage: StorageMap,
    //private secureStorage: SecureStorage,
    private authService: AuthServiceProxy,
    private utilsService: UtilsServiceProxy,
    //private networkInterface: NetworkInterface,
    //private platform: Platform
  ) {
    this.currentToken = null;
    this.storage.get("ACCESS_TOKEN").subscribe((res: string) => {
      this.currentToken = res;
    });
    //this.getIPAddress();
  }

  /*getIPAddress() {
    if (this.platform.is("cordova")) {
      this.networkInterface
        .getCarrierIPAddress()
        .then((address) => {
          console.info(`IP: ${address.ip}, Subnet: ${address.subnet}`);
          this.ipAddress = address.ip;
        })
        .catch((error) => console.error(`Unable to get IP: ${error}`));
    } else {
        this.utilsService.getIPAddress({}).subscribe((res) => {
          this.ipAddress = res.ip;
        })
    }
  }*/

  //async login(accessToken: string, encryptedAccessToken: string, expireInSeconds: number, rememberMe?: boolean) {
  async login(authResponse: AuthenticateResultModel, rememberMe?: boolean) {
    this.storage
      .set("ACCESS_TOKEN", authResponse.accessToken)
      .subscribe(() => {});
    this.storage
      .set("EXPIRES_IN", authResponse.expireInSeconds)
      .subscribe(() => {});
    let tokenAt = moment();
    this.storage
      .set("TOKEN_AT", tokenAt.format("YYYY-MM-DDTHH:mm:ss.SSSSZ"))
      .subscribe(() => {});
    //await this.storage.set("EXPIRES_AT", tokenAt.add(authResponse.expireInSeconds, 'second').toISOString());
    this.storage
      .set(
        "EXPIRES_AT",
        tokenAt
          .add(authResponse.expireInSeconds, "second")
          .format("YYYY-MM-DDTHH:mm:ss.SSSSZ")
      )
      .subscribe(() => {});
    this.storage.set("USER_ID", authResponse.UserID).subscribe(() => {});
    //await this.storage.set("USER_NAME", (authResponse.user != undefined ? authResponse.user.name : null));
    this.storage.set("USER_NAME", authResponse.name).subscribe(() => {});
    this.storage.set("USER_TITLE", authResponse.title).subscribe(() => {});
    this.storage
      .set("USER_FIRST_NAME", authResponse.first_name)
      .subscribe(() => {});
    this.storage
      .set("USER_LAST_NAME", authResponse.last_name)
      .subscribe(() => {});
    this.storage.set("USER_EMAIL", authResponse.email).subscribe(() => {});
    this.storage.set("USER_ROLES", authResponse.roles).subscribe(() => {});
    this.storage.set("REMEMBER_ME", rememberMe).subscribe(() => {});

    this.storage.set("Amount", authResponse.Amount).subscribe(() => {});
    this.storage.set("ClientID", authResponse.ClientID).subscribe(() => {});
    this.storage.set("Email", authResponse.Email).subscribe(() => {});
    this.storage.set("IsPGActive", authResponse.IsPGActive).subscribe(() => {});
    this.storage
      .set("IsTermsAccepted", authResponse.IsTermsAccepted)
      .subscribe(() => {});
    this.storage
      .set("JoiningDate", authResponse.JoiningDate)
      .subscribe(() => {});
    this.storage
      .set("JoiningType", authResponse.JoiningType)
      .subscribe(() => {});
    this.storage
      .set("LoginAccess", authResponse.LoginAccess)
      .subscribe(() => {});
    this.storage
      .set("MobileNumber", authResponse.MobileNumber)
      .subscribe(() => {});
    this.storage.set("OrderType", authResponse.OrderType).subscribe(() => {});
    this.storage.set("PlanType", authResponse.PlanType).subscribe(() => {});
    this.storage.set("UserID", authResponse.UserID).subscribe(() => {});
    this.storage.set("UserName", authResponse.UserName).subscribe(() => {});
    this.storage.set("UserType", authResponse.UserType).subscribe(() => {});
    this.storage.set("strUserID", authResponse.strUserID).subscribe(() => {});

    let userModel: UserModel = authResponse;
    this.storage.set("USER_MODEL", userModel).subscribe(() => {});
    this.currentToken = authResponse.accessToken;

    localStorage.setItem("sistoken", authResponse.strUserID);

    let formdata = {
      CompanyId: environment.CompanyID,
      UserName: "sample string 2",
      Password: "sample string 3",
      LoginId: authResponse.UserID,
      MobileNoOrEmailID: authResponse.MobileNumber,
      AppVersionCode: environment.AppVersionCode,
      FireBaseToken: "sample string 7",
      IPAddress: this.ipAddress,
    };

    this.utilsService
      .getClientDetails(formdata)
      .pipe(finalize(() => {}))
      .subscribe(
        (result: any) => {
          if (!result.issuccess) {
            //this.notify.warn(result.message);
          } else if (result.statuscode == 200) {
            let clientDetails = result.result[0];
            this.storage
              .set("CLIENT_DETAILS", clientDetails)
              .subscribe(() => {});
          }
        },
        (err) => {}
      );
    this.authSubject.next(true);
  }

  async logout(reload?: boolean) {
    /*abp.auth.clearToken();
		abp.utils.setCookieValue(AppConsts.authorization.encrptedAuthTokenName, undefined, undefined, abp.appPath);
        if (reload !== false) {
            location.href = AppConsts.appBaseUrl;
        }*/

    this.storage.delete("ACCESS_TOKEN").subscribe(() => {});
    this.storage.delete("EXPIRES_IN").subscribe(() => {});
    this.storage.delete("TOKEN_AT").subscribe(() => {});
    this.storage.delete("EXPIRES_AT").subscribe(() => {});
    this.storage.delete("USER_ID").subscribe(() => {});
    this.storage.delete("USER_NAME").subscribe(() => {});
    this.storage.delete("USER_TITLE").subscribe(() => {});
    this.storage.delete("USER_FIRST_NAME").subscribe(() => {});
    this.storage.delete("USER_LAST_NAME").subscribe(() => {});
    this.storage.delete("USER_EMAIL").subscribe(() => {});
    this.storage.delete("USER_ROLES").subscribe(() => {});
    this.storage.delete("REMEMBER_ME").subscribe(() => {});
    //await this.storage.remove("IS_RADIOLOGIST");
    this.storage.delete("USER_MODEL").subscribe(() => {});
    //await this.storage.remove("currenttimesheet");

    this.storage.delete("Amount").subscribe(() => {});
    this.storage.delete("ClientID").subscribe(() => {});
    this.storage.delete("Email").subscribe(() => {});
    this.storage.delete("IsPGActive").subscribe(() => {});
    this.storage.delete("IsTermsAccepted").subscribe(() => {});
    this.storage.delete("JoiningDate").subscribe(() => {});
    this.storage.delete("JoiningType").subscribe(() => {});
    this.storage.delete("LoginAccess").subscribe(() => {});
    this.storage.delete("MobileNumber").subscribe(() => {});
    this.storage.delete("OrderType").subscribe(() => {});
    this.storage.delete("PlanType").subscribe(() => {});
    this.storage.delete("UserID").subscribe(() => {});
    this.storage.delete("UserName").subscribe(() => {});
    this.storage.delete("UserType").subscribe(() => {});
    this.storage.delete("strUserID").subscribe(() => {});
    this.storage.delete("CART_ITEMS").subscribe(() => {});
    this.storage.delete("CLIENT_DETAILS").subscribe(() => {});

    localStorage.removeItem("sistoken");

    //this.secureStorage.remove();
    this.currentToken = null;
    this.authSubject.next(false);
  }

  //isLoggedIn() {
  //    return this.authSubject.asObservable();
  //}

  isLoggedIn = (): Promise<boolean> => {
    return new Promise<any>((res) => {
      this.storage.get("USER_ID").subscribe((val) => {
        return res(val != undefined && val != null && val > 0 ? true : false);
      });
    });
  };

  loggedIn() {
    return this.isLoggedIn().then((res) => {
      return res;
    });
  }

  /*isRadiologist(): Promise<boolean> {
    return this.storage.get("USER_ROLES").then((res) => {
      return res != null &&
        res.filter((x) => x.name == "radiologist").length > 0
        ? true
        : false;
    });
  }

  isClinician(): Promise<boolean> {
    return this.storage.get("USER_ROLES").then((res) => {
      return res != null && res.filter((x) => x.name == "clinician").length > 0
        ? true
        : false;
    });
  }*/

  getUserModel = (): Promise<UserModel> => {
    return new Promise<any>((res) => {
      this.storage.get("USER_MODEL").subscribe((val) => {
        return res(val);
      });
    });
  };

  getClientDetails = (): Promise<UserModel> => {
    return new Promise<any>((res) => {
      this.storage.get("CLIENT_DETAILS").subscribe((val) => {
        return res(val);
      });
    });
  };

  getAccessToken2(): string {
    return this.currentToken;
  }

  getAccessToken = (): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      this.storage.get("ACCESS_TOKEN").subscribe((res: string) => {
        return resolve(res);
      });
    });
  };

  refreshToken = (): Observable<string> => {
    return new Observable<any>((res) => {
      return (
        this.authService
          .refresh(null)
          //.pipe(finalize(() => { this.saving = false; }))
          .subscribe((result: any) => {
            if (result != null && result.success) {
              //await this.storage.set("ACCESS_TOKEN", result);
              this.storage
                .set("ACCESS_TOKEN", result.data.accessToken)
                .subscribe(() => {});
              this.storage
                .set("EXPIRES_IN", result.data.expireInSeconds)
                .subscribe(() => {});
              //let tokenAt = moment.utc();
              let tokenAt = moment();
              this.storage
                .set("TOKEN_AT", tokenAt.toISOString())
                .subscribe(() => {});
              this.storage
                .set(
                  "EXPIRES_AT",
                  tokenAt
                    .add(result.data.expireInSeconds, "second")
                    .toISOString()
                )
                .subscribe(() => {});
              this.storage
                .set("USER_ID", result.data.userId)
                .subscribe(() => {});
            }

            return observableOf(result ? result.data.accessToken : null).pipe(
              delay(500)
            );
          })
      );
    });
  };

  getToken = (): Observable<string> => {
    return new Observable<any>((res) => {
      this.storage.get("ACCESS_TOKEN").subscribe((val) => {
        return observableOf(val).pipe(delay(200));
      });
    });
  };

  getTokenAt = (): Promise<any> => {
    return new Promise<any>((res) => {
      this.storage.get("TOKEN_AT").subscribe((val) => {
        return res(val);
      });
    });
  };

  getTokenExpiresAt = (): Promise<any> => {
    return new Promise<any>((res) => {
      this.storage.get("EXPIRES_AT").subscribe((val) => {
        return res(val);
      });
    });
  };

  async updateProfile(authResponse: any) {
    this.storage.set("USER_ID", authResponse.userId).subscribe(() => {});
    this.storage.set("USER_NAME", authResponse.name).subscribe(() => {});
    this.storage.set("USER_TITLE", authResponse.title).subscribe(() => {});
    this.storage
      .set("USER_FIRST_NAME", authResponse.first_name)
      .subscribe(() => {});
    this.storage
      .set("USER_LAST_NAME", authResponse.last_name)
      .subscribe(() => {});
    this.storage.set("USER_EMAIL", authResponse.email).subscribe(() => {});

    let userModel: UserModel = authResponse;
    this.storage.set("USER_MODEL", userModel).subscribe(() => {});
  }

  getEPBalance = (): Promise<any> => {
    return new Promise<any>((res) => {
      this.storage.get("Amount").subscribe((val) => {
        return res(val);
      });
    });
  };

  setEPBalance = (amount): Promise<any> => {
    return new Promise<any>((res) => {
      this.storage.set("Amount", amount).subscribe((amount) => {return res(amount);});
    });
  };
}

export class UserModel extends AuthenticateResultModel {}
