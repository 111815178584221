// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  APP_NAME: 'SISPAY',
  APP_TITLE: 'SIS PAY',
  APP_LOGO: 'assets/images/app-logo.svg',
  APP_LANGUAGE: 'en',
  APP_THEME: 'grayscale',
  WEB_URL: 'https://test.sisfinowealth.com/',
  UPLOADS_URL: 'https://test.sisfinowealth.com/',
  API_URL: 'https://services.test.sisfinowealth.com/',
  SECURE_KEY: 'GWs6Lmk24HyOUbaL9FQAWDgdhyu45x5sb3qj48T',
  SECURE_KEY_OTP: 'GWs6Lmk28HyOUbaL9FQAWDgdhyu45x5sb3qj49T',
  WS_URL: '',
  versionCheckURL: '',
  CompanyID: '169',
  AppVersionCode: 2,
  APP_TAGLINE: 'Opportunities Unlimited....',
  TERMSNCONDSURL: 'https://shop.sisfinowealth.com/roots/00_Guest/08_TermsAndConditions.aspx'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
