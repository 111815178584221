import { Injectable, Inject, Optional, InjectionToken } from "@angular/core";
//import { AppAuthService } from "@shared/auth/app-auth.service";
import { UtilsServiceProxy } from "@shared/service-proxies/service-proxies";
import { NgxSpinnerService } from "ngx-spinner";
import { finalize } from "rxjs/operators";
import { NotifyService } from "./notify.service";

@Injectable()
export class UtilsHelper {
  constructor(
    //private appAuthService: AppAuthService,
    public notify: NotifyService,
    public spinner: NgxSpinnerService,
    private utilsService: UtilsServiceProxy
  ) {}

  getReferenceDetails = (referenceId): Promise<ReferenceDetailsModel> => {
    return new Promise<ReferenceDetailsModel>((resolve, reject) => {
      this.spinner.show();
      this.utilsService
        .getReferenceInfo({
          StrReferenceID: referenceId,
          StrSponsorID: referenceId,
        })
        .pipe(
          finalize(() => {this.spinner.hide();})
        )
        .subscribe(
          (result: any) => {
            if (!result.issuccess) {
              this.notify.warn("Invalid Reference Id");
              return resolve(null);
            } else {
              if (result.result[0].ReferenceID) {
                return resolve(result.result[0]);
              }
            }
          },
          (err) => {
            this.spinner.hide();
            return resolve(null);
          }
        );
    });
  };
}

export class ReferenceDetailsModel implements IReferenceDetailsModel {
  name?: string;
  referenceID?: string;

  constructor(data?: IReferenceDetailsModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.name = data["name"];
      this.referenceID = data["referenceID"];
    }
  }

  static fromJS(data: any): ReferenceDetailsModel {
    data = typeof data === "object" ? data : {};
    let result = new ReferenceDetailsModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === "object" ? data : {};
    data["name"] = this.name;
    data["referenceID"] = this.referenceID;
    return data;
  }
}

export interface IReferenceDetailsModel {
  name?: string;
  referenceID?: string;
}
