import { Component, OnInit, Injector, OnDestroy } from "@angular/core";
import { finalize } from "rxjs/operators";
import { NgxPubSubService } from "@pscoped/ngx-pub-sub";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";

import { AppComponentBase } from "@app/shared/app-component-base";
import {
  Category,
  OffersServiceProxy,
} from "@app/shared/service-proxies/service-proxies";
import { environment } from "@environments/environment";

@Component({
  selector: "app-offers",
  templateUrl: "./offers.component.html",
  styleUrls: ["./offers.component.scss"],
})
export class OffersComponent
  extends AppComponentBase
  implements OnInit, OnDestroy {
  public myOffers: any[] = [];
  showResult: boolean = false;
  saving: boolean = false;
  filterParams: any; // = {limit: 10, skip: 0};
  //loading: any;
  index: number = 0;
  count: number = 0;
  perPage: number = 10;
  submitted: boolean = false;
  searchResult: any[] = [];
  searchCapital: any[] = [];
  searchRunning: any[] = [];
  public defaultCatImg: string = "assets/images/default-category.png";
  public defaultImgBrand: string = "assets/images/default-category.png";
  frmFG: FormGroup;
  public categories: Array<Category>;
  public enumsSelected: any[] = [];
  public allSelected: boolean = false;
  public usedStatus: string = "all";

  constructor(
    injector: Injector,
    private fb: FormBuilder,
    public pubsubSvc: NgxPubSubService,
    private offersService: OffersServiceProxy //private namesService: PNamesServiceProxy
  ) {
    super(injector);
    this.filterParams = { limit: this.perPage, skip: 0 };
  }

  ngOnInit(): void {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("pg-home");

    this.frmFG = this.fb.group({
      date_range: new FormControl("", []),
      categories: new FormControl(),
    });
    this.categories = [...[{'id': 'all', 'name': 'All'}], ...this.genums.ordertypes];
    this.enumsSelected["categories"] = [this.categories[0].id];

    this.route.paramMap.subscribe((params) => {
      this.enumsSelected["categories"].push(params.get("categories"));
      if (params.get("categories") == "all") {
        this.allSelected = true;
      }
    });

    this.searchResult = [];
    this.filterParams = { limit: this.perPage, skip: 0 };
    //this.getCategories();
    this.getData();
  }

  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("pg-home");
  }

  getCategories() {
    this.utilsService
      .getCategories({
        pager: {
          skipCount: 0,
          maxResultCount: 10000,
          searchValue: null,
          sortBy: "created_at",
          sorting: "desc",
        },
      })
      .pipe(finalize(() => {}))
      .subscribe(
        (result: any) => {
          this.categories = result.data;
          this.selectAll(this.allSelected);
        },
        (err) => {
          this.categories = [];
        }
      );
  }

  getData() {
    this.appAuthService.isLoggedIn().then((res) => {
      this.isLoggedIn = res;
      if (!this.isLoggedIn) {
        this.notify.warn(this.l("Please login and try again!"));
        this.pubsubSvc.publishEvent("user:openlogin", {
          enableOTP: false,
          email: "",
        });
        return;
      }
      this.saving = true;

      //Object.assign(this.filterParams, form);
      if (this.filterParams.skip == 0) {
        this.pubsubSvc.publishEvent("recomm:refresh", {
          data: this.filterParams,
        });
      }

      this.spinner.show();
      this.offersService
        .getOffers(this.filterParams)
        .pipe(
          finalize(() => {
            this.saving = false;
            this.spinner.hide();
          })
        )
        .subscribe(
          (result: any) => {
            if (!result.issuccess) {
              this.notify.warn(result.message);
            } else if (result.statuscode == 200) {
              if (result.result[0].HomeProductsList) {
                result.result[0].HomeProductsList.forEach((element, index) => {
                  //if (index < this.perPage) {
                  //this.searchResult.push(element);
                  //element.avatar = this.getURL(element.ProductImage);
                  element.avatar =
                    environment.UPLOADS_URL +
                    (element.ProductImage != ""
                      ? element.ProductImage.replace("../../", "")
                      : "");
                  if (element.ProductShoppingType == "Purchase") {
                    this.searchCapital.push(element);
                  } else {
                    this.searchRunning.push(element);
                  }
                  //}
                });
              }
            }
          },
          (err) => {
            this.searchResult = [];
            this.notify.error(err.message);
          }
        );
    });
  }

  doInfinite(event) {
    if (!this.saving && this.filterParams.skip != -1) {
      this.getData();
    }
  }

  doRefresh(event) {
    this.searchResult = [];
    this.filterParams = { limit: this.perPage, skip: 0 };
    this.getData();

    setTimeout(() => {
      event.target.complete();
    }, 1000);
  }

  selectAll(action) {
    this.enumsSelected["categories"] = [];
    this.allSelected = false;
    if (action) {
      this.categories.forEach((element) => {
        this.enumsSelected["categories"].push(element.slug);
      });
      this.allSelected = true;
    }
  }

  async frmSubmit(form: any, isValid: boolean) {
    this.submitted = true;
    this.searchResult = [];
    this.filterParams = { limit: this.perPage, skip: 0 };
    this.filterParams.from_date =
      this.frmFG.value.date_range != null
        ? this.frmFG.value.date_range[0]
        : null;
    this.filterParams.to_date =
      this.frmFG.value.date_range != null
        ? this.frmFG.value.date_range[1]
        : null;
    this.filterParams.categories = this.enumsSelected["categories"];
    this.filterParams.used_status = this.usedStatus;

    if (!isValid) {
      /*this.notify.error(
        "Invalid form inputs. Please enter proper details and try again."
      );*/
    }

    this.getData();
  }

  resetFrm() {
    this.frmFG.reset();
    //this.enumsSelected["categories"] = ['all'];
    this.selectAll(true);
    this.enumsSelected["used_status"] = ["all"];

    this.frmSubmit(this.frmFG.value, this.frmFG.valid);
  }

  onCheckChange2(event, name) {
    let existing = this.enumsSelected["categories"].findIndex(
      (x) => x == event.target.value
    );
    console.log('existing', existing, event.target.value , this.enumsSelected["categories"])
    
    if (existing === -1 && event.target.checked) {
      this.enumsSelected["categories"] = [];
      // Add a new control in the arrayForm
      if(event.target.value == 'all') {
        this.enumsSelected["categories"] = [];
      }
      this.enumsSelected["categories"].push(event.target.value);
    } else {
      if (existing > -1) {
        this.enumsSelected["categories"].splice(existing, 1);
      }
    }
    console.log('this.enumsSelected["categories"]', event.target.value, this.enumsSelected["categories"])
  }

  changeUsedStatus(value) {
    this.usedStatus = value;
  }
}
