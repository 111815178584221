import {
  Component,
  OnInit,
  Injector,
  OnDestroy,
  AfterContentInit,
  HostListener,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { finalize } from "rxjs/operators";
import { NgxPubSubService } from "@pscoped/ngx-pub-sub";
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
  FormArray,
  ValidationErrors,
} from "@angular/forms";

import CryptoJS from "crypto-js";
import Base64 from "crypto-js/enc-base64";
import { AppComponentBase } from "@app/shared/app-component-base";
import {
  AuthServiceProxy,
  EpocketServiceProxy,
  OffersServiceProxy,
  UtilsServiceProxy,
} from "@app/shared/service-proxies/service-proxies";
import { environment } from "@environments/environment";
import { ActivationStart } from "@angular/router";
import * as moment from "moment";
import { UserModel } from "@shared/auth/app-auth.service";
import { Subscription } from "rxjs/internal/Subscription";
import { UtilsHelper } from "@shared/helpers/utils.helper";

@Component({
  selector: "app-addtransfer",
  templateUrl: "./addtransfer.component.html",
  styleUrls: ["./addtransfer.component.scss"],
})
export class AddTransferComponent
  extends AppComponentBase
  implements OnInit, OnDestroy {
  public myOffers: any[] = [];
  showResult: boolean = false;
  saving: boolean = false;
  frmFG: FormGroup;
  public userModel: UserModel;
  public me: any;
  @ViewChild("fileInput") fileInput;
  submitted = false;
  otpSent: boolean = false;
  otpSentValue: number = null;

  validation_messages = {
    currentBalance: [
      { type: "required", message: "Current Balance is required." },
    ],
    amount: [
      { type: "required", message: "Amount is required." },
      {
        type: "amountlessthancb",
        message: "Amount should be less than or equal to Current Balance.",
      },
    ],
    userIDTo: [
      { type: "required", message: "To Partner ID is required." },
      { type: "invalidref", message: "Invalid Partner ID" },
    ],
    remark: [{ type: "required", message: "Remark is required." }],
    otp: [{ type: "required", message: "OTP is required." }],
  };

  constructor(
    injector: Injector,
    private fb: FormBuilder,
    public pubsubSvc: NgxPubSubService,
    private spEpocket: EpocketServiceProxy,
    private authSP: AuthServiceProxy,
    private utilsHelper: UtilsHelper
  ) {
    super(injector);
  }

  ngOnInit(): void {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("pg-epocket");

    this.frmFG = this.fb.group({
      currentBalance: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      amount: new FormControl("", Validators.compose([Validators.required])),
      userIDTo: new FormControl("", Validators.compose([Validators.required])),
      remark: new FormControl(
        "",
        Validators.compose([Validators.required, Validators.maxLength(12)])
      ),
      otp: new FormControl("", Validators.compose([])),
    });

    this.frmFG.get("amount").valueChanges.subscribe((amount) => {
      let cbal = parseFloat(this.frmFG.get("currentBalance").value);
      if (amount > cbal) {
        this.frmFG.get("amount").setErrors({ amountlessthancb: true });
        this.frmFG.get("amount").markAsDirty();
      }
    });

    this.frmFG.get("otp").valueChanges.subscribe((otp) => {
      if (otp == "") {
        this.frmFG.get("otp").setErrors({ required: true });
        this.frmFG.get("otp").markAsDirty();
      }
    });

    this.getUserInfo().then((res) => {
      if (!res) {
        return;
      }
      this.authSP
        .getMe({
          LoginID: this.userModel.strUserID,
          PanelType: this.userModel.UserType,
        })
        .pipe(
          finalize(() => {
            this.spinner.hide();
          })
        )
        .subscribe(
          (result: any) => {
            if (!result.issuccess) {
              this.notify.warn(result.message);
            } else if (result.statuscode == 200) {
              this.me = result.result[0];
              this.frmFG.controls["currentBalance"].setValue(this.me.Balance);
            }
            this.spinner.hide();
          },
          (err) => {
            this.me = {};
            this.showResult = false;
            this.spinner.hide();
          }
        );
    });
  }

  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("pg-home");
  }

  doRefresh(event) {}

  // convenience getter for easy access to form fields
  get f() {
    return this.frmFG.controls;
  }

  chngPaymentMode(event) {}

  chngCompanyAccNo(event) {}

  chngTransactionRefNo(event) {}

  async validateForm(form: any, isValid: boolean) {
    this.getFormValidationErrors();
    if (!isValid) {
      this.frmFG.markAsDirty();
      this.frmFG.markAllAsTouched();

      if (!this.otpSent && !this.frmFG.valid) {
        this.frmFG.markAsDirty();
        this.frmFG.markAllAsTouched();
        return false;
      } else if (this.otpSent && this.frmFG.get("otp").hasError) {
        this.notify.info("Please enter OTP and submit again.");
        this.frmFG.get("otp").setErrors({ required: true });
        this.frmFG.get("otp").markAsDirty();
        this.frmFG.markAsDirty();
        this.frmFG.markAllAsTouched();
        return false;
      }
      this.notify.error(
        "Invalid form inputs. Please enter proper details and try again."
      );
      return false;
    }
    return true;
  }

  async sendOTP(form: any, isValid: boolean) {
    isValid = await this.validateForm(form, isValid);
    if (!isValid) {
      return;
    }
    let formdataotp = {
      StrUserID: this.userModel.strUserID,
      Name: this.userModel.UserName,
      MobileNo: this.userModel.MobileNumber,
      EmailID: this.userModel.Email,
      LoginID: this.userModel.strUserID,
      Domain: this.clientDetails.DomainName,
    };

    this.utilsHelper
      .getReferenceDetails(this.frmFG.get("userIDTo").value)
      .then((res) => {
        if (res == null) {
          this.frmFG.get("userIDTo").setErrors({ invalidref: true });
          this.frmFG.get("userIDTo").markAsDirty();
        } else if (res != null) {
          this.saving = true;
          this.spinner.show();
          this.spEpocket
            .sendePocketTransferOTP(formdataotp)
            .pipe(
              finalize(() => {
                this.saving = false;
                this.spinner.hide();
              })
            )
            .subscribe(
              (result: any) => {
                if (!result.issuccess) {
                  this.notify.warn(result.message);
                } else if (result.statuscode == 200) {
                  this.otpSent = true;
                  this.otpSentValue = result.result[0].OTP;
                  this.notify.info(
                    "OTP will be sent to your registered mobile number. Please enter OTP and submit again."
                  );
                }
              },
              (err) => {
                this.notify.error(err.message);
                this.saving = false;
              }
            );
        }
      });
  }

  async frmSubmit(form: any, isValid: boolean) {
    form.username = form.email;

    if (!this.validateForm(form, isValid)) {
      return;
    }

    const hashDigest2 = Base64.stringify(
        CryptoJS.HmacSHA256(
          this.frmFG.get("otp").value, environment.SECURE_KEY_OTP
        )
    );

    //if (this.otpSentValue != parseInt(this.frmFG.get("otp").value)) {
    if (this.otpSentValue != hashDigest2) {
      this.notify.warn("Invalid OTP");
      return;
    }

    let formdata = {
      UserIDFrom: this.userModel.UserID,
      StrUserIDTo: form.userIDTo,
      Status: "",
      Amount: form.amount,
      PayBy: null,
      UpdatedBy: this.userModel.strUserID,
      Description: form.remark,
      CompanyId: parseInt(environment.CompanyID),
    };

    this.saving = true;
    this.spinner.show();
    this.spEpocket
      .addTransfer(formdata)
      .pipe(
        finalize(() => {
          this.saving = false;
          this.spinner.hide();
        })
      )
      .subscribe(
        (result: any) => {
          if (!result.issuccess) {
            this.notify.warn(result.message);
          } else if (result.statuscode == 200) {
            this.notify.success("Request added successfully.");
            this.pubsubSvc.publishEvent("epocket:epbalchanged", {
              Balance: null
            });
            this.router.navigate([
              "/epocket/transactions",
              { transtype: "all", loaddata: true },
            ]);
          }
          this.saving = false;
        },
        (err) => {
          this.notify.error(err.message);
          this.saving = false;
        }
      );
  }

  frmReset() {
    this.submitted = false;
    this.frmFG.reset();
  }

  getFormValidationErrors() {
    Object.keys(this.frmFG.controls).forEach((key) => {
      const controlErrors: ValidationErrors = this.frmFG.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          console.log(
            "Key control: " + key + ", keyError: " + keyError + ", err value: ",
            controlErrors[keyError]
          );
        });
      }
    });
  }
}
