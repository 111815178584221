import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { THEME_CONFIG } from '@bcodes/ngx-theme-service';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocationStrategy, Location, PathLocationStrategy } from '@angular/common';
//import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image'; // <-- include ScrollHooks
import { NetworkInterface } from '@ionic-native/network-interface/ngx';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { FileOpener } from "@ionic-native/file-opener/ngx";
import { File } from "@ionic-native/file/ngx";

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AboutUsComponent } from './aboutus/aboutus.component';
import { SharedModule } from '@shared/shared.module';
import { PermissionCheckerService } from '@shared/auth/permission-checker.service';
import { AppSessionService } from '@shared/session/app-session.service';
import { IntroPage } from './intro/intro.page';
import { TopBarModule } from './layout/topbar.module';
import { LazyLoadImageModule } from "ng-lazyload-image";
import { OffersModule } from './offers/offers.module';
import { AccountModule } from './account/account.module';
import { EpocketModule } from './epocket/epocket.module';

@NgModule({
  declarations: [AppComponent, AboutUsComponent, IntroPage],
  entryComponents: [],
  exports: [],
  imports: [
    //BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({
      scrollAssist: false, 
      //autoFocusAssist: false
      scrollPadding: false
    }),
    AppRoutingModule,
    SharedModule.forRoot(),
    TopBarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    HttpClientModule,
    LazyLoadImageModule,
    OffersModule, AccountModule, EpocketModule, //KycModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: THEME_CONFIG,
      useValue: {
        themes: ['light', 'dark'],
        defaultTheme: 'light',
        transitionConfig: {
            className: 'theme-transition',
            duration: 1500,
        },
      }
    },
    Location, 
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    PermissionCheckerService, AppSessionService, 
    NetworkInterface, FileOpener, File
  ],
  //schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}