import { Injectable } from "@angular/core";
import html2canvas from "html2canvas";
//declare let html2canvas;
import { FileOpener } from "@ionic-native/file-opener/ngx";
import { File } from "@ionic-native/file/ngx";
import { Platform } from "@ionic/angular";
import domtoimage from "dom-to-image";

@Injectable()
export class Html2canvasService {
  constructor(
    public fileOpener: FileOpener,
    public file: File,
    public platform: Platform
  ) {}

  public html2canvas(ele) {
    if (!ele) {
      return;
    }

    const option = { allowTaint: true, useCORS: true };
    return html2canvas(ele, option)
      .then((canvas) => {
        if (canvas) {
          return canvas.toDataURL("image/png");
        }
        return null;
      })
      .catch((res) => {
        return res;
      });
  }

  /*public download(elementid) {
    if (!elementid) {
      return;
    }

    var domToPdf = require('dom-to-pdf');

    var element = document.getElementById(elementid);
    var options = {
      filename: 'report.pdf'
    };
    domToPdf(element, options, function() {
      console.log('done');
    });
  }*/

  public download(elementid) {
    if (!elementid) {
      return;
    }
    this.convertToImage(elementid);
    return;

    //const element = document.getElementById(elementid);
    //const targetElement = document.getElementById('target').cloneNode(true);

    //const element = document.getElementById("html2canvas");
    //console.log('elementid', elementid)
    //const targetElement = document.getElementById(elementid);

    const element = document.getElementById(elementid);
    //const targetElement = document.getElementById('target').cloneNode(true);
    const targetElement = document.createElement("a");
    element.appendChild(targetElement);

    this.html2canvas(element.firstChild)
      .then((img) => {
        try {
          if (this.platform.is("cordova")) {
            this.saveAndOpenPdf(img, "Image.png");
            setTimeout(() => {
              //element.firstChild.remove();
            }, 1000);
          } else {
            var a = document.createElement("a"); //Create <a>
            //a.href = "data:image/png;base64," + base64URL; //Image Base64 Goes here
            a.href = img;
            a.download = "Image.png"; //File name Here
            a.click(); //Downloaded file
            setTimeout(() => {
              //element.firstChild.remove();
              a.remove();
            }, 1000);
          }
        } catch (error) {
          console.log("err1", error);
        }
      })
      .catch((res) => {
        console.log("err", res);
      });
  }

  public download1(elementid) {
    if (!elementid) {
      return;
    }

    const element = document.getElementById(elementid);
    //const targetElement = document.getElementById('target').cloneNode(true);
    const targetElement = document.createElement("a");
    element.appendChild(targetElement);
    this.html2canvas(element.firstChild)
      .then((img) => {
        var a = document.createElement("a"); //Create <a>
        //a.href = "data:image/png;base64," + base64URL; //Image Base64 Goes here
        a.href = img;
        a.download = "Image.png"; //File name Here
        a.click(); //Downloaded file
        setTimeout(() => {
          //element.firstChild.remove();
          a.remove();
        }, 1000);
      })
      .catch((res) => {
        console.log(res);
      });
  }

  screenshot2(elementid) {
    window.scrollTo(0, 0);
    html2canvas(document.getElementById(elementid), {}).then(function (canvas) {
      document.body.appendChild(canvas);

      // Get base64URL
      var base64URL = canvas
        .toDataURL("image/jpeg")
        .replace("image/jpeg", "image/octet-stream");

      var a = document.createElement("a"); //Create <a>
      //a.href = "data:image/png;base64," + base64URL; //Image Base64 Goes here
      a.href = base64URL;
      a.download = "Image.png"; //File name Here
      a.click(); //Downloaded file
      setTimeout(() => {
        canvas.remove();
      }, 1000);
    });
  }

  saveAndOpenPdf(pdf: string, filename: string) {
    const writeDirectory = this.platform.is("cordova")
      ? this.file.dataDirectory
      : this.file.externalDataDirectory;
    this.file
      .writeFile(
        writeDirectory,
        filename,
        this.convertBase64ToBlob(pdf, "data:image/png;base64"),
        { replace: true }
      )
      .then((res) => {
        this.fileOpener
          .open(writeDirectory + filename, "image/png")
          .catch(() => {
            console.log("Error opening pdf file");
          });
      })
      .catch(() => {
        console.error("Error writing pdf file");
      });
  }

  convertBase64ToBlob(b64Data, contentType): Blob {
    contentType = contentType || "";
    const sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, "");
    b64Data = b64Data.replace(/\s/g, "");
    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  convertToImage(elementid) {
    try {
      //let result = document.querySelector("#result");
      const container = document.getElementById(elementid);
      const result = document.createElement("a");
      //element.appendChild(targetElement);

      domtoimage.toJpeg(container, {bgcolor: 'white', quality: 1}).then((img) => {
        //console.log('dataUrl', img)
        // var img = new Image();
        // img.src = dataUrl;
        // result.appendChild(img);

        // var link = document.createElement('img')
        // link.src = dataUrl

        // // Down below is to open another window with the picture in it and ready to print
        // var WinPrint = window.open(
        //   "",
        //   "",
        //   "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
        // );

        //WinPrint.document.write(link.outerHTML);
        //WinPrint.document.close();
        //WinPrint.focus();
        //WinPrint.print();

        try {
          if (this.platform.is("cordova")) {
            this.saveAndOpenPdf(img, "Image.png");
            setTimeout(() => {
              //element.firstChild.remove();
            }, 1000);
          } else {
            var a = document.createElement("a"); //Create <a>
            //a.href = "data:image/png;base64," + base64URL; //Image Base64 Goes here
            a.href = img;
            a.download = "Image.png"; //File name Here
            a.click(); //Downloaded file
            setTimeout(() => {
              //element.firstChild.remove();
              a.remove();
            }, 1000);
          }
        } catch (error) {
          console.log("err1", error);
        }
      });
    } catch (error) {
      console.log("err1", error);
    }
  }
}
