import { CommonModule } from "@angular/common";
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { HttpClientJsonpModule } from "@angular/common/http";
//import { HttpClientModule } from "@angular/common/http";
import { ReactiveFormsModule, FormControl, FormsModule } from "@angular/forms";
import { NgxMaskModule, IConfig } from "ngx-mask";

import { AccountRoutingModule } from "./account-routing.module";
import { ServiceProxyModule } from "@app/shared/service-proxies/service-proxy.module";

import { AccountComponent } from "./account.component";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { AccountLanguagesComponent } from "./layout/account-languages.component";
import { ProfileComponent } from "./profile/profile.component";
import { EditProfileComponent } from "./profile/edit-profile.component";
import { ForgotPassComponent } from "./forgotpass/forgotpass.component";
import { ResetPassComponent } from "./resetpass/resetpass.component";
import { TopBarModule } from "@app/layout/topbar.module";
//import { ZipcodeInputComponent } from "@shared/components/zipcode/zipcode.component";
import { SharedModule } from "@shared/shared.module";
import { NgxSpinnerModule } from "ngx-spinner";

@NgModule({
  imports: [
    CommonModule,
    //FormsModule,
    //HttpClientModule,
    HttpClientJsonpModule,
    SharedModule,
    ServiceProxyModule,
    AccountRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    NgxMaskModule.forRoot(),
    TopBarModule,
  ],
  exports: [LoginComponent, RegisterComponent],
  declarations: [
    AccountComponent,
    LoginComponent,
    RegisterComponent,
    AccountLanguagesComponent,
    ProfileComponent,
    EditProfileComponent,
    ForgotPassComponent,
    ResetPassComponent, //ZipcodeInputComponent
  ],
  providers: [],
  entryComponents: [],
  //schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AccountModule {}
