import { Component, OnInit } from "@angular/core";
//import { BsModalService, BsModalRef, ModalDirective } from "ngx-bootstrap/modal";

@Component({
  selector: "app-terms",
  templateUrl: "./terms.page.html",
})
export class TermsPage implements OnInit {
  constructor(
    //public bsModalRef: BsModalRef,
    //private modalService: BsModalService
    ) {}

  ngOnInit() {}

  close(): void {}
}