import { Component, Injector, OnInit } from "@angular/core";
import {
  finalize,
  map,
  mergeMap as _observableMergeMap,
  catchError as _observableCatch,
} from "rxjs/operators";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  ValidationErrors,
} from "@angular/forms";
import { AppComponentBase } from "@app/shared/app-component-base";
import { MustMatch } from "@app/shared/validators/must-match.validator";
import { PasswordValidator } from "@app/shared/validators/password.validator";
import { NgxPubSubService } from "@pscoped/ngx-pub-sub";
import { AuthServiceProxy } from "@app/shared/service-proxies/service-proxies";
import { AppAuthService } from "@app/shared/auth/app-auth.service";
import { LoginService } from "@app/shared/auth/login.service";

@Component({
  templateUrl: "./edit-profile.component.html",
  selector: "app-editprofile",
  //animations: [accountModuleAnimation()],
})
export class EditProfileComponent extends AppComponentBase implements OnInit {
  public me: any;
  public showResult: boolean = false;
  public loading: boolean = false;
  saving = false;
  public frmFG: FormGroup;
  submitted = false;
  passwordType: string = "password";
  passwordIcon: string = "eye-off";

  matching_passwords_group: FormGroup;

  validation_messages = {
    username: [
      { type: "required", message: "Username is required." },
      {
        type: "maxlength",
        message: "Username cannot be more than 25 characters long.",
      },
      {
        type: "pattern",
        message: "Your Username must contain only numbers and letters.",
      },
    ],
    first_name: [
      { type: "required", message: "First Name is required." },
      {
        type: "maxlength",
        message: "First Name cannot be more than 25 characters long.",
      },
      {
        type: "pattern",
        message: "Your First Name must contain only numbers and letters.",
      },
    ],
    last_name: [
      { type: "required", message: "Last Name is required." },
      {
        type: "maxlength",
        message: "Last Name cannot be more than 25 characters long.",
      },
      {
        type: "pattern",
        message: "Your Last Name must contain only numbers and letters.",
      },
    ],
    email: [
      { type: "required", message: "Email is required." },
      { type: "pattern", message: "Please wnter a valid email." },
    ],
    phone: [
      { type: "required", message: "Phone is required." },
      //{ type: 'validCountryPhone', message: 'The phone is incorrect for the selected country.' }
    ],
    password: [
      { type: "required", message: "Password is required." },
      {
        type: "minlength",
        message: "Password must be at least 5 characters long.",
      },
      {
        type: "pattern",
        message:
          "Your password must contain at least one uppercase, one lowercase, one number and at least one special character.",
      },
    ],
    confirm_password: [
      { type: "required", message: "Confirm password is required." },
    ],
    matching_passwords: [{ type: "areEqual", message: "Password mismatch." }],
    terms_and_cond: [
      { type: "pattern", message: "You must accept terms and conditions." },
    ],
  };

  constructor(
    injector: Injector,
    private fb: FormBuilder,
    public pubsubSvc: NgxPubSubService,
    private authSP: AuthServiceProxy,
    public appAuthService: AppAuthService
  ) {
    super(injector);
  }

  ngOnInit() {
    //var body = document.getElementsByTagName("body")[0];
    //body.classList.add("register-page");

    this.appAuthService.isLoggedIn().then(
      (res) => {
        //console.log("isLoggedIn res", res);
        this.isLoggedIn = res;
      },
      (err) => {}
    );

    this.matching_passwords_group = new FormGroup(
      {
        password: new FormControl(
          "",
          Validators.compose([
            Validators.minLength(5),
            //Validators.required,
            Validators.pattern(
              //"^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+$"
              "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{5,}$"
            ),
          ])
        ),
        confirm_password: new FormControl("", []),
      },
      (formGroup: FormGroup) => {
        return PasswordValidator.areEqual(formGroup);
      }
    );

    this.frmFG = this.fb.group(
      {
        email: new FormControl("", Validators.required),
        username: new FormControl(
          "",
          Validators.compose([
            Validators.maxLength(25),
            Validators.required,
            //Validators.pattern("^(?=.*[a-z])[a-zA-Z0-9]+$"),
          ])
        ),
        first_name: new FormControl(
          "",
          Validators.compose([
            Validators.maxLength(25),
            Validators.required,
            //Validators.pattern("^(?=.*[a-z])[a-zA-Z0-9]+$"),
          ])
        ),
        last_name: new FormControl(
          "",
          Validators.compose([
            Validators.maxLength(25),
            Validators.required,
            //Validators.pattern("^(?=.*[a-z])[a-zA-Z0-9]+$"),
          ])
        ),
        matching_passwords: this.matching_passwords_group,
        /*meta: this.fb.group({
          //phone: new FormControl(null, Validators.required),
          terms_and_cond: new FormControl(true, Validators.pattern("true")),
        }),*/
      } /*,
      (formGroup: FormGroup) => {
        return PasswordValidator.areEqual(formGroup);
      }*/
    );

    //this.frmFG.get("meta.phone").setValue("");
    //this.frmFG.get("meta.terms_and_cond").setValue(true);

    this.getDetails();
  }

  getDetails() {
    this.getUserInfo().then((res) => {
        if(!res) {return;}
        this.spinner.show();
        this.authSP
          .getMe({
            LoginID: this.userModel.strUserID,
            PanelType: this.userModel.UserType
          })
          .pipe(
            finalize(() => {
              this.spinner.hide();
            })
          )
          .subscribe(
            (result: any) => {
              if (!result.issuccess) {
                this.notify.warn(result.message);
              } else if (result.statuscode == 200) {
                this.me = result.result[0];
                this.frmFG.get("email").setValue(this.me.email);
                this.frmFG.get("username").setValue(this.me.name);
                this.frmFG.get("first_name").setValue(this.me.first_name);
                this.frmFG.get("last_name").setValue(this.me.last_name);
                //this.frmFG.get("email").setValue(this.me.email);
                //this.frmFG.get("email").setValue(this.me.email);
              }
              this.showResult = true;
              
              this.spinner.hide();
            },
            (err) => {
              this.me = {};
              this.showResult = false;
              this.spinner.hide();
            }
          );
      },
      (err) => {}
    );
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.frmFG.controls;
  }

  ngOnDestroy() {
    //var body = document.getElementsByTagName("body")[0];
    //body.classList.remove("register-page");
  }

  back(): void {
    this.router.navigate(["/home"]);
  }

  tncChange(event) {
    //this.frmFG.get("meta.terms_and_cond").setValue(event.srcElement.checked);
  }

  async Passwordmismatch() {
    this.notify.warn("Please enter correct password");
  }

  async frmSubmit(form: any, isValid: boolean) {
    form.username = form.email;
    /*if (!this.checkNetwork) {
      this.notify.error("No internet connection!");
    }*/

    this.getFormValidationErrors();
    if (!isValid) {
      this.frmFG.markAsDirty();
      this.frmFG.markAllAsTouched();
      this.notify.error(
        "Invalid form inputs. Please enter proper details and try again."
      );
      return;
    }

    this.appAuthService.isLoggedIn().then(
      (res) => {
        this.isLoggedIn = res;
        if(!this.isLoggedIn) {
          this.notify.warn(this.l("Please login and try again!"));
          this.pubsubSvc.publishEvent("user:openlogin", {
            enableOTP: false,
            email: ''
          });
          return;
        }

        this.saving = true;
        form.password = form.matching_passwords.password;

        this.spinner.show();
        this.authSP
          .updateProfile(form)
          .pipe(
            finalize(() => {
              this.saving = false;
              this.spinner.hide();
            })
          )
          .subscribe(
            (result: any) => {
              if (!result.success) {
                this.notify.warn(this.l("Update failed. Please try again."));
              } else {
                this.notify.success(this.l("Update completed successfully"));
                this.router.navigate(["/account/edit-profile"]);
              }
              this.saving = false;
            },
            (err) => {
              /*console.log(
                "Error: " + err.error,
                "Name: " + err.name,
                "Message: " + err.message,
                "Status: " + err.status
              );*/
              this.notify.error(err.message);
              this.saving = false;
            }
          );
      },
      (err) => {}
    );
  }

  onReset() {
    this.submitted = false;
    this.frmFG.reset();
  }

  async openTNCPage() {
    /*const modal = await this.modalCtrl.create({
      component: TermsPage,
    });
    return await modal.present();*/
  }

  /**
   * Marks all controls in a form group as touched
   * @param formGroup - The form group to touch
   */
  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  getFormValidationErrors() {
    Object.keys(this.frmFG.controls).forEach((key) => {
      const controlErrors: ValidationErrors = this.frmFG.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          console.log(
            "Key control: " + key + ", keyError: " + keyError + ", err value: ",
            controlErrors[keyError]
          );
        });
      }
    });
  }
}
