import { Injectable, Inject, Optional, InjectionToken } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class NotifyService {
  private defaultOptions: any = { timeOut: 3000, positionClass: 'toast-bottom-center', enableHtml: true };
  constructor(public toastCtrl: ToastrService) {}

  async info(message: string, title?: string, options?: any) {
    //if(!options.positionClass) {options.positionClass = this.defaultOptions.positionClass}
    options = {...this.defaultOptions, ...options};
    await this.toastCtrl.info(message, title, (!options ? this.defaultOptions : options));
  }

  async success(message: string, title?: string, options?: any) {
    options = {...this.defaultOptions, ...options};
    await this.toastCtrl.success(message, title, (!options ? this.defaultOptions : options));
  }

  async warn(message: string, title?: string, options?: any) {
    options = {...this.defaultOptions, ...options};
    await this.toastCtrl.warning(message, title, (!options ? this.defaultOptions : options));
  }

  async error(message: string, title?: string, options?: any) {
    options = {...this.defaultOptions, ...options};
    await this.toastCtrl.error(message, title, (!options ? this.defaultOptions : options));
  }
}
