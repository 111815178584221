import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { CommonModule } from "@angular/common";

import { TopBarComponent } from "./topbar.component";
import { IonicModule } from "@ionic/angular";
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { LazyLoadImageModule } from "ng-lazyload-image";
import { RouterModule } from "@angular/router";
import { SideBarComponent } from "./sidebar.component";

@NgModule({
  imports: [
    CommonModule,
    IonicModule.forRoot(),
    //AppRoutingModule
    RouterModule,
    BsDropdownModule.forRoot(),
    LazyLoadImageModule,
  ],
  declarations: [TopBarComponent, SideBarComponent],
  providers: [],
  exports: [TopBarComponent, SideBarComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TopBarModule {}
