import { Component, Injector, Input, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { MenuController } from "@ionic/angular";
import { NgxPubSubService } from "@pscoped/ngx-pub-sub";
import { AppComponentBase } from "@shared/app-component-base";
import { UserModel } from "@shared/auth/app-auth.service";
import { AuthServiceProxy, CartItem } from "@shared/service-proxies/service-proxies";
import { CartService } from "@shared/services/cart.service";
import { consolidateAdaptiveIconResources } from "cordova-res/dist/platform";
import { Subscription } from "rxjs";
import { filter, finalize, pairwise } from "rxjs/operators";

@Component({
  templateUrl: "./topbar.component.html",
  selector: "top-bar",
})
export class TopBarComponent extends AppComponentBase implements OnInit {
  @Input() title: string;
  public userModel: UserModel;
  public cartCount: number = 0;
  cartItems: CartItem[] = [];
  public subscartchanged: Subscription;
  public subEPBalChanged: Subscription;
  public defaultImgProfile: string = "assets/img/default-avatar.png";
  baseBackUrl: string;
  currentUrl: string;
  showBackBtn: boolean = true;
  EPBalance: number = 0;

  constructor(
    injector: Injector,
    public pubsubSvc: NgxPubSubService,
    private cartService: CartService,
    router: Router,
    private menu: MenuController,
    private authSP: AuthServiceProxy
  ) {
    super(injector);
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.currentUrl = event.url;
        if (event.url.indexOf("/epocket/requests") !== -1) {
          this.baseBackUrl = "/epocket";
        } else if (event.url.indexOf("/epocket/") !== -1) {
          this.baseBackUrl = "/epocket";
        } else if (event.url.indexOf("/orders/incomehistory") !== -1) {
          this.baseBackUrl = "/home";
        } else if (event.url.indexOf("/orders/paymenthistory") !== -1) {
          this.baseBackUrl = "/home";
        } else if (event.url.indexOf("/orders/") !== -1) {
          this.baseBackUrl = "/orders";
        } else if (event.url.indexOf("/offers/") !== -1) {
          this.baseBackUrl = "/offers";
        } /*else if (event.url.indexOf("/home") !== -1) {
          this.baseBackUrl = "";
        }*/ else if (
          event.url.indexOf("/account/forgotpass") !== -1
        ) {
          this.baseBackUrl = "/account/login";
        } else {
          this.baseBackUrl = "/home";
        }
        this.showBackBtn = this.currentUrl != '' && this.currentUrl != '/' && this.currentUrl?.indexOf('/home') === -1;
      });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        pairwise()
      )
      .subscribe((event: any[]) => {
        //if (event[0] != undefined && event[0].url == "/account/login") {
        //this.spinner.show();
        //this.pubsubSvc.publishEvent("user:login", {temp: 1});
        //this.router.navigate(["/home"], { replaceUrl: true });
        //window.location.reload();
        //}
      });

    this.subscartchanged = this.pubsubSvc.subscribe("cart:changed", (data) => {
      if (data == null || data == undefined) {
        return;
      }
      this.refresh();
    });

    this.subEPBalChanged = this.pubsubSvc.subscribe("epocket:epbalchanged", (data) => {
      //console.log('epocket:epbalchanged', data)
      //if (this.mdlOpenLogin) {
      //  return;
      //}
      if (data != null) {
        /*if(data.Balance != null) {
          this.appAuthService.setEPBalance(data.Balance).then((res) => {
            this.EPBalance = res;
          });
        } else {
          this.getMe();
        }*/
      }
    });
  }

  openMenu() {
    this.menu.enable(false, "navleft");
    this.menu.open("navleft");
  }

  ngOnInit(): void {
    //this.selectedThemeCssClass = this.setting.get('App.UiTheme');
    //$('body').addClass('theme-' + this.selectedThemeCssClass);

    /*this.route.queryParams.subscribe((params) => {
      if (params["t"]) {
        this.cartService.getCartItems().then((val) => {
          if(val != null && val != undefined) {
            this.cartCount = val.length;
          }
        });
      }
    });*/

    this.getUserInfo().then((res) => {
      if (!res) {
        return;
      }
      //this.defaultImgProfile = this.userModel.p
      //this.refresh();
      this.cartService.getCartItems().then((val) => {
        if (val != null && val != undefined) {
          this.cartCount = val.length;
        }
      });
    });
  }

  gotohome() {
    //if(this.baseBackUrl != '') {
    //this.router.navigate([""]).then((e) => {
    this.router.navigate(["/home"]);
    //});
    //}
  }

  gotocart() {
    //this.router.navigate([""]).then((e) => {
    this.router.navigate(["/cart"]);
    //});
  }

  gotoprofile() {
    //this.router.navigate([""]).then((e) => {
    this.router.navigate(["/account/profile"]);
    //});
  }

  refresh() {
    this.cartService.getCartCount().then((val) => {
      this.cartCount = val;

      /*const cntall = document.querySelectorAll('cnt-shortlisted');
      if(cntall.length > 0) {
        for (var i = 0; i < cntall.length; i++) {
          cntall[i].innerHTML = val.toString();
        }
    }*/

      /*const html = document.getElementById("cnt-shortlisted");
      if(html != null) {
        html.innerHTML = val.toString();
        //this.pubsubSvc.publishEvent("cart:changed", { temp: val });
      }*/
    });

    /*this.cartService.getCartItems().then((res) => {
      this.cartItems = res;
    });*/
  }

  getMe() {
    this.authSP
      .getMe({
        LoginID: this.userModel.strUserID,
        PanelType: this.userModel.UserType,
      })
      .pipe(
        finalize(() => {
          //this.spinner.hide();
          //this.loadingDeskB = false;
        })
      )
      .subscribe(
        (result: any) => {
          //this.spinner.hide();
          if (!result.issuccess) {
            this.notify.warn(result.message);
          } else if (result.statuscode == 200) {
            let me = result.result[0];
            this.EPBalance = parseInt(me.Balance);
            this.appAuthService.setEPBalance(this.EPBalance).then((res) => {});
          }
          //this.spinner.hide();
        },
        (err) => {
          //this.spinner.hide();
        }
      );
  }
}
