import { Component, Injector, OnInit, Input } from "@angular/core";
import {
  finalize,
  mergeMap as _observableMergeMap,
  catchError as _observableCatch,
} from "rxjs/operators";
import { NgxPubSubService } from "@pscoped/ngx-pub-sub";
import { BsModalRef } from "ngx-bootstrap/modal";
import { AppComponentBase } from "@app/shared/app-component-base";
import { OffersServiceProxy } from "@app/shared/service-proxies/service-proxies";
import { AppAuthService } from "@app/shared/auth/app-auth.service";
import { CartService } from "@shared/services/cart.service";
import { environment } from "@environments/environment";

@Component({
  selector: "app-offerpo",
  templateUrl: "./offerpo.component.html",
})
export class OfferpoComponent extends AppComponentBase implements OnInit {
  userModel: any;
  isInCart: boolean = false;
  isLoggedIn: boolean = false;
  active: boolean = false;
  saving: boolean = false;
  loading: boolean = false;
  submitted: boolean = false;
  formData: FormData = new FormData();
  useroffer_slug: string = null;
  userOffer: any = null;
  modalRef: BsModalRef;
  data: any = null;
  selectedOffer: any = {};
  public addinfo: boolean = true;
  public thruWeb: boolean = false;
  public thruStore: boolean = false;
  @Input() slug: string;
  @Input() ismodal: boolean = true;
  hasData: boolean = false;
  public defaultImgBrand: string = "assets/images/default-category.png";

  constructor(
    injector: Injector,
    public appAuthService: AppAuthService,
    public pubsubSvc: NgxPubSubService,
    public bsModalRef: BsModalRef,
    private offersService: OffersServiceProxy,
    private cartService: CartService
  ) {
    super(injector);
  }

  ngOnInit() {
    if (this.slug != null) {
      this.useroffer_slug = this.slug;
    } else {
      if (this.data && this.data != null) {
        this.useroffer_slug = this.data.slug;
      }
    }

    if (this.useroffer_slug != null) {
      this.getData();
    } else {
    }

    this.route.paramMap.subscribe((params) => {
      this.useroffer_slug = params.get("slug");
      this.getData();
    });
  }

  getData() {
    this.loading = true;
    this.spinner.show();
    this.offersService
      .get({ PackageCode: this.useroffer_slug })
      .pipe(
        finalize(() => {
          this.loading = false;
          //this.spinner.hide();
        })
      )
      .subscribe(
        (result: any) => {
          if (!result.issuccess) {
            this.notify.warn(result.message);
          } else if (result.statuscode == 200) {
            this.selectedOffer = this.userOffer = result.result[0];
            this.userOffer.avatar =
              environment.UPLOADS_URL +
              (this.userOffer.ProductImage != ""
                ? this.userOffer.ProductImage.replace("../../", "").replace(
                    "~/",
                    ""
                  )
                : "");
          }
          this.spinner.hide();
        },
        (err) => {
          this.loading = false;
          this.spinner.hide();
        }
      );
  }

  addToCart(itm): void {
    itm.Quantity = 1;
    itm.PackageCode = this.useroffer_slug; // itm.PackageCode;
    this.cartService.addToCart(itm).then((val: boolean) => {
      if (val) {
        this.isInCart = val;
        this.pubsubSvc.publishEvent("cart:changed", itm);
        this.pubsubSvc.publishEvent("cart:added", itm);
        setTimeout(() => {
          this.router.navigate(["/cart"], {replaceUrl: true})
        }, 2000);
      } else {
        this.notify.warn(this.l("Cart entry failed."));
      }
    });
  }

  removeFromCart(itm) {
    this.cartService.removeFromCart(itm).then((val: boolean) => {
      this.isInCart = !val;
      if (val) {
        const html = document.getElementById("nbx-" + itm.id);
        if (
          html.className.indexOf("nbx-shortlisted") !== -1 &&
          html.className.indexOf("existing") === -1
        ) {
          html.remove();
        }
        this.pubsubSvc.publishEvent("cart:changed", itm);
      }
    });
  }

  get aokeys() {
    return this.userOffer.addtitionaloffers
      ? Object.keys(this.userOffer.addtitionaloffers)
      : null;
  }

  copyCode(val: string) {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }

  viewOffer(slug) {
    this.useroffer_slug = slug;
    this.getData();
  }
}
