import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AppConsts } from "../AppConsts";

export class AuthenticateModel implements IAuthenticateModel {
  userNameOrEmailAddress: string;
  password: string;
  rememberClient: boolean | undefined;

  constructor(data?: IAuthenticateModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.userNameOrEmailAddress = data["userNameOrEmailAddress"];
      this.password = data["password"];
      this.rememberClient = data["rememberClient"];
    }
  }

  static fromJS(data: any): AuthenticateModel {
    data = typeof data === "object" ? data : {};
    let result = new AuthenticateModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === "object" ? data : {};
    data["userNameOrEmailAddress"] = this.userNameOrEmailAddress;
    data["password"] = this.password;
    data["rememberClient"] = this.rememberClient;
    return data;
  }

  clone(): AuthenticateModel {
    const json = this.toJSON();
    let result = new AuthenticateModel();
    result.init(json);
    return result;
  }
}

export interface IAuthenticateModel {
  userNameOrEmailAddress: string;
  password: string;
  rememberClient: boolean | undefined;
}

export class AuthenticateResultModel implements IAuthenticateResultModel {
  accessToken: string | undefined;
  encryptedAccessToken: string | undefined;
  expireInSeconds: number | undefined;
  userId: number | undefined;

  constructor(data?: IAuthenticateResultModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.accessToken = data["accessToken"];
      this.encryptedAccessToken = data["encryptedAccessToken"];
      this.expireInSeconds = data["expireInSeconds"];
      this.userId = data["userId"];
    }
  }

  static fromJS(data: any): AuthenticateResultModel {
    data = typeof data === "object" ? data : {};
    let result = new AuthenticateResultModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === "object" ? data : {};
    data["accessToken"] = this.accessToken;
    data["encryptedAccessToken"] = this.encryptedAccessToken;
    data["expireInSeconds"] = this.expireInSeconds;
    data["userId"] = this.userId;
    return data;
  }

  clone(): AuthenticateResultModel {
    const json = this.toJSON();
    let result = new AuthenticateResultModel();
    result.init(json);
    return result;
  }
}

export interface IAuthenticateResultModel {
  accessToken: string | undefined;
  encryptedAccessToken: string | undefined;
  expireInSeconds: number | undefined;
  userId: number | undefined;
}

@Injectable()
export class LoginService {
  static readonly twoFactorRememberClientTokenName =
    "TwoFactorRememberClientToken";

  authenticateModel: AuthenticateModel;
  authenticateResult: AuthenticateResultModel;
  //model: any = {userName: '', password: ''};
  rememberMe: boolean;

  constructor(
    //private _tokenAuthService:any,  //TokenAuthServiceProxy,
    private _router: Router /*private _utilsService: UtilsService,
        private _messageService: MessageService,
        private _tokenService: TokenService,
        private _logService: LogService,
        private _userService: UserServiceProxy*/
  ) {
    this.clear();
  }

  authenticate(finallyCallback?: () => void): void {
    finallyCallback = finallyCallback || (() => {});
    /*this._userService
            .authenticate(new LoginInput({userName: this.authenticateModel.userNameOrEmailAddress, password: this.authenticateModel.password}))
            .pipe(finalize(() => { finallyCallback(); }))
            //.subscribe((result: OutputWarapperOfBoolean) => {
            .subscribe((result: OutputWarapperOfAuthenticateResult) => {
                if(result.isSuccess) {
                    this.processAuthenticateResult(result.outputObject[0]);
                } else {
                    this._logService.warn('Unexpected authenticateResult!');
                    this._router.navigate(['account/login']);
                }
            });*/
  }

  private processAuthenticateResult(authenticateResult: any) {
    this.authenticateResult = authenticateResult;
    if (authenticateResult.accessToken) {
      // Successfully logged in
      this.login(
        authenticateResult.accessToken,
        authenticateResult.encryptedAccessToken,
        authenticateResult.expireInSeconds,
        this.rememberMe
      );
    } else {
      // Unexpected result!
      //this._logService.warn('Unexpected authenticateResult!');
      this._router.navigate(["account/login"]);
    }
  }

  private login(
    accessToken: string,
    encryptedAccessToken: string,
    expireInSeconds: number,
    rememberMe?: boolean
  ): void {
    const tokenExpireDate = rememberMe
      ? new Date(new Date().getTime() + 1000 * expireInSeconds)
      : undefined;

    /*this._tokenService.setToken(
            accessToken,
            tokenExpireDate
        );

        this._utilsService.setCookieValue(
            AppConsts.authorization.encryptedAuthTokenName,
            encryptedAccessToken,
            tokenExpireDate,
            //abp.appPath
        );*/

    /*let initialUrl = UrlHelper.initialUrl;
        if (initialUrl.indexOf('/login') > 0) {
            initialUrl = AppConsts.appBaseUrl;
        }
        location.href = (initialUrl == undefined ? AppConsts.appBaseUrl + '/app/home' : initialUrl);*/
    //this._router.navigate(['app/home']);
    location.href = AppConsts.appBaseUrl + "/#/app/home";
  }

  private clear(): void {
    this.authenticateModel = new AuthenticateModel();
    //this.authenticateModel.rememberClient = false;
    this.authenticateResult = null;
    this.rememberMe = false;
  }
}
