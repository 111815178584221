import {
  NgModule,
  Injector,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgxSpinnerModule } from "ngx-spinner";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxMaskModule, IConfig } from "ngx-mask";
import { CrystalLightboxModule } from "@crystalui/angular-lightbox";

import { EpocketRoutingModule } from "./epocket-routing.module";
import { AppInjector } from "./app-injector.service";
import { ServiceProxyModule } from "@app/shared/service-proxies/service-proxy.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { FooterBarModule } from "@app/layout/footerbar.module";
import { SharedModule } from "@shared/shared.module";
import { TopBarModule } from "@app/layout/topbar.module";
import { IonicModule } from "@ionic/angular";
import { LazyLoadImageModule } from "ng-lazyload-image";
import { EpocketLayoutComponent } from "./layout/epocket-layout.component";
import { EpocketComponent } from "./epocket.component";
import { AddRequestComponent } from "./addrequest/addrequest.component";
import { AddRequestOnlineComponent } from "./addrequestonline/addrequestonline.component";
import { RequestListComponent } from "./requestlist/requestlist.component";
import { TransferListComponent } from "./transferlist/transferlist.component";
import { AddTransferComponent } from "./addtransfer/addtransfer.component";
import { AddRequestOflineComponent } from "./addrequest/addrequestoffline.component";
import { EditRequestOflineComponent } from "./addrequest/editrequestoffline.component";
//import { ModalModule } from "ngx-bootstrap/modal";

@NgModule({
  declarations: [
    EpocketComponent,
    EpocketLayoutComponent,
    AddRequestComponent,
    AddRequestOnlineComponent, EditRequestOflineComponent,
    AddRequestOflineComponent,
    RequestListComponent,
    TransferListComponent,
    AddTransferComponent
  ],
  entryComponents: [],
  exports: [],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    ServiceProxyModule,
    NgxSpinnerModule,
    EpocketRoutingModule,
    LazyLoadImageModule,
    BsDatepickerModule.forRoot(),
    IonicModule.forRoot(),
    FooterBarModule,
    TopBarModule, ImageCropperModule,
    //ModalModule.forRoot(),
    NgxMaskModule.forRoot(),
    CrystalLightboxModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  //schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [EpocketLayoutComponent],
})
export class EpocketModule {
  constructor(injector: Injector) {
    AppInjector.injector = injector;
  }
}
