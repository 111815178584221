import { Component, OnInit, Injector, OnDestroy } from "@angular/core";
import { finalize } from "rxjs/operators";
import { NgxPubSubService } from "@pscoped/ngx-pub-sub";
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
  FormArray,
  ValidationErrors,
} from "@angular/forms";

import { AppComponentBase } from "@app/shared/app-component-base";
import {
  AuthServiceProxy,
  Category,
  EpocketServiceProxy,
  OffersServiceProxy,
  UtilsServiceProxy,
} from "@app/shared/service-proxies/service-proxies";
import * as moment from "moment";

@Component({
  selector: "app-transferlist",
  templateUrl: "./transferlist.component.html",
  styleUrls: ["./transferlist.component.scss"],
})
export class TransferListComponent
  extends AppComponentBase
  implements OnInit, OnDestroy {
  public myOffers: any[] = [];
  showResult: boolean = false;
  saving: boolean = false;
  filterParams: any; // = {limit: 10, skip: 0};
  //loading: any;
  index: number = 0;
  count: number = 0;
  perPage: number = 10;
  submitted: boolean = false;
  searchResult: any[] = [];
  searchCapital: any[] = [];
  searchRunning: any[] = [];
  public defaultCatImg: string = "assets/images/default-category.png";
  public defaultImgBrand: string = "assets/images/default-brand.png";
  frmFG: FormGroup;
  public categories: Array<Category>;
  public enumsSelected: any[] = [];
  public allSelected: boolean = false;
  public usedStatus: string = null;
  minDate: Date;
  maxDate: Date;

  constructor(
    injector: Injector,
    private fb: FormBuilder,
    public pubsubSvc: NgxPubSubService,
    //private utilsService: UtilsServiceProxy,
    //private authSP: AuthServiceProxy,
    private spEpocket: EpocketServiceProxy
  ) //private offersService: OffersServiceProxy //private namesService: PNamesServiceProxy
  {
    super(injector);
    this.filterParams = { limit: this.perPage, skip: 0 };

    this.minDate = new Date();
    this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate() - 1);
    this.maxDate.setDate(this.maxDate.getDate());
  }

  ngOnInit(): void {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("pg-home");

    this.frmFG = this.fb.group({
      date_range: new FormControl("", []),
      categories: new FormControl(),
    });

    //this.frmFG.controls["date_range"].setValue([new Date(), new Date()]);
    this.categories = this.genums.epockettranstypes;
    this.enumsSelected["categories"] = [this.categories[0].id];
    this.enumsSelected["dateftype"] = [];

    this.route.paramMap.subscribe((params) => {
      if (params.get("categories") != null) {
        this.enumsSelected["categories"].push(params.get("categories"));
        if (params.get("categories") == "all") {
          this.allSelected = true;
        }
      }
      if (params.get("transtype") != null) {
        this.enumsSelected["categories"] = [];
        this.enumsSelected["categories"].push(params.get("transtype"));
      }
      if (params.get("loaddata") != null) {
        this.frmSubmit(this.frmFG, this.frmFG.valid);
      }
    });

    this.searchResult = [];
    this.filterParams = { limit: this.perPage, skip: 0 };
    //this.getData();
  }

  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("pg-home");
  }

  getData() {
    this.getUserInfo().then((res) => {
      if (!res) {
        return;
      }
      this.filterParams.UserID = this.userModel.UserID;
      this.filterParams.LastIndex = this.filterParams.skip;
      this.saving = true;
      this.spinner.show();
      this.spEpocket
        .getTransactions(this.filterParams)
        .pipe(
          finalize(() => {
            this.saving = false;
            this.spinner.hide();
          })
        )
        .subscribe(
          (result: any) => {
            if (!result.issuccess) {
              this.notify.warn(result.message);
            } else if (result.statuscode == 200) {
              if (result.result.length > 0) {
                result.result.forEach((element, index) => {
                  if (index < this.perPage) {
                    this.searchResult.push(element);
                  }
                });
                if(result.result.length > this.perPage - 1) {
                  this.filterParams.skip += (this.filterParams.limit);
                } else {
                  this.filterParams.skip = -1;
                }
              }
            }
          },
          (err) => {
            this.searchResult = [];
            this.notify.error(err.message);
          }
        );
    });
  }

  doInfinite(event) {
    if (!this.saving && this.filterParams.skip != -1) {
      this.getData();
      event.target.complete();
    }
  }

  doRefresh(event) {
    this.searchResult = [];
    this.filterParams = { limit: this.perPage, skip: 0 };
    this.getData();

    setTimeout(() => {
      event.target.complete();
    }, 1000);
  }

  clearDateRange(event) {
    this.frmFG.controls["date_range"].setValue(null);
  }

  selectAll(action) {
    this.enumsSelected["categories"] = [];
    this.allSelected = false;
    if (action) {
      this.categories.forEach((element) => {
        this.enumsSelected["categories"].push(element.slug);
      });
      this.allSelected = true;
    }
  }

  async frmSubmit(form: any, isValid: boolean) {
    this.submitted = true;
    this.searchResult = [];
    this.filterParams = { limit: this.perPage, skip: 0 };
    this.filterParams.FromDate =
      (this.frmFG.value.date_range != null && this.frmFG.value.date_range != '')
        ? moment(this.frmFG.value.date_range[0])
            .format(this.gconsts.shortdateformat)
        : null;
    this.filterParams.ToDate =
    (this.frmFG.value.date_range != null && this.frmFG.value.date_range != '')
        ? moment(this.frmFG.value.date_range[1])
            .format(this.gconsts.shortdateformat)
        : null;
    this.filterParams.TrnType =
      this.enumsSelected["categories"].length > 0
        ? (this.enumsSelected["categories"][0] == 'all' ? null : this.enumsSelected["categories"][0])
        : null; // Payment Mode
    this.filterParams.Status = this.usedStatus;
    this.filterParams.LastIndex = 0;
    if (!isValid) {
      /*this.notify.error(
        "Invalid form inputs. Please enter proper details and try again."
      );*/
    }
    this.getData();
  }

  resetFrm() {
    this.frmFG.reset();
    //this.enumsSelected["categories"] = ['all'];
    this.selectAll(true);
    this.enumsSelected["used_status"] = ["all"];
    this.enumsSelected["categories"] = [this.categories[0].id];

    this.frmSubmit(this.frmFG.value, this.frmFG.valid);
  }

  onCheckChange2(event, name) {
    this.enumsSelected["categories"] = [];
    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      this.enumsSelected["categories"].push(event.target.value);
    } else {
      let existing = this.enumsSelected["categories"].findIndex(
        (x) => x == event.target.value
      );
      if (existing > -1) {
        this.enumsSelected["categories"].splice(existing, 1);
      }
    }
  }

  onCheckdateftype(event, name) {
    this.enumsSelected["dateftype"] = [];
    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      this.enumsSelected["dateftype"].push(event.target.value);
    } else {
      let existing = this.enumsSelected["dateftype"].findIndex(
        (x) => x == event.target.value
      );
      if (existing > -1) {
        this.enumsSelected["dateftype"].splice(existing, 1);
      }
    }
  }

  changeUsedStatus(value) {
    this.usedStatus = value;
  }
}
