import { Component, OnInit, Injector, OnDestroy } from "@angular/core";
import { finalize } from "rxjs/operators";
import { NgxPubSubService } from "@pscoped/ngx-pub-sub";

import { AppComponentBase } from "@app/shared/app-component-base";
import { AuthServiceProxy } from "@app/shared/service-proxies/service-proxies";
import { UserModel } from "@shared/auth/app-auth.service";

@Component({
  selector: "app-epocket",
  templateUrl: "./epocket.component.html",
  styleUrls: ["./epocket.component.scss"],
})
export class EpocketComponent
  extends AppComponentBase
  implements OnInit, OnDestroy {
  public myOffers: any[] = [];
  showResult: boolean = false;
  saving: boolean = false;
  filterParams: any; // = {limit: 10, skip: 0};
  index: number = 0;
  count: number = 0;
  perPage: number = 10;
  submitted: boolean = false;
  searchResult: any[] = [];
  public userModel: UserModel;
  orderTotal: number = 0;
  epocketCount: number = 0;
  public me: any;

  constructor(
    injector: Injector,
    public pubsubSvc: NgxPubSubService,
    private authSP: AuthServiceProxy
  ) {
    super(injector);
    this.filterParams = { limit: this.perPage, skip: 0 };
  }

  ngOnInit(): void {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("pg-epocket");

    this.getUserInfo().then((res) => {
      if (!res) {
        return;
      }
      this.authSP
        .getMe({
          LoginID: this.userModel.strUserID,
          PanelType: this.userModel.UserType,
        })
        .pipe(
          finalize(() => {
            this.spinner.hide();
          })
        )
        .subscribe(
          (result: any) => {
            if (!result.issuccess) {
              this.notify.warn(result.message);
            } else if (result.statuscode == 200) {
              this.me = result.result[0];
            }
            //this.showResult = true;
            this.spinner.hide();
          },
          (err) => {
            this.me = {};
            this.showResult = false;
            this.spinner.hide();
          }
        );
    });
  }

  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("pg-home");
  }

  doRefresh(event) {}

  goto(url) {
    this.router.navigate([""]).then((e) => {
      this.router.navigate([url], { replaceUrl: true });
    });
  }
}
