import {
  Component,
  OnInit,
  Injector,
  OnDestroy,
  AfterContentInit,
  HostListener,
  ViewChild,
  ElementRef,
  TemplateRef,
} from "@angular/core";
import { finalize } from "rxjs/operators";
import { NgxPubSubService } from "@pscoped/ngx-pub-sub";
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
  FormArray,
  ValidationErrors,
} from "@angular/forms";

import { AppComponentBase } from "@app/shared/app-component-base";
import {
  AuthServiceProxy,
  EpocketServiceProxy,
  OffersServiceProxy,
  UtilsServiceProxy,
} from "@app/shared/service-proxies/service-proxies";
import { environment } from "@environments/environment";
import { ActivationStart } from "@angular/router";
import * as moment from "moment";
import { UserModel } from "@shared/auth/app-auth.service";
import { Subscription } from "rxjs/internal/Subscription";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: "app-addrequestoffline",
  templateUrl: "./addrequestoffline.component.html",
  styleUrls: ["./addrequestoffline.component.scss"],
})
export class AddRequestOflineComponent
  extends AppComponentBase
  implements OnInit, OnDestroy {
  public myOffers: any[] = [];
  showResult: boolean = false;
  saving: boolean = false;
  frmFG: FormGroup;
  public userModel: UserModel;
  public me: any;
  @ViewChild("fileInput") fileInput;
  submitted = false;
  companyAccNos: any[] = [];
  maxDate: Date;
  clientDetails: any = {};
  imageChangedEvent: any = "";
  croppedImage: any = "";
  otpSent: boolean = false;
  //otp: string = "";
  selectedcompanyAccNo: any = null;
  modalRef: BsModalRef;
  subscriptions: Subscription[] = [];
  messages: string[] = [];
  public defaultImgBrand: string = "assets/images/default-category.png";

  validation_messages = {
    currentBalance: [
      { type: "required", message: "Current Balance is required." },
    ],
    amount: [{ type: "required", message: "Amount is required." }],
    paymentMode: [{ type: "required", message: "Payment Mode is required." }],
    chequeNo: [
      { type: "required", message: "Cheque No. is required." },
    ],
    transactionRefNo: [
      { type: "required", message: "Transaction Reference No. is required." },
    ],
    transactionDate: [
      { type: "required", message: "Transaction Date is required." },
    ],
    companyAccNo: [
      { type: "required", message: "Company Account No. is required." },
    ],
    transactionDetails: [
      { type: "required", message: "Transaction Details is required." },
    ],
    ImageName: [{ type: "required", message: "Transaction Slip is required." }],
    oto: [{ type: "required", message: "OTP is required." }],
  };

  constructor(
    injector: Injector,
    private fb: FormBuilder,
    public pubsubSvc: NgxPubSubService,
    private spEpocket: EpocketServiceProxy,
    private modalService: BsModalService,
    private authSP: AuthServiceProxy
  ) {
    super(injector);
    this.maxDate = new Date();
  }

  ngOnInit(): void {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("pg-epocket");

    this.frmFG = this.fb.group({
      currentBalance: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      amount: new FormControl("", Validators.compose([Validators.required])),
      paymentMode: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      chequeNo: new FormControl("", []),
      transactionRefNo: new FormControl(
        "",
        Validators.compose([])
      ),
      transactionDate: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      companyAccNo: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      transactionDetails: new FormControl(
        "",
        Validators.compose([Validators.required, Validators.maxLength(12)])
      ),
      //ImageName: new FormControl("", Validators.compose([Validators.required])),
      ImageName: new FormControl("", []),
      //otp: new FormControl("", Validators.compose([Validators.required])),
    });

    /*this.frmFG.get("otp").valueChanges.subscribe((otp) => {
      if (otp == "") {
        this.frmFG.get("otp").setErrors({ required: true });
        this.frmFG.get("otp").markAsDirty();
      }
    });*/
    this.frmFG.get("companyAccNo").valueChanges.subscribe((companyAccNo) => {
      if (companyAccNo != "") {
        let acd = this.companyAccNos.filter((x) => x.AccountNo == companyAccNo);
        if (acd.length > 0) {
          this.selectedcompanyAccNo = acd[0];
          this.selectedcompanyAccNo.QRCodeImagePath =
            environment.UPLOADS_URL +
            (this.selectedcompanyAccNo.QRCodeImagePath != ""
              ? this.selectedcompanyAccNo.QRCodeImagePath.replace("../../")
              : "");
        }
      }
    });

    this.frmFG.get("paymentMode").valueChanges.subscribe((value) => {
      if (value == "Cash") {
        this.frmFG.get("ImageName").setValidators([]);
        this.frmFG.get("ImageName").clearValidators();
        this.frmFG.get("ImageName").updateValueAndValidity();
      } else {
        this.frmFG.get("ImageName").setValidators(Validators.required);
      }
      if (value == "NEFT/IMPS" || value == "UPI") {
        this.frmFG.get("transactionRefNo").setValidators(Validators.required);
        this.frmFG.get("transactionRefNo").setErrors({ required: true });
        this.frmFG.get("transactionRefNo").markAsDirty();
      } else {
        this.frmFG.get("transactionRefNo").clearValidators();
      }
      if (value == "Cheque") {
        this.frmFG.get("chequeNo").setValidators(Validators.required);
        this.frmFG.get("chequeNo").setErrors({ cheque_required: true });
        this.frmFG.get("chequeNo").markAsDirty();
      } else {
        this.frmFG.get("chequeNo").clearValidators();
      }
    });

    this.route.paramMap.subscribe((params) => {
      let amount = params.get("amount");
      this.frmFG.controls["amount"].setValue(amount);
      //this.getData();
    });

    this.getUserInfo().then((res) => {
      if (!res) {
        return;
      }

      this.spinner.show();
      this.authSP
        .getMe({
          LoginID: this.userModel.strUserID,
          PanelType: this.userModel.UserType,
        })
        .pipe(
          finalize(() => {
            this.spinner.hide();
          })
        )
        .subscribe(
          (result: any) => {
            if (!result.issuccess) {
              this.notify.warn(result.message);
            } else if (result.statuscode == 200) {
              this.me = result.result[0];
              this.frmFG.controls["currentBalance"].setValue(this.me.Balance);

              this.getClientDetails();
            }
            //this.showResult = true;
            this.spinner.hide();
          },
          (err) => {
            this.me = {};
            this.showResult = false;
            this.spinner.hide();
          }
        );
    });

    this.spEpocket
      .getAccountNo({})
      .pipe(
        finalize(() => {
          //this.spinner.hide();
        })
      )
      .subscribe(
        (result: any) => {
          if (!result.issuccess) {
            this.notify.warn(result.message);
          } else if (result.statuscode == 200) {
            this.companyAccNos = result.result;
          }
        },
        (err) => {
          //this.loginError();
        }
      );
  }

  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("pg-home");
  }

  doRefresh(event) {}

  // convenience getter for easy access to form fields
  get f() {
    return this.frmFG.controls;
  }

  chngPaymentMode(event) {}

  chngCompanyAccNo(event) {}

  chngTransactionRefNo(event) {}

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async validateForm1(form: any, isValid: boolean) {
    this.getFormValidationErrors();
    if (!isValid) {
      this.frmFG.markAsDirty();
      this.frmFG.markAllAsTouched();

      /*if (!this.otpSent && this.frmFG.get("otp").hasError) {
        this.notify.info(
          "OTP will be sent to your registered mobile number. Please enter OTP and submit again."
        );
        return true;
      }*/
      this.notify.error(
        "Invalid form inputs. Please enter proper details and try again."
      );
      return false;
    }

    if (this.croppedImage == null || this.croppedImage == "") {
      this.notify.warn("Please choose an image to proceed further.");
      return false;
    }
    return true;
  }

  validateForm = (form: any, isValid: boolean): Promise<boolean> => {
    return new Promise<boolean>((result) => {
      if (form == null) {
        return result(false);
      }

      this.getFormValidationErrors();
      
      // if(this.frmFG.get("paymentMode").value == 'Cash' || this.frmFG.get("paymentMode").value == 'Cash Deposit') {
      //   if(this.frmFG.get("ImageName").valid) {
      //     this.frmFG.get("ImageName").clearValidators();
      //     this.frmFG.get("ImageName").updateValueAndValidity();
      //   }
      // }
      if (!isValid) {
        this.frmFG.markAsDirty();
        this.frmFG.markAllAsTouched();

        /*if (!this.otpSent && this.frmFG.get("otp").hasError) {
          this.notify.info(
            "OTP will be sent to your registered mobile number. Please enter OTP and submit again."
          );
          return true;
        }*/
        this.notify.error(
          "Invalid form inputs. Please enter proper details and try again."
        );
        return result(false);
      }

      if(!(this.frmFG.get("paymentMode").value == 'Cash')) {
        if (this.croppedImage == null || this.croppedImage == "") {
          this.notify.warn("Please choose an image to proceed further.");
          return result(false);
        }
      }
      
      return result(true);
    });
  };

  async sendOTP(form: any, isValid: boolean) {
    if (!this.validateForm(form, isValid)) {
      return;
    }

    let formdataotp = {
      StrUserID: this.userModel.strUserID,
      Name: this.userModel.UserName,
      MobileNo: this.userModel.MobileNumber,
      EmailID: this.userModel.Email,
      LoginID: this.userModel.strUserID,
      Domain: this.clientDetails.DomainName,
    };

    this.saving = true;
    this.spinner.show();
    this.spEpocket
      .sendePocketTransferOTP(formdataotp)
      .pipe(
        finalize(() => {
          this.saving = false;
          this.spinner.hide();
        })
      )
      .subscribe(
        (result: any) => {
          if (!result.issuccess) {
            this.notify.warn(result.message);
          } else if (result.statuscode == 200) {
            this.otpSent = true;
          }
        },
        (err) => {
          this.notify.error(err.message);
          this.saving = false;
        }
      );
  }

  async frmSubmit(form: any, isValid: boolean) {
    this.validateForm(form, isValid).then((res) => {
      if (!res) {
        return;
      }
      let imagedata = this.croppedImage;

      let formdata = {
        UserID: this.userModel.UserID,
        Amount: form.amount,
        paymentMode: form.paymentMode,
        ChequeDDNo: form.chequeNo,
        TranReferenceNo: form.transactionRefNo,
        AccountNo: form.companyAccNo,
        DepositDate: form.transactionDate,
        TransactionDetails: form.transactionDetails,
      };
      this.spinner.show();
      this.spEpocket
        .addRequest(formdata)
        .pipe(
          finalize(() => {
            this.saving = false;
            //this.spinner.hide();
          })
        )
        .subscribe(
          (result: any) => {
            if (!result.issuccess) {
              this.notify.warn(result.message);
              this.spinner.hide();
              this.saving = false;
            } else if (result.statuscode == 200) {
              //this.notify.success("Request added successfully.");
              //this.router.navigate(["/epocket/requests"]);
              let formdata3 = {
                Domain: this.clientDetails.DomainName,
                strUserID: this.userModel.strUserID,
                RequestNo: result.result[0].RequestNo,
                ImageName: result.result[0].BankSlipImageName,
                ImagedecodeUrl: imagedata,
              };
              //this.spinner.show();
              this.spEpocket
                .addRequestSlipImage(formdata3)
                .pipe(
                  finalize(() => {
                    this.saving = false;
                    //this.spinner.hide();
                  })
                )
                .subscribe(
                  (result: any) => {
                    if (!result.issuccess) {
                      this.notify.warn(result.message);
                    } else if (result.statuscode == 200) {
                      this.notify.success("Request added successfully.");
                      this.router.navigate(
                        [
                          "/epocket/requests",
                          { loaddata: true, status: "Pending" },
                        ],
                        { replaceUrl: true }
                      );

                      //this.router.navigateByUrl('/epocket/requests;loaddata=true;status=Pending', { replaceUrl: false })
                    }
                    this.saving = false;
                    this.spinner.hide();
                  },
                  (err) => {
                    this.notify.error(err.message);
                    this.saving = false;
                    this.spinner.hide();
                  }
                );
            }
            this.saving = false;
            //this.spinner.hide();
          },
          (err) => {
            this.notify.error(err.message);
            this.saving = false;
            this.spinner.hide();
          }
        );
    });
  }

  frmReset() {
    this.submitted = false;
    this.frmFG.reset();
  }

  getFormValidationErrors() {
    Object.keys(this.frmFG.controls).forEach((key) => {
      const controlErrors: ValidationErrors = this.frmFG.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          console.log(
            "Key control: " + key + ", keyError: " + keyError + ", err value: ",
            controlErrors[keyError]
          );
        });
      }
    });
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.croppedImage = event;
    //console.log('fileChangeEvent', event, this.croppedImage)
    //this.croppedImage = '';
    /*if(this.croppedImage != '') {
      this.frmFG.controls['ImageName'].setErrors({required: null})
    }*/
  }
  imageCropped(event: ImageCroppedEvent) {
    //console.log('imageCropped', event.base64)
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
    //console.log('imageLoaded')
  }
  cropperReady() {
    // cropper ready
    //console.log('cropperReady')
  }
  loadImageFailed() {
    // show message
    //console.log('loadImageFailed')
  }

  openBankDetails(template: TemplateRef<any>) {
    this.messages = [];

    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "modal-fullwidth" })
    );
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }
}
