import {
  Component,
  OnInit,
  Injector,
  OnDestroy,
  AfterContentInit,
  HostListener,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { finalize } from "rxjs/operators";
import { NgxPubSubService } from "@pscoped/ngx-pub-sub";
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
  FormArray,
  ValidationErrors,
} from "@angular/forms";

import { AppComponentBase } from "@app/shared/app-component-base";
import {
  AuthServiceProxy,
  EpocketServiceProxy,
} from "@app/shared/service-proxies/service-proxies";
import { UserModel } from "@shared/auth/app-auth.service";
import { ImageCroppedEvent } from "ngx-image-cropper";
import * as moment from "moment";

@Component({
  selector: "app-editrequestoffline",
  templateUrl: "./editrequestoffline.component.html",
  styleUrls: ["./editrequestoffline.component.scss"],
})
export class EditRequestOflineComponent
  extends AppComponentBase
  implements OnInit, OnDestroy {
  public myOffers: any[] = [];
  showResult: boolean = false;
  saving: boolean = false;
  frmFG: FormGroup;
  public userModel: UserModel;
  public me: any;
  @ViewChild("fileInput") fileInput;
  submitted = false;
  companyAccNos: any[] = [];
  maxDate: Date;
  requestNo: any = null;
  imageName: any = null;
  imageChangedEvent: any = "";
  croppedImage: any = "";
  requestDetails: any = {};
  ddpaymentmodes: any[] = [];

  validation_messages = {
    currentBalance: [
      { type: "required", message: "Current Balance is required." },
    ],
    amount: [{ type: "required", message: "Amount is required." }],
    paymentMode: [{ type: "required", message: "Payment Mode is required." }],
    chequeNo: [
      { type: "required", message: "Cheque No. is required." },
    ],
    transactionRefNo: [
      { type: "required", message: "Transaction Reference No. is required." },
    ],
    transactionDate: [
      { type: "required", message: "Transaction Date is required." },
    ],
    companyAccNo: [
      { type: "required", message: "Company Account No. is required." },
    ],
    transactionDetails: [
      { type: "required", message: "Transaction Details is required." },
    ],
    ImageName: [{ type: "required", message: "Transaction Slip is required." }],
  };

  constructor(
    injector: Injector,
    private fb: FormBuilder,
    public pubsubSvc: NgxPubSubService,
    private spEpocket: EpocketServiceProxy,
    private authSP: AuthServiceProxy
  ) {
    super(injector);
    this.maxDate = new Date();
  }

  ngOnInit(): void {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("pg-epocket");

    this.ddpaymentmodes = [...this.genums.paymentmodes, ...[{"id": "Cash", "name": "Cash", "vfields": ""}]];

    this.frmFG = this.fb.group({
      amount: new FormControl(
        { disabled: true },
        Validators.compose([Validators.required])
      ),
      paymentMode: new FormControl(
        { disabled: true },
        Validators.compose([Validators.required])
      ),
      chequeNo: new FormControl(
        "",
        Validators.compose([])
      ),
      transactionRefNo: new FormControl(
        { disabled: true },
        Validators.compose([Validators.required])
      ),
      transactionDate: new FormControl(
        { disabled: true },
        Validators.compose([Validators.required])
      ),
      companyAccNo: new FormControl(
        { disabled: true },
        Validators.compose([Validators.required])
      ),
      transactionDetails: new FormControl(
        { disabled: true },
        Validators.compose([Validators.required, Validators.maxLength(12)])
      ),
      ImageName: new FormControl("", Validators.compose([Validators.required])),
    });

    this.route.queryParams.subscribe((params) => {
      this.requestNo = params["requestNo"];
    });

    this.frmFG.get("paymentMode").valueChanges.subscribe((value) => {
      if (value == "Cash") {
        this.frmFG.get("ImageName").setValidators([]);
        this.frmFG.get("ImageName").clearValidators();
        this.frmFG.get("ImageName").updateValueAndValidity();
      } else {
        this.frmFG.get("ImageName").setValidators(Validators.required);
      }
    });

    this.getUserInfo().then((res) => {
      if (!res) {
        return;
      }

      this.spinner.show();
      this.authSP
        .getMe({
          LoginID: this.userModel.strUserID,
          PanelType: this.userModel.UserType,
        })
        .pipe(
          finalize(() => {
            this.spinner.hide();
          })
        )
        .subscribe(
          (result: any) => {
            if (!result.issuccess) {
              this.notify.warn(result.message);
            } else if (result.statuscode == 200) {
              this.me = result.result[0];

              //this.getClientDetails();

              this.spinner.show();
              this.spEpocket
                .getRequestDetails({ RequestNo: this.requestNo })
                .pipe(
                  finalize(() => {
                    //this.spinner.hide();
                  })
                )
                .subscribe(
                  (result: any) => {
                    if (!result.issuccess) {
                      this.notify.warn(result.message);
                    } else if (result.statuscode == 200) {
                      this.spinner.show();
                      this.requestDetails = result.result[0];
                      //this.frmFG.controls["currentBalance"].setValue(this.me.Balance);
                      this.frmFG.controls["amount"].setValue(
                        this.requestDetails.Amount
                      );
                      this.frmFG.controls["paymentMode"].setValue(
                        this.requestDetails.PaymentMode
                      );
                      this.frmFG.controls["chequeNo"].setValue(
                        this.requestDetails.ChequeDDNo
                      );
                      this.frmFG.controls["transactionRefNo"].setValue(
                        this.requestDetails.TranReferenceNo
                      );
                      if (this.requestDetails.DepositeDate != null) {
                        let dob = moment(
                          this.requestDetails.DepositeDate
                        ).format(this.gconsts.displayshortdateformat);
                        this.frmFG.controls["transactionDate"].setValue(dob);
                      }
                      this.frmFG.controls["transactionDetails"].setValue(
                        this.requestDetails.TransactionDetails
                      );
                      this.frmFG.controls["companyAccNo"].setValue(
                        this.requestDetails.AcountNo
                      );
                      //this.imageName = this.requestDetails.BankSlipImageName;
                      this.croppedImage = this.getURL(
                        this.requestDetails.BankSlipImageName
                      );
                      // this.frmFG.controls["ImageName"].setValue(
                      //   {ImageName: this.croppedImage, rotation: 0}
                      // );
                    }
                    this.spinner.hide();
                  },
                  (err) => {
                    //this.loginError();
                    this.spinner.hide();
                  }
                );
            }
            //this.showResult = true;
            this.spinner.hide();
          },
          (err) => {
            this.me = {};
            this.showResult = false;
            this.spinner.hide();
          }
        );
    });

    this.spEpocket
      .getAccountNo({})
      .pipe(
        finalize(() => {
          //this.spinner.hide();
        })
      )
      .subscribe(
        (result: any) => {
          if (!result.issuccess) {
            this.notify.warn(result.message);
          } else if (result.statuscode == 200) {
            this.companyAccNos = result.result;
          }
        },
        (err) => {
          //this.loginError();
        }
      );
  }

  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("pg-home");
  }

  doRefresh(event) {}

  // convenience getter for easy access to form fields
  get f() {
    return this.frmFG.controls;
  }

  chngPaymentMode(event) {}

  chngCompanyAccNo(event) {}

  chngTransactionRefNo(event) {}

  toPng = (img) =>
    new Promise((resolve, _) => {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      //canvas.toBlob(resolve, 'image/png', 0.15); // 15% quality
      canvas.toBlob(resolve, "image/png", 1); // 100% quality
    });

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      //const fakePng = new Blob(file, {type:'image/png'});
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async frmSubmit(form: any, isValid: boolean) {
    //form.username = form.email;
    /*if (!this.checkNetwork) {
      this.notify.error("No internet connection!");
    }*/
    
    if(!(this.frmFG.get("paymentMode").value == 'Cash')) {
      if (this.croppedImage == null || this.croppedImage == "") {
        this.notify.warn("Please choose an image to proceed further.");
        return;
      }
    }

    this.getFormValidationErrors();
    if (!isValid) {
      this.frmFG.markAsDirty();
      this.frmFG.markAllAsTouched();
      this.notify.error(
        "Invalid form inputs. Please enter proper details and try again."
      );
      return;
    }

    this.getUserInfo().then((res) => {
      if (!res) {
        return;
      }
      this.getClientDetails().then((res2) => {
        let imagedata = this.croppedImage;
        let formdata = {
          Domain: this.clientDetails.DomainName,
          strUserID: this.userModel.strUserID,
          RequestNo: this.requestNo,
          ImageName: this.userModel.strUserID, //this.imageName,
          ImagedecodeUrl: imagedata,
        };

        this.saving = true;
        this.spinner.show();
        this.spEpocket
          .addRequestSlipImage(formdata)
          .pipe(
            finalize(() => {
              this.saving = false;
              this.spinner.hide();
            })
          )
          .subscribe(
            (result: any) => {
              if (!result.issuccess) {
                this.notify.warn(result.message);
              } else if (result.statuscode == 200) {
                this.notify.success("Request added successfully.");
                this.router.navigate(["/epocket/requests"]);
              }
              this.saving = false;
            },
            (err) => {
              this.notify.error(err.message);
              this.saving = false;
            }
          );
      });
    });
  }

  frmReset() {
    this.submitted = false;
    this.frmFG.reset();
  }

  getFormValidationErrors() {
    Object.keys(this.frmFG.controls).forEach((key) => {
      const controlErrors: ValidationErrors = this.frmFG.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          console.log(
            "Key control: " + key + ", keyError: " + keyError + ", err value: ",
            controlErrors[keyError]
          );
        });
      }
    });
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    //console.log("this.croppedImage", this.croppedImage);
    this.croppedImage = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    //console.log("imageCropped", event.base64);
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
    //console.log("imageLoaded");
  }
  cropperReady() {
    // cropper ready
    //console.log("cropperReady");
  }
  loadImageFailed() {
    // show message
    //console.log("loadImageFailed");
  }
}
