import {
  Component,
  OnInit,
  Injector,
  OnDestroy,
  AfterContentInit,
  HostListener,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { finalize } from "rxjs/operators";
import { NgxPubSubService } from "@pscoped/ngx-pub-sub";
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
  FormArray,
  ValidationErrors,
} from "@angular/forms";

import { AppComponentBase } from "@app/shared/app-component-base";
import {
  AuthServiceProxy,
  EpocketServiceProxy,
  OffersServiceProxy,
  UtilsServiceProxy,
} from "@app/shared/service-proxies/service-proxies";
import { environment } from "@environments/environment";
import { ActivationStart } from "@angular/router";
import * as moment from "moment";
import { UserModel } from "@shared/auth/app-auth.service";
import { Subscription } from "rxjs/internal/Subscription";

@Component({
  selector: "app-addrequestonline",
  templateUrl: "./addrequestonline.component.html",
  styleUrls: ["./addrequestonline.component.scss"],
})
export class AddRequestOnlineComponent
  extends AppComponentBase
  implements OnInit, OnDestroy {
  public myOffers: any[] = [];
  showResult: boolean = false;
  saving: boolean = false;
  frmFG: FormGroup;
  public userModel: UserModel;
  public me: any;
  @ViewChild("fileInput") fileInput;
  submitted = false;

  validation_messages = {
    currentBalance: [
      { type: "required", message: "Current Balance is required." },
    ],
    amount: [{ type: "required", message: "Amount is required." }],
  };

  constructor(
    injector: Injector,
    private fb: FormBuilder,
    public pubsubSvc: NgxPubSubService,
    private spEpocket: EpocketServiceProxy,
    private authSP: AuthServiceProxy
  ) {
    super(injector);
  }

  ngOnInit(): void {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("pg-epocket");

    this.frmFG = this.fb.group({
      currentBalance: new FormControl(
        "",
        Validators.compose([Validators.required])
      ),
      amount: new FormControl("", Validators.compose([Validators.required])),
    });

    this.appAuthService.isLoggedIn().then((res) => {
      this.isLoggedIn = res;
      if (!this.isLoggedIn) {
        this.notify.warn(this.l("Please login and try again!"));
        this.router.navigate([""]).then((e) => {
          this.router.navigate(["/account/login"]);
        });
        return;
      } else {
        this.spinner.show();
        this.appAuthService.getUserModel().then(
          (res) => {
            this.userModel = res;
            this.authSP
              .getMe({
                LoginID: this.userModel.strUserID,
                PanelType: this.userModel.UserType,
              })
              .pipe(
                finalize(() => {
                  this.spinner.hide();
                })
              )
              .subscribe(
                (result: any) => {
                  if (!result.issuccess) {
                    this.notify.warn(result.message);
                  } else if (result.statuscode == 200) {
                    this.me = result.result[0];
                    this.frmFG.controls["currentBalance"].setValue(
                      this.me.Balance
                    );
                  }
                  //this.showResult = true;
                  this.spinner.hide();
                },
                (err) => {
                  this.me = {};
                  this.showResult = false;
                  this.spinner.hide();
                }
              );
          },
          (err) => {}
        );
      }
    });
  }

  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("pg-home");
  }

  doRefresh(event) {}

  // convenience getter for easy access to form fields
  get f() {
    return this.frmFG.controls;
  }

  chngPaymentMode(event) {}

  chngCompanyAccNo(event) {}

  chngTransactionRefNo(event) {}

  async frmSubmit(form: any, isValid: boolean) {
    form.username = form.email;
    /*if (!this.checkNetwork) {
      this.notify.error("No internet connection!");
    }*/

    this.getFormValidationErrors();
    if (!isValid) {
      this.frmFG.markAsDirty();
      this.frmFG.markAllAsTouched();
      this.notify.error(
        "Invalid form inputs. Please enter proper details and try again."
      );
      //return;
    }

    let fi = this.fileInput.nativeElement;
    let formdata: FormData = new FormData();
    formdata.append("UserID", this.userModel.strUserID);
    formdata.append("Status", null);
    formdata.append(
      "DepositDate",
      moment(form.transactionDate).format(this.gconsts.shortdateformat)
    );
    formdata.append("Amount", form.amount);
    formdata.append("CompanyId", "" + environment.CompanyID);
    formdata.append("RequestNo", null);

    this.saving = true;
    this.spinner.show();
    this.spEpocket
      .addRequest(formdata)
      .pipe(
        finalize(() => {
          this.saving = false;
          this.spinner.hide();
        })
      )
      .subscribe(
        (result: any) => {
          if (result.status == 400) {
            this.notify.warn(result.message);
          } else if (result.status == 200) {
            this.notify.success("Request added successfully.");
            this.router.navigate(["/epocket/requests"]);
          }
          this.saving = false;
        },
        (err) => {
          this.notify.error(err.message);
          this.saving = false;
        }
      );
  }

  frmReset() {
    this.submitted = false;
    this.frmFG.reset();
  }

  getFormValidationErrors() {
    Object.keys(this.frmFG.controls).forEach((key) => {
      const controlErrors: ValidationErrors = this.frmFG.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          console.log(
            "Key control: " + key + ", keyError: " + keyError + ", err value: ",
            controlErrors[keyError]
          );
        });
      }
    });
  }
}
