import {
  Component,
  Injector,
  OnInit,
  EventEmitter,
  ElementRef,
  Input,
  Output,
} from "@angular/core";
import {
  finalize,
  map,
  mergeMap as _observableMergeMap,
  catchError as _observableCatch,
} from "rxjs/operators";
//import { NgxPubSubService } from "@pscoped/ngx-pub-sub";
//import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { AppComponentBase } from "@app/shared/app-component-base";
//import { accountModuleAnimation } from "@app/shared/animations/routerTransition";
import { LoginService } from "@app/shared/auth/login.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  ValidationErrors,
  FormControl,
} from "@angular/forms";
import { PasswordValidator } from "@app/shared/validators/password.validator";
import {
  AuthServiceProxy,
  SocialServiceProxy,
} from "@app/shared/service-proxies/service-proxies";
import { AppAuthService } from "@app/shared/auth/app-auth.service";

@Component({
  templateUrl: "./resetpass.component.html",
  styleUrls: ["./resetpass.component.less"],
  //animations: [accountModuleAnimation()],
})
export class ResetPassComponent extends AppComponentBase implements OnInit {
  //model: RegisterInput = new RegisterInput();
  //model: ShardConfig = new ShardConfig();
  saving = false;
  subscriptionid: number = null;
  frmFG: FormGroup;
  submitted = false;
  passwordType: string = "password";
  passwordIcon: string = "eye-off";
  public event: EventEmitter<any> = new EventEmitter();
  enableOTP: boolean = false;
  loggedIn: boolean;
  //@Input() data: any = null;
  data: any = null;

  matching_passwords_group: FormGroup;

  validation_messages = {
    email: [
      { type: "required", message: "Email is required." },
      { type: "pattern", message: "Please wnter a valid email." },
    ],
    password: [
      { type: "required", message: "Password is required." },
      {
        type: "minlength",
        message: "Password must be at least 5 characters long.",
      },
      {
        type: "pattern",
        message:
          "Your password must contain at least one uppercase, one lowercase, one number and at least one special character.",
      },
    ],
    confirm_password: [
      { type: "required", message: "Confirm password is required." },
    ],
    matching_passwords: [{ type: "areEqual", message: "Password mismatch." }],
    token: [{ type: "required", message: "Token is required." }],
  };

  constructor(
    injector: Injector,
    private el: ElementRef,
    private _loginService: LoginService,
    private fb: FormBuilder,
    private authSP: AuthServiceProxy,
    public appAuthService: AppAuthService
  ) //public pubsubSvc: NgxPubSubService
  {
    super(injector);
  }

  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("login-page");

    this.matching_passwords_group = new FormGroup(
      {
        password: new FormControl(
          "",
          Validators.compose([
            Validators.minLength(5),
            Validators.required,
            Validators.pattern(
              //"^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+$"
              "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{5,}$"
            ),
          ])
        ),
        confirm_password: new FormControl("", Validators.required),
      },
      (formGroup: FormGroup) => {
        return PasswordValidator.areEqual(formGroup);
      }
    );

    this.frmFG = this.fb.group(
      {
        email: [
          "",
          [
            Validators.required,
            Validators.email,
            Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
          ],
        ],
        matching_passwords: this.matching_passwords_group,
        token: ["", [Validators.required]],
      },
      {}
    );

    this.route.params.subscribe((params) => {
      let token = params["token"] ?? [];
      this.frmFG.get("token").setValue(token);
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.frmFG.controls;
  }

  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("login-page");
  }

  async frmSubmit(form: any, isValid: boolean) {
    /*if (!this.checkNetwork) {
      this.notify.error("No internet connection!");
    }*/
    this.getFormValidationErrors();
    if (!isValid) {
      this.frmFG.markAsDirty();
      this.frmFG.markAllAsTouched();
      this.notify.error(
        "Invalid form inputs. Please enter proper details and try again."
      );
      /*for (const key of Object.keys(this.frmFG.controls)) {
        if (this.frmFG.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          invalidControl.focus();
          break;
       }
      }*/
      return;
    }

    this.saving = true;
    this.spinner.show();
    this.authSP
      .resetPass({
        email: form.email,
        password: form.matching_passwords.password,
        token: form.token,
        password_confirmation: form.matching_passwords.confirm_password,
      })
      .pipe(
        finalize(() => {
          this.saving = false;
          this.spinner.hide();
        })
      )
      .subscribe(
        (result: any) => {
          if (!result.success) {
            this.saving = false;
            this.notify.info(
              this.l("Reset password failed. Please try again.")
            );
          } else {
            this.notify.success(this.l("Password changed successfully."));
            
            this.router.navigate([""]).then((e) => {
              this.router.navigate(["/account/login"], { replaceUrl: true });
            });
          }
        },
        (err) => {
          this.notify.error(err.message);
          this.saving = false;
        }
      );
  }

  onReset() {
    this.submitted = false;
    this.frmFG.reset();
  }

  resendOTP() {
    if (this.frmFG.get("email").value == "") {
      this.notify.warn(this.l("Please enter email address and try again."));
      return;
    }
    this.spinner.show();
    this.authSP
      .resendOTP({ email: this.frmFG.get("email").value })
      .pipe(
        finalize(() => {
          this.saving = false;
          this.spinner.hide();
        })
      )
      .subscribe(
        (result: any) => {
          if (!result.success) {
            this.saving = false;
            this.notify.info(result.message);
          } else {
            this.notify.success(this.l("OTP sent. Please try again."));
          }
        },
        (err) => {
          this.notify.error(err.message);
          this.saving = false;
        }
      );
  }

  getFormValidationErrors() {
    Object.keys(this.frmFG.controls).forEach((key) => {
      const controlErrors: ValidationErrors = this.frmFG.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          console.log(
            "Key control: " + key + ", keyError: " + keyError + ", err value: ",
            controlErrors[keyError]
          );
        });
      }
    });
  }
}
