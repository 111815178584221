import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AddRequestComponent } from "./addrequest/addrequest.component";
import { AddRequestOflineComponent } from "./addrequest/addrequestoffline.component";
import { EditRequestOflineComponent } from "./addrequest/editrequestoffline.component";
import { AddRequestOnlineComponent } from "./addrequestonline/addrequestonline.component";
import { AddTransferComponent } from "./addtransfer/addtransfer.component";
import { EpocketComponent } from "./epocket.component";
import { EpocketLayoutComponent } from "./layout/epocket-layout.component";
import { RequestListComponent } from "./requestlist/requestlist.component";
import { TransferListComponent } from "./transferlist/transferlist.component";

const routes: Routes = [
  {
    path: "",
    component: EpocketLayoutComponent,
    children: [
      { path: "", component: EpocketComponent },
      { path: "epocket", component: EpocketComponent },
      // { path: ":slug", component: EpocketComponent },
      { path: "epocket/requests", component: RequestListComponent },
      { path: "epocket/addrequest", component: AddRequestComponent },
      { path: "epocket/addrequestoffline", component: AddRequestOflineComponent },
      { path: "epocket/editrequestoffline", component: EditRequestOflineComponent },
      { path: "epocket/addrequestonline", component: AddRequestOnlineComponent },
      { path: "epocket/addtransfer", component: AddTransferComponent },
      { path: "epocket/transactions", component: TransferListComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EpocketRoutingModule {}
