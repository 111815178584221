import {
  Component,
  Injector,
  ViewEncapsulation,
  Input,
  OnInit,
} from "@angular/core";
import { AppComponentBase } from "@shared/app-component-base";

@Component({
  templateUrl: "./footerbar.component.html",
  selector: "footer-bar",
  //encapsulation: ViewEncapsulation.None
})
export class FooterBarComponent extends AppComponentBase implements OnInit {
  @Input() title: string;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    //this.selectedThemeCssClass = this.setting.get('App.UiTheme');
    //$('body').addClass('theme-' + this.selectedThemeCssClass);
  }
}
