
export class AppConsts {

  static remoteServiceBaseUrl: string;
  static appBaseUrl: string;
  static appBaseHref: string; // returns angular's base-href parameter value if used during the publish

  static readonly userManagement = {
    defaultAdminUserName: 'admin'
  };

  static readonly localization = {
    defaultLocalizationSourceName: 'sispay'
  };

  static readonly authorization = {
    encrptedAuthTokenName: 'sp_auth_token'
  };

  static readonly shortdateformat: string = 'YYYY/MM/DD';
  static readonly displayshortdateformat: string = 'DD/MM/YYYY';
}