import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { CommonModule } from "@angular/common";

import { FooterBarComponent } from "./footerbar.component";

@NgModule({
  imports: [CommonModule],
  declarations: [FooterBarComponent],
  providers: [],
  exports: [FooterBarComponent],
  //schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class FooterBarModule {}
