import {
  Component,
  OnInit,
  Injector,
  OnDestroy,
  ChangeDetectorRef,
  TemplateRef,
} from "@angular/core";
import { finalize } from "rxjs/operators";
import { NgxPubSubService } from "@pscoped/ngx-pub-sub";
import {
  Validators,
  FormBuilder,
  FormGroup,
  FormControl,
  FormArray,
  ValidationErrors,
} from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { combineLatest, Subscription } from "rxjs";
import { Html2canvasService } from "@app/shared/helpers/Html2canvasService.service";

import { AppComponentBase } from "@app/shared/app-component-base";
import {
  Category,
  EpocketServiceProxy,
} from "@app/shared/service-proxies/service-proxies";
import * as moment from "moment";

@Component({
  selector: "app-requestlist",
  templateUrl: "./requestlist.component.html",
  styleUrls: ["./requestlist.component.scss"],
})
export class RequestListComponent
  extends AppComponentBase
  implements OnInit, OnDestroy {
  public myOffers: any[] = [];
  showResult: boolean = false;
  saving: boolean = false;
  filterParams: any; // = {limit: 10, skip: 0};
  //loading: any;
  index: number = 0;
  count: number = 0;
  perPage: number = 10;
  submitted: boolean = false;
  searchResult: any[] = [];
  searchCapital: any[] = [];
  searchRunning: any[] = [];
  public defaultCatImg: string = "assets/images/default-category.png";
  public defaultImgBrand: string = "assets/images/default-brand.png";
  frmFG: FormGroup;
  public categories: Array<Category>;
  public enumsSelected: any[] = [];
  public allSelected: boolean = false;
  public usedStatus: string = null;
  minDate: Date;
  maxDate: Date;
  panelid?: number = null;
  modalRef: BsModalRef;
  subscriptions: Subscription[] = [];
  messages: string[] = [];
  receiptItm: any = {};

  constructor(
    injector: Injector,
    private fb: FormBuilder,
    public pubsubSvc: NgxPubSubService,
    private spEpocket: EpocketServiceProxy,
    private modalService: BsModalService,
    private changeDetection: ChangeDetectorRef,
    private html2canvasService: Html2canvasService
  ) {
    super(injector);
    this.minDate = new Date();
    this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate() - 1);
    this.maxDate.setDate(this.maxDate.getDate());
    this.filterParams = { limit: this.perPage, skip: 0 };
  }

  ngOnInit(): void {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("pg-home");

    this.frmFG = this.fb.group({
      date_range: new FormControl("", []),
      categories: new FormControl(),
    });

    this.searchResult = [];
    this.filterParams = { limit: this.perPage, skip: 0 };
    //this.getData();
    //this.frmFG.controls["date_range"].setValue([new Date(), new Date()]);
    //this.categories = this.genums.paymentmodes;
    this.categories = [
      ...[{ id: "all", name: "All" }],
      ...this.genums.paymentmodes,
    ];
    this.enumsSelected["categories"] = [this.categories[0].id];
    this.enumsSelected["dateftype"] = ["request"];
    this.usedStatus = this.genums.epocketrequeststatus[0].id;

    this.getUserInfo().then((res) => {
      this.route.paramMap.subscribe((params) => {
        if (params.get("categories") != null) {
          this.enumsSelected["categories"].push(params.get("categories"));
          if (params.get("categories") == "all") {
            this.allSelected = true;
          }
        }
        //console.log("loaddata", params);
        if (params.get("status") != null) {
          this.usedStatus = params.get("status");
        }
        if (params.get("loaddata") != null) {
          this.frmSubmit(this.frmFG, this.frmFG.valid);
        }
      });
    });
  }

  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("pg-home");
  }

  getData() {
    this.getUserInfo().then((res) => {
      if (!res) {
        return;
      }
      this.filterParams.LastIndex = this.filterParams.skip;
      this.spinner.show();
      this.spEpocket
        .getRequests(this.filterParams)
        .pipe(
          finalize(() => {
            this.saving = false;
            this.spinner.hide();
          })
        )
        .subscribe(
          (result: any) => {
            if (!result.issuccess) {
              this.notify.warn(result.message);
            } else if (result.statuscode == 200) {
              if (result.result.length > 0) {
                result.result.forEach((element, index) => {
                  if (index < this.perPage) {
                    this.searchResult.push(element);
                  }
                });
                if(result.result.length > this.perPage - 1) {
                  this.filterParams.skip += (this.filterParams.limit);
                } else {
                  this.filterParams.skip = -1;
                }
              }
            }
          },
          (err) => {
            this.searchResult = [];
            this.notify.error(err.message);
          }
        );
    });
  }

  doInfinite(event) {
    if (!this.saving && this.filterParams.skip != -1) {
      this.getData();
      event.target.complete();
    }
  }

  doRefresh(event) {
    this.searchResult = [];
    this.resetFrm();

    setTimeout(() => {
      event.target.complete();
    }, 1000);
  }

  clearDateRange(event) {
    //this.frmFG.controls['date_range'].setValue([null, null]);
    this.frmFG.controls["date_range"].setValue(null);
  }

  selectAll(action) {
    this.enumsSelected["categories"] = [];
    this.allSelected = false;
    if (action) {
      this.categories.forEach((element) => {
        this.enumsSelected["categories"].push(element.slug);
      });
      this.allSelected = true;
    }
  }

  async frmSubmit(form: any, isValid: boolean) {
    this.submitted = true;
    //console.log('this.frmFG.value.date_range', this.frmFG.value.date_range, this.frmFG.value.date_range != null)
    this.searchResult = [];
    this.filterParams = { limit: this.perPage, skip: 0 };
    this.filterParams.FromDate =
      this.frmFG.value.date_range != null && this.frmFG.value.date_range != ''
        ? moment(this.frmFG.value.date_range[0]).format(
            this.gconsts.shortdateformat
          )
        : null;
    this.filterParams.ToDate =
      this.frmFG.value.date_range != null && this.frmFG.value.date_range != ''
        ? moment(this.frmFG.value.date_range[1]).format(
            this.gconsts.shortdateformat
          )
        : null;
    this.filterParams.TypeOfDate =
      this.enumsSelected["dateftype"][0] == "all"
        ? null
        : this.enumsSelected["dateftype"][0] == "request"
        ? "RequestDate"
        : "VerifiedDate";
    this.filterParams.PaymentMode =
      this.enumsSelected["categories"].length > 0
        ? this.enumsSelected["categories"][0] == "all"
          ? null
          : this.enumsSelected["categories"][0]
        : null;
    this.filterParams.Status = this.usedStatus == 'all' ? null : this.usedStatus;
    this.filterParams.UserID = this.userModel.UserID;
    this.filterParams.LastIndex = 0;

    if (!isValid) {
      /*this.notify.error(
        "Invalid form inputs. Please enter proper details and try again."
      );*/
    }

    this.searchResult = [];
    this.getData();
  }

  resetFrm() {
    this.frmFG.reset();
    //this.enumsSelected["categories"] = ['all'];
    this.selectAll(true);
    
    this.enumsSelected["dateftype"] = ["request"];
    this.enumsSelected["used_status"] = ["all"];
    this.enumsSelected["categories"] = [this.categories[0].id];
    this.usedStatus = this.genums.epocketrequeststatus[0].id;

    this.frmSubmit(this.frmFG.value, this.frmFG.valid);
  }

  onCheckChange2(event, name) {
    this.enumsSelected["categories"] = [];
    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      this.enumsSelected["categories"].push(event.target.value);
    } else {
      let existing = this.enumsSelected["categories"].findIndex(
        (x) => x == event.target.value
      );
      if (existing > -1) {
        this.enumsSelected["categories"].splice(existing, 1);
      }
    }
  }

  onCheckdateftype(event, name) {
    this.enumsSelected["dateftype"] = [];
    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      this.enumsSelected["dateftype"].push(event.target.value);
    } else {
      let existing = this.enumsSelected["dateftype"].findIndex(
        (x) => x == event.target.value
      );
      if (existing > -1) {
        this.enumsSelected["dateftype"].splice(existing, 1);
      }
    }
  }

  changeUsedStatus(value) {
    this.usedStatus = value;
  }

  openReceipt(template: TemplateRef<any>, itm: any) {
    this.messages = [];
    this.receiptItm = itm;
    /*const _combine = combineLatest(
      this.modalService.onShow,
      this.modalService.onShown,
      this.modalService.onHide,
      this.modalService.onHidden
    ).subscribe(() => this.changeDetection.markForCheck());

    this.subscriptions.push(
      this.modalService.onShow.subscribe(() => {
        this.messages.push(`onShow event has been fired`);
      })
    );
    this.subscriptions.push(
      this.modalService.onShown.subscribe(() => {
        this.messages.push(`onShown event has been fired`);
      })
    );
    this.subscriptions.push(
      this.modalService.onHide.subscribe((reason: string | any) => {
        if (typeof reason !== "string") {
          reason = `onHide(), modalId is : ${reason.id}`;
        }
        const _reason = reason ? `, dismissed by ${reason}` : "";
        this.messages.push(`onHide event has been fired${_reason}`);
      })
    );
    this.subscriptions.push(
      this.modalService.onHidden.subscribe((reason: string | any) => {
        if (typeof reason !== "string") {
          reason = `onHide(), modalId is : ${reason.id}`;
        }
        const _reason = reason ? `, dismissed by ${reason}` : "";
        this.messages.push(`onHidden event has been fired${_reason}`);
        this.unsubscribe();
      })
    );

    this.subscriptions.push(_combine);*/

    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "modal-fullwidth" })
    );
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  print() {}

  screenshot(elementid) {
    this.html2canvasService.download(elementid);
  }
}
