import {
  NgModule,
  Injector,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { CommonModule } from "@angular/common";

import { NgxSpinnerModule } from "ngx-spinner";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";

import { OffersRoutingModule } from "./offers-routing.module";
import { AppInjector } from "./app-injector.service";
//import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image'; // <-- include ScrollHooks

import { ServiceProxyModule } from "@app/shared/service-proxies/service-proxy.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { OffersComponent } from "./offers.component";
import { OfferLayoutComponent } from "./layout/offer-layout.component";
import { FooterBarModule } from "@app/layout/footerbar.module";
import { SharedModule } from "@shared/shared.module";
import { TopBarModule } from '@app/layout/topbar.module';
import { IonicModule } from '@ionic/angular';
import { LazyLoadImageModule } from "ng-lazyload-image";
import { OfferpoComponent } from "./component/offerpo.component";
import { OfferComponent } from "./offer.component";

@NgModule({
  declarations: [OfferLayoutComponent, OffersComponent, OfferpoComponent, OfferComponent],
  entryComponents: [],
  exports: [],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    ServiceProxyModule,
    //CarouselModule.forRoot(),
    //ModalModule.forRoot(),
    //PopoverModule.forRoot(),
    NgxSpinnerModule,
    OffersRoutingModule,
    LazyLoadImageModule,
    BsDatepickerModule.forRoot(),
    IonicModule.forRoot(),
    FooterBarModule,
    //OfferModule,
    //OfferpoModule,
    TopBarModule
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [OfferLayoutComponent],
})
export class OffersModule {
  constructor(injector: Injector) {
    AppInjector.injector = injector;
  }
}
