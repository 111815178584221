import {
  Component,
  Injector,
  OnInit,
  EventEmitter,
  ElementRef,
  Input,
  Output,
  ViewChild 
} from "@angular/core";
import {
  finalize,
  map,
  mergeMap as _observableMergeMap,
  catchError as _observableCatch,
} from "rxjs/operators";
import { NgxPubSubService } from "@pscoped/ngx-pub-sub";
//import { BsModalService, BsModalRef, ModalDirective } from "ngx-bootstrap/modal";
import { AppComponentBase } from "@app/shared/app-component-base";
//import { accountModuleAnimation } from "@app/shared/animations/routerTransition";
import { LoginService } from "@app/shared/auth/login.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  ValidationErrors,
} from "@angular/forms";
import {
  AuthServiceProxy,
  SocialServiceProxy,
} from "@app/shared/service-proxies/service-proxies";
import { AppAuthService } from "@app/shared/auth/app-auth.service";
/*import { SocialAuthService, SocialUser } from "angularx-social-login";
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
} from "angularx-social-login";*/
import { RegisterComponent } from '../register/register.component';
import { environment } from "@environments/environment";

@Component({
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.less"],
  //animations: [accountModuleAnimation()],
})
export class LoginComponent extends AppComponentBase implements OnInit {
  //model: RegisterInput = new RegisterInput();
  //model: ShardConfig = new ShardConfig();
  saving = false;
  subscriptionid: number = null;
  frmFG: FormGroup;
  submitted = false;
  passwordType: string = "password";
  passwordIcon: string = "eye-off";
  public event: EventEmitter<any> = new EventEmitter();
  enableOTP: boolean = false;
  //user: SocialUser;
  loggedIn: boolean;
  //@Input() data: any = null;
  data: any = null;
  socialProvider: string = null;
  isIncognito: boolean = false;

  validation_messages = {
    username: [
      { type: "required", message: "Login ID / Mobile No. is required" },
      { type: "pattern", message: "Please enter a valid Login ID / Mobile No." },
    ],
    /*phone: [
      { type: "required", message: "Phone is required." },
      //{ type: 'validCountryPhone', message: 'The phone is incorrect for the selected country.' }
    ],*/
    password: [
      { type: "required", message: "Password is required." },
      {
        type: "minlength",
        message: "Password must be at least 4 characters long.",
      },
      {
        type: "pattern",
        message:
          "Your password must contain at least one uppercase, one lowercase, one number and at least one special character (#?!@$%^&*+-).",
      },
    ],
    otp: [
      { type: "required", message: "OTP is required." },
      {
        type: "minlength",
        message: "OTP must be at least 4 characters long.",
      },
      {
        type: "maxlength",
        message: "OTP must be at max 4 characters long.",
      },
    ],
  };

  constructor(
    injector: Injector,
    //private el: ElementRef,
    //private _loginService: LoginService,
    private fb: FormBuilder,
    private authSP: AuthServiceProxy,
    public appAuthService: AppAuthService,
    //public bsModalRef: BsModalRef,
    //private modalService: BsModalService,
    public pubsubSvc: NgxPubSubService,
    //private authService: SocialAuthService,
    //private socialSP: SocialServiceProxy
  ) {
    super(injector);
  }

  ngOnInit() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("login-page");

    this.frmFG = this.fb.group(
      {
        username: ["", [
          Validators.required, //Validators.email,
          //Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
        ]],
        password: ["", [Validators.required, Validators.minLength(4)]],
        //otp: ["", [Validators.minLength(4), Validators.maxLength(4)]],
      },
      {}
    );
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.frmFG.controls;
  }

  ngOnDestroy() {
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("login-page");
  }

  back(): void {
    this.router.navigate(["/home"]);
  }

  async frmSubmit(form: any, isValid: boolean) {
    /*if (!this.checkNetwork) {
      this.notify.error("No internet connection!");
    }*/
    this.getFormValidationErrors();
    if (!isValid) {
      this.frmFG.markAsDirty();
      this.frmFG.markAllAsTouched();
      this.notify.error(
        "Invalid form inputs. Please enter proper details and try again."
      );
      /*for (const key of Object.keys(this.frmFG.controls)) {
        if (this.frmFG.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
          invalidControl.focus();
          break;
       }
      }*/
      return;
    }

    let formdata = {
      "CompanyId": 1,
      "LoginID": form.username,
      "Password": form.password,
      "AppVersionCode": environment.AppVersionCode,
      "FireBaseToken": "sample string 5",
      "IPAddress": "192.168.1.1"
    }
    
    this.saving = true;
    this.spinner.show();
    this.authSP
      .login(formdata)
      .pipe(
        finalize(() => {
          this.saving = false;
          this.spinner.hide();
        })
      )
      .subscribe(
        (result: any) => {
          //console.log('result', result)
          if (!result.issuccess) {
            this.notify.warn(result.message);
          } else if (result.statuscode == 200) {
            //this.notify.success("Login successfull.");
            if (result.result[0].UserID != null) {
              this.appAuthService.login(result.result[0], form.remember_me).then(
                () => {
                  this.saving = false;
                  //this.event.emit({ data: result.result[0], res: 200 });
  
                  this.notify.success(this.l("Logged in successfully"));
                  this.pubsubSvc.publishEvent("user:login", result.result[0]);
                  //this.router.navigate(["/home"], { replaceUrl: true });
                  this.spinner.show();
                  setTimeout(() => {
                    location.href = window.location.origin + '/home?t=' + Math.random();
                  }, 2000);
                },
                (err) => {
                  this.saving = false;
                }
              );
            }
          }
        },
        (err) => {
          /*console.log(
            "Error: " + err.error,
            "Name: " + err.name,
            "Message: " + err.message,
            "Status: " + err.status
          );*/
          this.notify.error(err.message);
          if (err.status == 418) {
            this.enableOTP = true;
            this.frmFG.get("otp").setValidators(Validators.required);
          }
          this.saving = false;
        }
      );
  }

  onReset() {
    this.submitted = false;
    this.frmFG.reset();
  }

  resendOTP() {
    if (this.frmFG.get("email").value == "") {
      this.notify.warn(this.l("Please enter email address and try again."));
      return;
    }
    this.spinner.show();
    this.authSP
      .resendOTP({ email: this.frmFG.get("email").value })
      .pipe(
        finalize(() => {
          this.saving = false;
          this.spinner.hide();
        })
      )
      .subscribe(
        (result: any) => {
          if (!result.success) {
            this.saving = false;
            this.notify.info(result.message);
          } else {
            this.notify.success(this.l("OTP sent. Please try again."));
          }
        },
        (err) => {
          console.log(
            "Error: " + err.error,
            "Name: " + err.name,
            "Message: " + err.message,
            "Status: " + err.status
          );
          this.notify.error(err.message);
          this.saving = false;
        }
      );
  }

  getFormValidationErrors() {
    Object.keys(this.frmFG.controls).forEach((key) => {
      const controlErrors: ValidationErrors = this.frmFG.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          console.log(
            "Key control: " + key + ", keyError: " + keyError + ", err value: ",
            controlErrors[keyError]
          );
        });
      }
    });
  }

  register() {
    this.router.navigate(["/account/register"], { replaceUrl: true });
  }

  forgotPass() {
    //this.router.navigate([""]).then((e) => {
    this.router.navigate(["/account/forgotpass"], { replaceUrl: true });
    //});
  }

  forgotPass2() {
    if (this.frmFG.get("email").value == "") {
      this.notify.warn(this.l("Please enter email address and try again."));
      return;
    }
    this.spinner.show();
    this.authSP
      .forgotPass({ email: this.frmFG.get("email").value })
      .pipe(
        finalize(() => {
          this.saving = false;
          this.spinner.hide();
        })
      )
      .subscribe(
        (result: any) => {
          if (!result.success) {
            this.saving = false;
            this.notify.info(result.message);
          } else {
            this.notify.success(this.l("Reset password link sent to your email."));
          }
        },
        (err) => {
          console.log(
            "Error: " + err.error,
            "Name: " + err.name,
            "Message: " + err.message,
            "Status: " + err.status
          );
          this.notify.error(err.message);
          this.saving = false;
        }
      );
  }
}
