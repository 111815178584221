import { CommonModule } from "@angular/common";
//import { BrowserModule } from '@angular/platform-browser';
//import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NgModule,
  ModuleWithProviders,
  CUSTOM_ELEMENTS_SCHEMA,
} from "@angular/core";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
//import { NgxPaginationModule } from 'ngx-pagination';
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { CustomHttpInterceptorService } from "@app/shared/helpers/http.service";
//import { CommentCardComponent } from "@app/job/comment-job/comment-card.component";

import { ToastrModule } from "ngx-toastr";
import { NotifyService } from "./helpers/notify.service";
import { UtilsHelper } from "./helpers/utils.helper";
import { Html2canvasService } from "./helpers/Html2canvasService.service";
//import { TokenService } from '@app/shared/helpers/token.service';
//import { AppSessionService } from './session/app-session.service';
import { AppUrlService } from "./nav/app-url.service";
import { AppAuthService } from "./auth/app-auth.service";
import { AppRouteGuard } from "./auth/auth-route-guard";
//import { AbpPaginationControlsComponent } from './pagination/abp-pagination-controls.component';
import { LocalizePipe } from "./pipes/localize.pipe";
/*import { BlockDirective } from './directives/block.directive';
import { BusyDirective } from './directives/busy.directive';
import { EqualValidator } from './directives/equal-validator.directive';*/
import { ModalModule, BsModalRef } from "ngx-bootstrap/modal";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { BsDropdownConfig } from "ngx-bootstrap/dropdown";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { CarouselModule } from "ngx-bootstrap/carousel";
//import { TimepickerModule } from 'ngx-bootstrap/timepicker';
//import { PopoverModule } from 'ngx-bootstrap/popover';
//import { DatetimePopupModule } from 'ngx-bootstrap-datetime-popup';
//import { NgSlimScrollModule, SLIMSCROLL_DEFAULTS, ISlimScrollOptions } from 'ngx-slimscroll';
import { NgxPubSubModule } from "@pscoped/ngx-pub-sub";
//import { NgxSelectModule, INgxSelectOptions } from 'ngx-select-ex';
import {
  LazyLoadImageModule,
  LAZYLOAD_IMAGE_HOOKS,
  ScrollHooks,
} from "ng-lazyload-image"; // <-- include ScrollHooks
import { ImageCropperModule } from "ngx-image-cropper";
//import { NgxMaskModule, IConfig } from 'ngx-mask'

import { AppUserConfigurationService } from "./app-user-configuration.service";
import { ServiceProxyModule } from "@app/shared/service-proxies/service-proxy.module";
import { LoginService } from "@app/shared/auth/login.service";
//import { TopBarModule } from '@app/layout/topbar.module';
import { FooterBarModule } from "@app/layout/footerbar.module";
import { RightSideBarModule } from "@app/layout/right-sidebar.module";
import { NgxSpinnerModule } from "ngx-spinner";
import { CrystalLightboxModule } from "@crystalui/angular-lightbox";
//import { ZipcodeInputComponent } from "./components/zipcode/zipcode.component";
import { CartService } from "./services/cart.service";
import { ImageCropperComponent } from "./components/image-cropper/imagecropper.component";
//import { MainLayoutComponent } from "@app/layout/main-layout.component";
//import { BackButtonDirective } from "./directives/backbutton";
//import { NavigationService } from '@app/shared/helpers/navigation.service';

@NgModule({
  imports: [
    //BrowserModule,
    //BrowserAnimationsModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    RightSideBarModule,
    FooterBarModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot(),
    LazyLoadImageModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    CarouselModule,
    //TimepickerModule.forRoot(),
    //PopoverModule.forRoot(),
    //DatetimePopupModule,
    //NgSlimScrollModule,
    NgxPubSubModule, //NgxSelectModule.forRoot(CustomSelectOptions),
    ServiceProxyModule,
    //NavigationService,
    /*FontAwesomeModule,
    ShareButtonsModule.withConfig({
      debug: true
    }),
    ShareIconsModule*/
    NgxSpinnerModule,
    ImageCropperModule,
    CrystalLightboxModule,
    //NgxMaskModule.forRoot(),
    //TopBarModule
  ],
  declarations: [
    //AbpPaginationControlsComponent,
    LocalizePipe,
    //BackButtonDirective,
    /*BlockDirective,
        BusyDirective,
        EqualValidator,*/
    //CommentCardComponent
    //ZipcodeInputComponent,
    //MainLayoutComponent
    ImageCropperComponent
  ],
  exports: [
    //AbpPaginationControlsComponent,
    LocalizePipe,
    /*BlockDirective,
        BusyDirective,
        EqualValidator,*/
    //ZipcodeInputComponent
    //MainLayoutComponent
    ImageCropperComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        //AppSessionService,
        AppUserConfigurationService,
        AppUrlService,
        AppAuthService,
        AppRouteGuard,
        NotifyService,
        LoginService,
        BsModalRef,
        CartService,
        UtilsHelper,
        Html2canvasService,
        //TokenService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: CustomHttpInterceptorService,
          multi: true,
        },
        { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },
        {
          provide: BsDropdownConfig,
          useValue: { isAnimated: true, autoClose: true },
        },
        //{provide: ShareIconsModule, useValue: {}}
      ],
    };
  }
}
