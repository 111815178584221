import { Component, Injector, OnInit } from "@angular/core";
import {
  finalize,
  map,
  mergeMap as _observableMergeMap,
  catchError as _observableCatch,
} from "rxjs/operators";
import { AppComponentBase } from "@app/shared/app-component-base";
import { AuthServiceProxy } from "@app/shared/service-proxies/service-proxies";
import { UserModel } from "@shared/auth/app-auth.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
})
export class ProfileComponent extends AppComponentBase implements OnInit {
  public me: any;
  public showResult: boolean = false;
  public loading: boolean = false;
  public userModel: UserModel;

  constructor(injector: Injector, private authSP: AuthServiceProxy) {
    super(injector);
  }

  ngOnInit() {
    this.getUserInfo().then((res) => {
      if (!res) {
        return;
      }
      this.getDetails();
    });
  }

  getDetails() {
    this.getUserInfo().then((res) => {
      if (!res) {
        return;
      }
      this.loading = true;
      this.spinner.show();
      this.authSP
        .getMe({
          LoginID: this.userModel.strUserID,
          PanelType: this.userModel.UserType,
        })
        .pipe(
          finalize(() => {
            this.loading = false;
            this.spinner.hide();
          })
        )
        .subscribe(
          (result: any) => {
            this.loading = false;
            if (!result.issuccess) {
              this.notify.warn(result.message);
            } else if (result.statuscode == 200) {
              this.me = result.result[0];
            }
            this.showResult = true;
            this.spinner.hide();
          },
          (err) => {
            this.me = {};
            this.loading = false;
            this.showResult = false;
            this.spinner.hide();
          }
        );
    });
  }
}
