import { Injectable } from "@angular/core";
import { PermissionCheckerService } from "./permission-checker.service";
import { UrlHelper } from "../helpers/UrlHelper";

import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
} from "@angular/router";

@Injectable()
export class AppRouteGuard implements CanActivate, CanActivateChild {
  constructor(
    private _permissionChecker: PermissionCheckerService,
    private _router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    /*if (!this._sessionService.user) {
            this._router.navigate(['/account/login']);
            return false;
        }*/

    /*const currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
            // logged in so return true
            return true;
        }*/

    // not logged in so redirect to login page with the return url
    /*this._router.navigate(["/account/login"], {
      queryParams: { returnUrl: state.url },
    });
    return false;*/

    // TODO
    /*let token = abp.auth.getToken();
        if(token == null || token == undefined) {
            this._router.navigate(['/account/login']);
            return false;
        }*/

    const sistoken = localStorage.getItem("sistoken");

    let initialUrl = UrlHelper.initialUrl;
    if (sistoken != "" && sistoken != null) {
      return true;
    } else {
      if (initialUrl.indexOf("/account/login") === -1) {
        this._router.navigate(["/account/login"]);
      }
      return false;
    }
    return false;

    /*let initialUrl = UrlHelper.initialUrl;
      const result = this._appAuthService.isLoggedIn().then((res) => {
        if(res) {
          return true;
        } else {
          if(initialUrl.indexOf("/account/login") === -1) {
            this._router.navigate(['/account/login']);
          }
          return false;
        }
      }).catch(() => {
        if(initialUrl.indexOf("/account/login") === -1) {
          this._router.navigate(['/account/login']);
        }
        return false;
    });


const result2 = this._appAuthService.isLoggedIn().map()*/
    //return result;

    /*console.log('this._appAuthService.loggedIn()', this._appAuthService.loggedIn())
    if(!this._appAuthService.loggedIn()) {
      if(initialUrl.indexOf("/account/login") === -1) {
        this._router.navigate(['/account/login']);
      }
    } else {
      return true;
    }*/
    return false;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canActivate(route, state);
  }

  selectBestRoute(): string {
    /*if (!this._sessionService.user) {
            return '/account/login';
        }*/
    if (this._permissionChecker.isGranted("Pages.Users")) {
      return "/app/admin/users";
    }

    return "/app/home";
  }
}
