import { NgModule } from '@angular/core';
//import { HTTP_INTERCEPTORS } from '@angular/common/http';
//import { AbpHttpInterceptor } from '@abp/abpHttpInterceptor';
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { CustomHttpInterceptorService } from "@app/shared/helpers/http.service";

import * as ApiServiceProxies from './service-proxies';

@NgModule({
    providers: [
        //ApiServiceProxies.RoleServiceProxy,
        //ApiServiceProxies.SessionServiceProxy,
        //ApiServiceProxies.TenantServiceProxy,
        //ApiServiceProxies.UserServiceProxy,
        //ApiServiceProxies.TokenAuthServiceProxy,
        //ApiServiceProxies.AccountServiceProxy,
        //ApiServiceProxies.ConfigurationServiceProxy,
        ApiServiceProxies.AuthServiceProxy,
        ApiServiceProxies.UtilsServiceProxy,
        ApiServiceProxies.OffersServiceProxy,
        ApiServiceProxies.SocialServiceProxy,
        ApiServiceProxies.EpocketServiceProxy,
        ApiServiceProxies.OrderServiceProxy,
        ApiServiceProxies.UsersServiceProxy,
        ApiServiceProxies.MyReferencesServiceProxy,
        //{ provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }
        /*{
        provide: HTTP_INTERCEPTORS,
        useClass: CustomHttpInterceptorService,
        multi: true,
        }*/
    ]
})
export class ServiceProxyModule { }
