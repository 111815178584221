import { Injectable } from "@angular/core";
import { NotifyService } from "../helpers/notify.service";
import { StorageMap } from "@ngx-pwa/local-storage";
import {
  CartItem,
  OrderServiceProxy,
} from "../service-proxies/service-proxies";
import { finalize } from "rxjs/operators";
import { environment } from "@environments/environment";
import { AppAuthService, UserModel } from "@shared/auth/app-auth.service";
import { NgxPubSubService } from "@pscoped/ngx-pub-sub";
import { COLORS } from "html2canvas/dist/types/css/types/color";

@Injectable()
export class CartService {
  protected notify: NotifyService;
  public userModel: UserModel;

  constructor(
    private storage: StorageMap,
    _notify: NotifyService,
    private appAuthService: AppAuthService,
    public pubsubSvc: NgxPubSubService,
    private spOrder: OrderServiceProxy
  ) {
    this.notify = _notify;
  }

  changeShortlistCount() {
    this.getCartCount().then((val) => {
      const html = document.getElementById("cnt-shortlisted");
      if(html != null) {
        html.innerHTML = val.toString();
      }
    });
  }

  addToCart = (itm, serverUpdate: boolean = true): Promise<boolean> => {
    return new Promise<boolean>((result) => {
      this.getCartCount().then((res) => {
        // TODO - allow only 1 item in cart at a time, for this client only
        if (res > 0) {
          this.notify.warn(
            "You already have one item in cart and cannot add more items in cart."
          );
          return result(false);
        } else {
          this.storage.get("CART_ITEMS").subscribe((res: any) => {
            if (res != null && res.length > 0) {
              let existing = res.filter(
                (x) => x.PackageCode == itm.PackageCode
              );
              if (existing == null || existing.length == 0) {
                if(serverUpdate) {
                  this.addToCartDb(itm).then((res4) => {
                    console.log('addToCartDbres1', res4)
                    if(res4) {
                      res.push(itm);
                      this.storage.set("CART_ITEMS", res).subscribe(() => {});
                      this.notify.success(
                        '"<strong>' + itm.Name + '</strong>" added to cart!'
                      );
                      this.changeShortlistCount();
                    }
                    return result(res4)
                  });
                } else {
                  res.push(itm);
                  this.storage.set("CART_ITEMS", res).subscribe(() => {});
                  this.notify.success(
                    '"<strong>' + itm.Name + '</strong>" added to cart!'
                  );
                  this.changeShortlistCount();
                }
                return result(true);
              } else {
                this.notify.warn(
                  '"<strong>' + itm.Name + '</strong>" already in cart!'
                );
                return result(false);
              }
            } else {
              if (itm == null) {
                return result(false);
              }
              
              if(serverUpdate) {
                return this.addToCartDb(itm).then((res4) => {
                  console.log('addToCartDbres2', res4)
                  if(res4) {
                    let res = [];
                    res.push(itm);
                    this.storage.set("CART_ITEMS", res).subscribe(() => {});
                    this.notify.success(
                      '"<strong>' + itm.Name + '</strong>" added to cart!'
                    );
                    this.refresh();
                  }
                  return result(res4)
                });
              } else {
                let res = [];
                res.push(itm);
                this.storage.set("CART_ITEMS", res).subscribe(() => {});
                this.notify.success(
                  '"<strong>' + itm.Name + '</strong>" added to cart!'
                );
                this.refresh();
              }
              return result(true);
            }
            return result(false);
          });
        }
      });
    });
  };

  removeFromCart = (itm): Promise<boolean> => {
    return new Promise<boolean>((result) => {
      if (itm == null) {
        return result(false);
      }
      this.storage.get("CART_ITEMS").subscribe((res: any) => {
        if (res != null && res.length > 0) {
          let existing = res.findIndex((x) => x.PackageCode == itm.PackageCode);
          if (existing >= 0) {
            res.splice(existing, 1);
            this.storage.set("CART_ITEMS", res).subscribe(() => {});
            this.notify.warn(
              '"<strong>' + itm.Name + '</strong>" removed from cart!'
            );
            this.refresh();
            this.removeFromCartDb(itm);
            return result(true);
          } else {
            this.notify.warn(
              '"<strong>' + itm.Name + '</strong>" not in cart!'
            );
            return result(false);
          }
        } 
        if(itm != null) {
          this.removeFromCartDb(itm);
          this.refresh();
          return result(true);
        }
        return result(false);
      });
    });
  };

  addCartQty = (itm, qty): Promise<boolean> => {
    return new Promise<boolean>((result) => {
      if (itm == null) {
        return result(false);
      }
      this.storage.get("CART_ITEMS").subscribe((res: any) => {
        if (res != null && res.length > 0) {
          let existing = res.findIndex((x) => x.PackageCode == itm.PackageCode);
          if (existing >= 0) {
            //res.splice(existing, 1);
            res[existing].Quantity =
              (res[existing].Quantity != undefined
                ? res[existing].Quantity
                : 0) + qty;
            this.storage.set("CART_ITEMS", res).subscribe(() => {});
            this.notify.info(
              '"<strong>' + itm.Name + '</strong>" quantity changed!'
            );
            this.refresh();
            return result(true);
          } else {
            this.notify.warn(
              '"<strong>' + itm.Name + '</strong>" not in cart!'
            );
            return result(false);
          }
        }
        return result(false);
      });
    });
  };

  removeCartQty = (itm, qty): Promise<boolean> => {
    return new Promise<boolean>((result) => {
      if (itm == null) {
        return result(false);
      }
      this.storage.get("CART_ITEMS").subscribe((res: any) => {
        if (res != null && res.length > 0) {
          let existing = res.findIndex((x) => x.PackageCode == itm.PackageCode);
          if (existing >= 0) {
            //res.splice(existing, 1);
            res[existing].Quantity =
              (res[existing].Quantity != undefined
                ? res[existing].Quantity
                : 0) - qty;
            this.storage.set("CART_ITEMS", res).subscribe(() => {});
            this.notify.info(
              '"<strong>' + itm.Name + '</strong>" quantity changed!'
            );
            this.refresh();
            return result(true);
          } else {
            this.notify.warn(
              '"<strong>' + itm.Name + '</strong>" not in cart!'
            );
            return result(false);
          }
        }
        return result(false);
      });
    });
  };

  getCartCount = (): Promise<number> => {
    return new Promise<any>((res) => {
      this.storage.get("CART_ITEMS").subscribe((val: any) => {
        //console.log('getCartCount CART_ITEMS', val)
        return res(val != null && val.length > 0 ? val.length : 0);
      });
    });
  };

  getCartItems = (): Promise<CartItem[]> => {
    return new Promise<CartItem[]>((resci) => {
      /*this.storage.get("CART_ITEMS").subscribe((val: CartItem[]) => {
        return resci(val);
      });*/

      return this.appAuthService.getUserModel().then((res) => {
        this.userModel = res;
        let formdata = {
          CompanyId: environment.CompanyID,
          UserID: this.userModel.UserID,
          OrderType: "Purchase",
          ShoppingForUserID: this.userModel.UserID,
          //PackageCode: data.PackageCode,
        };
        return this.spOrder
          .getUserShoppingCart(formdata)
          .pipe(finalize(() => {}))
          .subscribe(
            (result: any) => {
              if (!result.issuccess) {
                //this.notify.warn(result.message);
              } else if (result.statuscode == 200) {
              }
              
              return this.storage.get("CART_ITEMS").subscribe((val: CartItem[]) => {
                //console.log('CART_ITEMS', val, result.result)
                if(val != undefined && val.length > 0) {
                  if(result.result != undefined && result.result.length > 0) {
                    //console.log('case 1')
                    result.result.forEach(element => {
                      if(element.PackageCode == val[0].PackageCode) {
                        return resci(val);
                      }
                    });
                  } else if(result.result == undefined || result.result.length == 0) {
                    //console.log('case 2')
                    //this.addToCartDb(val[0]);
                    this.clearCart();
                    return resci([]);
                  }
                } else if((val == undefined || val.length == 0) && result.result != undefined && result.result.length > 0) {
                  //console.log('case 3')
                  let newitm = result.result[0];
                  newitm.Quantity = newitm.ItemQtn;
                  return this.addToCart(newitm, false).then((val2: boolean) => {
                    this.refresh();
                    return resci([newitm]);
                  });
                }
                //console.log('case 4')
                //console.log('getCartItems')
                this.refresh();
                return resci(val);
              });
            },
            (err) => {
              this.notify.error(err.message);
            }
          );
      });
    });
  };

  isInCart = (itm): Promise<boolean> => {
    return new Promise<boolean>((res) => {
      if (itm == null) {
        return false;
      }
      this.storage.get("CART_ITEMS").subscribe((val: CartItem[]) => {
        let existing = val
          ? val.filter((x) => x.PackageCode == itm.PackageCode)
          : null;
        return res(existing == null || existing.length == 0 ? false : true);
      });
    });
  };

  clearCart = (): Promise<boolean> => {
    return new Promise<boolean>((res) => {
      this.storage.set("CART_ITEMS", []).subscribe(() => {});
      this.refresh();
      res(true);
    });
  };

  refresh() {
    //console.log('refresh')
    this.pubsubSvc.publishEvent("cart:changed", { temp: 1 });
    /*this.getCartCount().then((val) => {
      const html = document.getElementById("cnt-shortlisted");
      if(html != null) {
        html.innerHTML = val.toString();
        this.pubsubSvc.publishEvent("cart:changed", { temp: val });
      }
    });*/
  }

  addToCartDb = (data): Promise<boolean> => {
    return new Promise<boolean>((pres) => {
      if (data == null) {
        return pres(false);
      }
      this.appAuthService.getUserModel().then((res) => {
        this.userModel = res;
        let formdata = {
          //CompanyId: environment.CompanyID,
          UserID: this.userModel.UserID,
          OrderType: "Purchase",
          ShoppingForUserID: this.userModel.UserID,
          PackageCode: data.PackageCode,
        };
        this.spOrder
          .addToShoppingCart(formdata)
          .pipe(finalize(() => {}))
          .subscribe(
            (result: any) => {
              if (!result.issuccess) {
                this.notify.warn(result.message);
                return pres(false);
              } else if (result.statuscode == 200) {
                return pres(true);
              }
            },
            (err) => {
              this.notify.error(err.message);
              return pres(false);
            }
          );
      });
    });
  }

  removeFromCartDb(data) {
    this.appAuthService.getUserModel().then((res) => {
      this.userModel = res;
      let formdata = {
        //CompanyId: environment.CompanyID,
        UserID: this.userModel.UserID,
        OrderType: "Purchase",
        ShoppingForUserID: this.userModel.UserID,
        PackageCode: data.PackageCode,
      };
      this.spOrder
        .removeProduct(formdata)
        .pipe(finalize(() => {}))
        .subscribe(
          (result: any) => {
            if (!result.issuccess) {
              this.notify.warn(result.message);
            } else if (result.statuscode == 200) {
            }
          },
          (err) => {
            this.notify.error(err.message);
          }
        );
    });
  }

  getFromCartDb() {
    this.appAuthService.getUserModel().then((res) => {
      this.userModel = res;
      let formdata = {
        CompanyId: environment.CompanyID,
        UserID: this.userModel.UserID,
        OrderType: "Purchase",
        ShoppingForUserID: this.userModel.UserID,
        //PackageCode: data.PackageCode,
      };
      this.spOrder
        .getUserShoppingCart(formdata)
        .pipe(finalize(() => {}))
        .subscribe(
          (result: any) => {
            if (!result.issuccess) {
              this.notify.warn(result.message);
            } else if (result.statuscode == 200) {
            }
          },
          (err) => {
            this.notify.error(err.message);
          }
        );
    });
  }
}
