import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { OffersComponent } from "./offers.component";
//import { OfferComponent } from "./offer.component";
import { OfferLayoutComponent } from "./layout/offer-layout.component";
import { OfferpoComponent } from "./component/offerpo.component";
//import { MainLayoutComponent } from "@app/layout/main-layout.component";

const routes: Routes = [
  {
    path: "",
    component: OfferLayoutComponent,
    children: [
      { path: "", component: OffersComponent },
      { path: "offers", component: OffersComponent },
      //{ path: "details", component: OfferpoComponent },
      { path: "offers/:slug", component: OfferpoComponent },
      //{ path: "offerpo", component: OfferpoComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OffersRoutingModule {}
