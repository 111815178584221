import {
  throwError as observableThrowError,
  Observable,
  BehaviorSubject,
} from "rxjs";
import { take, filter, catchError, switchMap, finalize } from "rxjs/operators";
import { Injectable, Injector, Inject, forwardRef } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent,
  HttpErrorResponse,
} from "@angular/common/http";
//import { Events } from "@ionic/angular";
import { AppAuthService } from "../auth/app-auth.service";
import * as moment from "moment-timezone";
import { NotifyService } from "@app/shared/helpers/notify.service";
import { NgxPubSubService } from "@pscoped/ngx-pub-sub";

@Injectable()
export class CustomHttpInterceptorService implements HttpInterceptor {
  private token: any;
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  isRefreshingToken: boolean = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private accessToken: string = null;

  constructor(
    public appAuthService: AppAuthService,
    public notify: NotifyService,
    public pubsubSvc: NgxPubSubService
  ) {}

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  //intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<
    | HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<any>
    | HttpUserEvent<any>
  > {
    //const appAuthService = this.injector.get(AppAuthService);
    //this.appAuthService = this.injector.get(AppAuthService);
    //request = request.clone({headers: request.headers.set('Content-Type', 'application/json')});

    /*if (!request.headers.has('Accept')) {
      request = request.clone({headers: request.headers.set('Accept', 'application/json')});
    }*/

    request = request.clone({
      headers: request.headers.set("Accept-Language", "en-Us"),
    });
    /*request = request.clone({
      headers: request.headers.set("Referrer Policy", "no-referrer-when-downgrade"),
    });*/

    //request = request.clone({headers: request.headers.set('Authorization', 'Basic ' + this.token)});

    //return next.handle(request);

    //return next.handle(this.addAuthenticationToken(request));

    request = this.addAuthenticationToken(request);

    /*request = this.addAuthenticationToken2(request).then((res) => {
      
    });*/

    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          switch ((<HttpErrorResponse>error).status) {
            case 400:
              return this.handle400Error(error);
            case 401:
            case 440:
              // 401 - Unauthorized
              // 440 - Token expired
              if (request.url.indexOf("auth/refresh") > -1) {
                //this.events.publish("user:logout");
                //this.notify.warn("Please login and try again!");
                this.pubsubSvc.publishEvent("user:logout", {logout: true, url: request.url});
              }
              if (request.url.indexOf("auth/login") === -1) {
                return this.handle401Error(request, next);
              }
              //this.events.publish("user:logout");
              this.pubsubSvc.publishEvent("user:logout", {});
            case 500:
              if (request.url.indexOf("auth/refresh") > -1) {
                //TokenBlacklistedException
                //this.events.publish("user:logout");
                //this.notify.warn("Please login and try again!");
                //this.pubsubSvc.publishWithLast("user:logout");
              }
            default:
              return observableThrowError(error);
          }
        } else {
          return observableThrowError(error);
        }
      })
    );
  }

  addAuthenticationToken(request) {
    // Get access token from Local Storage
    this.accessToken = this.appAuthService.getAccessToken2();
    this.appAuthService.getTokenExpiresAt().then((res) => {
      //console.log('getTokenAt 1_', res, moment(res).format('YYYY-MM-DD HH:mm:ss'))
      let dt = moment(res);
      let dtnow = moment();
      let timeremaining = dt.diff(dtnow, "seconds");
      //console.log('getTokenAt 2_', timeremaining, this.isRefreshingToken, dt, dtnow)//, dtnow, moment(timeremaining).format('YYYY-MM-DD HH:mm:ss'))
      if (timeremaining < 50 && timeremaining > 0) {
        if (!this.isRefreshingToken) {
          this.isRefreshingToken = true;
          //console.log('isRefreshingToken');
          this.appAuthService
            .refreshToken()
            .pipe(
              finalize(() => {
                this.isRefreshingToken = false;
              })
            )
            .subscribe((res) => {
              this.isRefreshingToken = false;
              this.accessToken = res;
            }, (err) => {
              //console.log('refresh err', err)
              if(err.status == 440) {
                // TokenBlacklistedException
                this.pubsubSvc.publishEvent("user:logout", {});
              }
            });
        }
      }
    });
    //console.log("accessToken", this.accessToken);
    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!this.accessToken) {
      return request;
    }

    // We clone the request, because the original request is immutable
    return request.clone({
      setHeaders: {
        Authorization: "Bearer " + this.accessToken,
      },
    });
  }

  addAuthenticationToken2(request) {
    //this.appAuthService = this.injector.get(AppAuthService);
    // Get access token from Local Storage
    //let accessToken = this.appAuthService.getAccessToken();
    return this.appAuthService.getAccessToken().then((accessToken) => {
      //console.log("addAuthenticationToken", accessToken);
      this.appAuthService.getTokenExpiresAt().then((res) => {
        //console.log('getTokenAt 1_', res, moment(res).format('YYYY-MM-DD HH:mm:ss'))
        let dt = moment(res);
        let dtnow = moment();
        let timeremaining = dt.diff(dtnow, "seconds");
        //console.log('getTokenAt 2_', timeremaining, this.isRefreshingToken, dt, dtnow)//, dtnow, moment(timeremaining).format('YYYY-MM-DD HH:mm:ss'))
        if (timeremaining < 50) {
          if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;
            //console.log("isRefreshingToken");
            this.appAuthService
              .refreshToken()
              .pipe(
                finalize(() => {
                  this.isRefreshingToken = false;
                })
              )
              .subscribe((res) => {
                this.isRefreshingToken = false;
                accessToken = res;
              });
          }
        }

        //console.log("accessToken", accessToken, !accessToken, request.url);
        // If access token is null this means that user is not logged in
        // And we return the original request
        if (!accessToken) {
          return request;
        }

        // We clone the request, because the original request is immutable
        /*return request.clone({
          setHeaders: {
            Authorization: "Bearer " + accessToken,
          },
        });*/
        return this.addToken(request, accessToken);
      });
    });
    //return request;
  }

  handle400Error(error) {
    //console.log("handle400Error");
    if (
      error &&
      error.status === 400 &&
      error.error &&
      error.error.error === "invalid_grant"
    ) {
      // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
      //return this.appAuthService.logout();
      //this.events.publish("user:logout");
    }

    return observableThrowError(error);
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);
      //console.log("appAuthService.refreshToken 0");
      return this.appAuthService.refreshToken().pipe(
        switchMap((newToken: string) => {
          console.log("appAuthService.refreshToken 1");
          if (newToken) {
            this.tokenSubject.next(newToken);
            return next.handle(this.addToken(req, newToken));
          }

          // If we don't get a new token, we are in trouble so logout.
          //return this.appAuthService.logout();
          return next.handle(req);
        }),
        catchError((error) => {
          //console.log("appAuthService.refreshToken 2");
          // If there is an exception calling 'refreshToken', bad news so logout.
          //return this.appAuthService.logout();
          //this.events.publish("user:logout");
          return next.handle(req);
        }),
        finalize(() => {
          //console.log("appAuthService.refreshToken 3");
          this.isRefreshingToken = false;
        })
      );
    } else {
      return this.tokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((token) => {
          return next.handle(this.addToken(req, token));
        })
      );
    }
  }
}
